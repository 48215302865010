import { Popover } from 'govwell-ui'
import { MenuContentStyles } from 'govwell-ui/components/Menu/Menu'
import styled from 'styled-components'

const StyledMenuContent = styled(Popover.Content).attrs({
  align: 'start',
  side: 'bottom',
  role: 'listbox',
  sideOffset: 1,
  onOpenAutoFocus: (e) => e.preventDefault(),
  onMouseDown: (e) => e.preventDefault(), // Prevent blurring input when selecting items
})`
  ${MenuContentStyles};
  max-height: var(--radix-popover-content-available-height);
  min-width: var(--radix-popper-anchor-width);
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1px;
`
export default StyledMenuContent
