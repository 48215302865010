import { faBars, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'

import { navigate, routes, useMatch } from '@redwoodjs/router'

import { useAuth } from '../../auth'
import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import {
  useIsCitizen,
  useMyOrg,
  useMyUser,
  useOrgSlugMetadata,
} from '../../layouts/AppStateContextLayout/utils'
import { StylePropsMobile } from '../shared/StyledComponents'
import { Logo, LogoColor } from '../svgs/Logo'

import LoginButton from './LoginButton'
import TopBarTemplate from './TopbarTemplate'

const Image = styled.img<StylePropsMobile>`
  width: auto;
  height: auto;
  max-width: 120px;
  max-height: 60px;
`

interface Props {
  onClickHamburger: () => void
  hamburgerOpen: boolean
  color: 'white' | 'blue'
}

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TopBar = (props: Props) => {
  const { onClickHamburger, hamburgerOpen, color } = props
  const { match: isOnLoginPage } = useMatch(routes.login())

  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.Topbar)
  const { isAuthenticated } = useAuth()

  const orgSlugData = useOrgSlugMetadata()
  const myOrg = useMyOrg()
  const orgData = orgSlugData || myOrg

  const slug = orgData?.slug
  const logoUrl = orgData?.logoUrl
  const govWellLogoColor = color === 'blue' ? LogoColor.White : LogoColor.Black

  const me = useMyUser()
  const isCitizen = useIsCitizen() || !me
  const onClickLogoOrTitle = () => navigate(routes.cityHome({ slug }))

  const title = (
    <TitleContainer>
      <Title
        level={2}
        style={{
          margin: 0,
          color: color === 'white' ? '#28316E' : 'white',
          width: '100%',
          textAlign: small ? 'left' : 'center',
          cursor: 'pointer',
        }}
        onClick={onClickLogoOrTitle}
      >
        {orgData?.displayName || ''}
      </Title>
      {isCitizen && !small && (
        <Title
          level={4}
          style={{ margin: 0, color: color === 'white' ? '#9397B6' : 'white' }}
        >
          Citizen Portal
        </Title>
      )}
    </TitleContainer>
  )

  const getButton = () => {
    if (isAuthenticated) {
      return (
        <Button
          onClick={onClickHamburger}
          type="primary"
          icon={<FontAwesomeIcon icon={hamburgerOpen ? faXmark : faBars} />}
        />
      )
    }
    if (!isOnLoginPage) {
      return <LoginButton />
    }
  }

  return (
    <TopBarTemplate
      color={color === 'white' ? 'white' : 'rgba(10, 46, 87, 1)'}
      onClickImage={onClickLogoOrTitle}
      image={
        logoUrl ? (
          <Image $small={small} src={logoUrl} alt="logo" />
        ) : (
          <Logo color={govWellLogoColor} />
        )
      }
      title={title}
      button={getButton()}
    />
  )
}

export default TopBar
