export const LOGOS_BUCKET = 'logos'

export enum PathPrefixes {
  CorrectionsReports = 'corrections-reports',
  DocumentTemplates = 'document-templates',
  GLNumberReports = 'gl-number-reports',
  Invoices = 'invoices',
  NewPermitTypeForm = 'new-permit-type-form',
  PaymentTransferCsvs = 'payment-transfer-csvs',
  Receipts = 'receipts',
  RecordsCsvs = 'records-csvs',
  RecordsReports = 'records-reports',
  RecordTaskFeesCsv = 'record-task-fees-csvs',
  RecordTaskInspectionAttemptsCsvs = 'record-task-inspection-attempts-csvs',
  RecordTaskInspectionAttemptsReports = 'record-task-inspection-attempts-reports',
  Signatures = 'signatures',
  StampTemplates = 'stamp-templates',
  SupportForm = 'support-form',
  ViolationCases = 'violation-cases',
  ViolationFiles = 'violation-files',
  ViolationInspectionPhotos = 'violation-inspection-photos',
  ViolationMailers = 'violation-mailers',
}
