import { useIsGov } from '../../layouts/AppStateContextLayout/utils'

import { ApplicantSidebar } from './ApplicantSidebar'
import { StaffSidebar } from './StaffSidebar'

interface Props {
  fullWidth?: boolean
}

export const Sidebar = (props: Props) => {
  const isGov = useIsGov()

  return isGov ? <StaffSidebar {...props} /> : <ApplicantSidebar {...props} />
}
