import { Select as AntdSelect, SelectProps } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { DefaultOptionType } from 'antd/es/select'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

export type OptionType<TValue = DefaultOptionType['value']> =
  DefaultOptionType & {
    value?: TValue | null
  }

export type OptionGroupType<TValue = DefaultOptionType['value']> = {
  label: string
  options?: OptionType<TValue>[]
  value?: TValue
}

export interface Props<TValue extends string | number>
  extends FormFieldWrapperProps {
  placeholder?: React.ReactNode
  limitOne?: boolean
  disabled?: boolean
  options: OptionType<TValue>[]
  optionRender?: React.ComponentProps<
    typeof AntdSelect<TValue, OptionType<TValue>>
  >['optionRender']
  onChange?: SelectProps<TValue, OptionType<TValue>>['onChange']
  mode?: SelectProps['mode']
  loading?: boolean
  size?: SizeType
}

export function SelectGroup<TValue extends string | number>(
  props: Props<TValue>
) {
  const {
    placeholder,
    disabled,
    options,
    optionRender,
    onChange,
    mode,
    loading,
    size,
    ...formFieldWrapperProps
  } = props

  return (
    <Wrapper {...formFieldWrapperProps}>
      <AntdSelect<TValue, OptionType<TValue>>
        onChange={onChange}
        showSearch={true}
        placeholder={placeholder || formFieldWrapperProps?.label}
        disabled={disabled}
        options={options}
        optionRender={optionRender}
        mode={mode}
        loading={loading}
        size={size}
      />
    </Wrapper>
  )
}

export default SelectGroup
