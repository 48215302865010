import { useCallback } from 'react'

import { faCopy, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { Flex, Tag } from 'antd'
import { Menu, Tabs } from 'govwell-ui'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { GetOrCreateViewsMutation } from 'types/graphql'

import CreateViewModal from 'src/components/TableViews/CreateViewModal'
import DeleteViewModal from 'src/components/TableViews/DeleteViewModal'
import RenameViewModal from 'src/components/TableViews/RenameViewModal'
import Text from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

const StyledFlex = styled(Flex)`
  width: 100%;
`
const StyledViewName = styled(Text)`
  display: block !important;
`

type Props = {
  generateDefaultViews: () => Promise<GetOrCreateViewsMutation>
  view: TableView
  tableViewManager: TableViewManager
}
const TableViewTab = ({
  generateDefaultViews,
  view,
  tableViewManager,
}: Props) => {
  const {
    isOpen: isMenuOpen,
    open: onOpenMenu,
    close: onCloseMenu,
  } = useDisclosure()

  const handleRightClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      onOpenMenu()
    },
    [onOpenMenu]
  )

  const renameModalState = useDisclosure()
  const duplicateModalState = useDisclosure()
  const deleteModalState = useDisclosure()

  return (
    <>
      <Menu isOpen={isMenuOpen} onClose={onCloseMenu} onOpen={onOpenMenu}>
        <Menu.Trigger isDisabled>
          <Tabs.Tab tabKey={`${view.id}`} onContextMenu={handleRightClick}>
            <StyledFlex gap="6px" justify="space-between">
              <StyledViewName color="inherit" noWrap ellipsis maxLines={1}>
                {view.name}
              </StyledViewName>
              <Tag color="default" style={{ margin: 0 }}>
                {view.databaseTotalCount}
              </Tag>
            </StyledFlex>
          </Tabs.Tab>
        </Menu.Trigger>
        <Menu.Content sideOffset={0}>
          <Menu.Item icon={faPen} onSelect={renameModalState.open}>
            Rename
          </Menu.Item>
          <Menu.Item icon={faCopy} onSelect={duplicateModalState.open}>
            Duplicate
          </Menu.Item>
          <Menu.Item icon={faTrash} onSelect={deleteModalState.open}>
            Delete
          </Menu.Item>
        </Menu.Content>
      </Menu>
      <RenameViewModal modalState={renameModalState} view={view} />
      <CreateViewModal
        modalState={duplicateModalState}
        duplicateFromView={view}
        tableViewManager={tableViewManager}
      />
      <DeleteViewModal
        generateDefaultViews={generateDefaultViews}
        modalState={deleteModalState}
        tableViewManager={tableViewManager}
        view={view}
      />
    </>
  )
}

export default observer(TableViewTab)
