import dayjs from 'dayjs'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsTimezone from 'dayjs/plugin/timezone'
import dayjsUtc from 'dayjs/plugin/utc'
import dayjsBusinessDays from 'dayjs-business-days2'

import { TimeZone } from '../../types/graphql'

dayjs.extend(dayjsUtc)
dayjs.extend(dayjsTimezone)
dayjs.extend(dayjsIsToday)
dayjs.extend(dayjsBusinessDays)

export enum DateFormats {
  NumericalDateTime = 'MM-DD-YY [at] h:mma',
  NumericalDateTimeConcise = 'MM-DD-YY, h:mma',
  NumericalDateTimeWithSeconds = 'MM-DD-YY, h:mma:ss',
  Month = 'MMMM',
  MonthNameDateTime = 'MMMM D, YYYY, h:mma',
  MonthNameDateNoTime = 'MMMM D, YYYY',
  MonthDayYear = 'M-DD-YY',
  MonthDayYearWithSlash = 'M/DD/YY',
  MonthDay = 'M-DD',
  MonthDayFullYear = 'M-DD-YYYY',
  FullDateWithDayOfWeek = 'dddd MMMM D, YYYY, h:mma',
  Time = 'h:mma',
  ISO8601Standard = 'YYYY-MM-DD',
}

export const formatDate = (date: Date | string, dateFormat: DateFormats) => {
  return dayjs(date).format(dateFormat)
}

export const formatDateInTimeZone = (
  date: Date | string,
  timeZone: TimeZone | null | undefined,
  dateFormat: DateFormats
) => {
  return dayjs(date)
    .tz(timeZone ? TimeZoneMap[timeZone] : undefined)
    .format(dateFormat)
}

// is the date at least one day in the past?
export const isBeforeToday = (date: Date | string) => {
  return dayjs(date).isBefore(new Date(), 'day')
}

export const isToday = (date: Date | string) => {
  return dayjs(date).isToday()
}

export const getDueDate = (
  daysToComplete?: number | null
): Date | undefined => {
  if (daysToComplete == null) {
    return undefined
  }
  return dayjs(new Date()).businessDaysAdd(daysToComplete).toDate()
}

export const removeSeconds = (date: Date | string) => {
  return dayjs(date).second(0).millisecond(0).toDate()
}

export const convertDateToTimeZone = (
  date: Date | string,
  timeZone: TimeZone
) => {
  // Convert the date to Day.js object in UTC
  const dayjsDateUTC = dayjs(date).utc()

  // Get the offset in hours for the target time zone
  const targetOffset = dayjs().tz(TimeZoneMap[timeZone]).utcOffset()
  const localOffset = dayjs().utcOffset()

  // Calculate the difference in minutes and adjust the date
  const adjustedDate = dayjsDateUTC.add(localOffset - targetOffset, 'minute')

  return adjustedDate.toDate()
}

export const TimeZoneAbbreviationMap: Record<TimeZone, string> = {
  Pacific: 'PDT',
  Mountain: 'MDT',
  MountainStandard: 'MST',
  Central: 'CDT',
  Eastern: 'EDT',
}

export const TimeZoneDisplayMap: Record<TimeZone, string> = {
  Pacific: 'Pacific Time',
  Mountain: 'Mountain Daylight Time',
  MountainStandard: 'Mountain Standard Time',
  Central: 'Central Time',
  Eastern: 'Eastern Time',
}

export const TimeZoneMap: Record<TimeZone, string> = {
  Pacific: 'America/Los_Angeles',
  Mountain: 'America/Denver',
  MountainStandard: 'America/Phoenix',
  Central: 'America/Chicago',
  Eastern: 'America/New_York',
}

export const getLocalBrowserTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return dayjs().tz(timeZone).format('z')
}

export const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
