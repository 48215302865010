import { useState } from 'react'

import { Menu as AntdMenu, MenuProps as AntdMenuProps, Tag } from 'antd'

import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { filterNil, isNumber } from '../../utils'
import { LeftRightView } from '../shared/LeftRightView'

import {
  MenuContainerHorizontal,
  MenuContainerVertical,
  MenuItemLabelContainer,
  MenuVerticalItemContainer,
} from './StyledComponents'
import { MenuItem, MenuMode, MenuProps, MenuState } from './types'

export const useMenu = (
  items: MenuItem[],
  options?: {
    initialKey: string
  }
): MenuState => {
  const initialKey = options?.initialKey ?? getInitialKey(items)
  const [menuKey, setMenuKey] = useState<string>(initialKey || '0')

  return {
    menuKey,
    setMenuKey,
    keys: filterNil(items.map((i) => i.key)),
  }
}

const renderCount = (count: number | string | null | undefined) => {
  if (typeof count === 'string') {
    return <Tag color="default">{count}</Tag>
  }
  if (!isNumber(count) || count === 0) {
    return ''
  }
  return <Tag color="default">{count?.toLocaleString() || count}</Tag>
}

const getLabel = (item: MenuItem) => {
  const { label, count, labelTag } = item

  return (
    <MenuItemLabelContainer>
      {label}
      {renderCount(count)}
      {labelTag && <Tag color="blue">{labelTag}</Tag>}
    </MenuItemLabelContainer>
  )
}

const getInitialKey = (items: MenuItem[]): string => {
  const initialItemIndex = items.findIndex((item) => item.isInitialItem)
  const initialKey = items.findIndex(
    (item) => !('count' in item) || !!item.count
  )
  const index = initialItemIndex !== -1 ? initialItemIndex : initialKey
  return index === -1
    ? items?.[0]?.key || '0'
    : items?.[index]?.key || index.toString()
}

export const Menu = (props: MenuProps) => {
  const {
    items,
    noStyle,
    mode = MenuMode.Horizontal,
    menuKey: controlledMenuKey,
    setMenuKey: controlledSetKey,
  } = props

  const useMenuRes = useMenu(items)

  const menuKey = controlledMenuKey || useMenuRes.menuKey
  const setMenuKey = controlledSetKey || useMenuRes.setMenuKey

  const mappedItems = items.map((item: MenuItem, index: number) => ({
    label: getLabel(item),
    key: `${index}`,
  }))
  const onClick: AntdMenuProps['onClick'] = (info) => setMenuKey(info.key)
  const { isSmallerThanOrEqual } = useCurrentBreakpoint()
  const small = isSmallerThanOrEqual('sm')

  if (!items?.length) {
    return null
  }

  let item = isNaN(parseInt(menuKey))
    ? items.find((i) => i.key === menuKey)
    : items[parseInt(menuKey)]
  if (!item) {
    item = items[0]
  }

  if (mode === MenuMode.Horizontal) {
    const selectedKey = `${items.indexOf(item)}`
    return (
      <>
        {item.renderTop?.()}
        <MenuContainerHorizontal $small={small} $noStyle={noStyle}>
          <AntdMenu
            mode="horizontal"
            selectedKeys={[selectedKey]}
            className="menuContainerClass"
            onClick={onClick}
            disabledOverflow
            items={mappedItems}
          />
        </MenuContainerHorizontal>
        {item.renderChildren()}
      </>
    )
  }

  return (
    <MenuContainerVertical>
      <LeftRightView
        left={
          <AntdMenu
            mode="vertical"
            selectedKeys={[menuKey]}
            onClick={onClick}
            disabledOverflow
            items={mappedItems}
          />
        }
        right={
          <MenuVerticalItemContainer>
            {items[parseInt(menuKey)].renderChildren()}
          </MenuVerticalItemContainer>
        }
        sizes={{ left: 6, right: 18 }}
      />
    </MenuContainerVertical>
  )
}
