import { createPortal } from 'react-dom'
type Props = {
  children: React.ReactNode
  container: Element | DocumentFragment | undefined | null
}
const Portal = ({ children, container }: Props) => {
  if (container) {
    return createPortal(children, container)
  }
  return children
}

export default React.memo(Portal)
