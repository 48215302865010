import { Alert } from 'antd'
import styled from 'styled-components'

import Text, { TextSize } from '../Typography/Text'

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  .anticon-info-circle {
    margin-top: 4px;
  }
`

type Props = {
  inspectionSchedulingInstructions: string | null
}
const InspectionSchedulingInstructions = ({
  inspectionSchedulingInstructions,
}: Props) => {
  if (!inspectionSchedulingInstructions?.trim()) {
    return null
  }
  return (
    <StyledAlert
      type="info"
      showIcon
      message={
        <Text size={TextSize.Base} allowNewlines>
          {inspectionSchedulingInstructions}
        </Text>
      }
    />
  )
}

export default React.memo(InspectionSchedulingInstructions)
