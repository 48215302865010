import Dragger from 'antd/es/upload/Dragger'
import styled from 'styled-components'

import { ColorToken } from '../constants/theme/types'

import {
  StylePropsMobile,
  getLightContainerStyleProps,
} from './shared/StyledComponents'

interface Props {
  $margin?: string
  $notWidth100?: boolean
  $colorToken?: ColorToken
}
// negative margins -> do not include width: 100%;
export const HorizontalLine = styled.div<Props>`
  ${(props) =>
    props.$margin?.includes('-') || props.$notWidth100 ? '' : 'width: 100%'};
  background-color: ${(props) =>
    props.$colorToken
      ? props.theme[props.$colorToken as string]
      : props.theme.colorSplit};
  height: 1px;
  ${(props) => (props.$margin ? `margin: ${props.$margin};` : '')};
`

export interface StyledDraggerProps extends StylePropsMobile {
  $disabled?: boolean
  $error?: boolean
}

export const StyledDragger = styled(Dragger)<StyledDraggerProps>`
  .ant-upload-drag {
    ${(props) => getLightContainerStyleProps(props.theme, props.$error)}
  }
  .ant-upload {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: ${(props) => (props.$small ? '4px' : '16px')};
    padding-right: ${(props) => (props.$small ? '4px' : '16px')};
    &:hover {
      ${(props) =>
        props.$disabled && `border-color: ${props.theme.colorBorder};`}
    }
  }
  .ant-upload-list {
    display: none;
  }
  width: 100%;
`

export const StyledDraggerContentContainer = styled.div<StylePropsMobile>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$small ? '8px' : '15px')};
  align-items: center;
  justify-content: center;
  position: relative;
`
