import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { faRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import { styled } from 'styled-components'

import { BreakpointComponents } from '../../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../../hooks/use-current-breakpoint'
import { useTheme } from '../../../hooks/use-theme'
import Text, { TextSize } from '../../Typography/Text'
import { Progress } from '../Progress'

interface Props {
  progress: number
  cancelUpload: () => void
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const UploadingHeader = () => {
  const { getTokenVal } = useTheme()
  return (
    <Flex gap="12px" align="center" justify="center">
      <FontAwesomeIcon
        icon={faRotate}
        style={{
          height: 32,
          width: 32,
          marginTop: 0,
          color: getTokenVal('colorPrimaryBase'),
        }}
      />
      <Text size={TextSize.Base}>Uploading</Text>
    </Flex>
  )
}

export const Uploading = (props: Props) => {
  const { progress, cancelUpload } = props

  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)

  const cancelButton = (
    <Button
      type="text"
      onClick={cancelUpload}
      icon={<FontAwesomeIcon icon={faXmark} />}
    >
      Cancel
    </Button>
  )

  return (
    <Container>
      {!small && (
        <Button
          type="text"
          onClick={cancelUpload}
          icon={<FontAwesomeIcon icon={faXmark} />}
        >
          Cancel
        </Button>
      )}
      <UploadingHeader />
      <Progress progress={progress} />
      {small && cancelButton}
    </Container>
  )
}
