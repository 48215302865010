import React, { useEffect } from 'react'

import { styled } from 'styled-components'

import { useLocation } from '@redwoodjs/router'

import { useAuth } from '../../auth'
import { Sidebar } from '../../components/Sidebar/Sidebar'
import TopBar from '../../components/Topbar/Topbar'
import useDisclosure from '../../hooks/use-disclosure'
import SidebarLayout from '../SidebarLayout/SidebarLayout'

type Props = {
  children: React.ReactNode
  color: 'white' | 'blue'
  allowSidebarFallback?: boolean
  noChildrenPadding?: boolean
}

export const TopbarPageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const PageContent = styled.div<{ $padding?: boolean }>`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${(props) => props.$padding && 'padding: 24px 32px;'}
`

export const FooterContainer = styled.div`
  margin-top: auto;
`

const TopbarLayout = (props: Props) => {
  const { color, allowSidebarFallback, noChildrenPadding, children } = props

  const { isAuthenticated } = useAuth()

  const modalState = useDisclosure()
  const { pathname } = useLocation()
  useEffect(() => {
    modalState.close()
  }, [pathname])

  const inSidebar = isAuthenticated && allowSidebarFallback

  if (inSidebar) {
    return (
      <SidebarLayout>
        <PageContent $padding={false}>{children}</PageContent>
      </SidebarLayout>
    )
  }

  return (
    <TopbarPageLayoutContainer>
      <TopBar
        onClickHamburger={modalState.toggle}
        hamburgerOpen={modalState.isOpen}
        color={color}
      />
      {!modalState.isOpen && (
        <PageContent $padding={!noChildrenPadding}>{children}</PageContent>
      )}
      {modalState.isOpen && <Sidebar fullWidth />}
    </TopbarPageLayoutContainer>
  )
}

export default TopbarLayout
