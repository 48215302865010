import { Breakpoint, Grid } from 'antd'

import { BreakpointComponents, Breakpoints } from '../constants/breakpoints'

const BreakpointToIndexMap: Record<Breakpoint, number> = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 5,
}

const getState = (screens: Partial<Record<Breakpoint, boolean>>) => {
  try {
    const listOfScreens = screens
      ? (Array.from(Object.keys(screens || {})) as Breakpoint[])
      : []
    return {
      currentScreenIndex: listOfScreens.findLastIndex(
        (screen: Breakpoint) => screens[screen]
      ),
      screensDataLoading: listOfScreens.length === 0,
    }
  } catch (e) {
    return {
      currentScreenIndex: -1,
      screensDataLoading: false,
    }
  }
}

export const useCurrentBreakpoint = () => {
  const screens = Grid.useBreakpoint()

  const { currentScreenIndex, screensDataLoading } = getState(screens)
  const isEqual = (breakpoint: Breakpoint): boolean => {
    return (
      !screensDataLoading &&
      currentScreenIndex === BreakpointToIndexMap[breakpoint]
    )
  }
  const isLargerThan = (breakpoint: Breakpoint): boolean => {
    return (
      !screensDataLoading &&
      currentScreenIndex > BreakpointToIndexMap[breakpoint]
    )
  }
  const isLargerThanOrEqual = (breakpoint: Breakpoint): boolean => {
    return (
      !screensDataLoading &&
      currentScreenIndex >= BreakpointToIndexMap[breakpoint]
    )
  }
  const isSmallerThan = (breakpoint: Breakpoint): boolean => {
    return (
      !screensDataLoading &&
      currentScreenIndex < BreakpointToIndexMap[breakpoint]
    )
  }
  const isSmallerThanOrEqual = (breakpoint: Breakpoint): boolean => {
    return (
      !screensDataLoading &&
      currentScreenIndex <= BreakpointToIndexMap[breakpoint]
    )
  }
  const isSmall = (breakpointComponent: BreakpointComponents): boolean => {
    return (
      !screensDataLoading &&
      isSmallerThanOrEqual(Breakpoints[breakpointComponent])
    )
  }
  return {
    isEqual,
    isLargerThan,
    isLargerThanOrEqual,
    isSmallerThan,
    isSmallerThanOrEqual,
    isSmall,
    isSmallScreen: () => {
      return isSmallerThanOrEqual('md')
    },
  }
}
