import { useCallback, useMemo } from 'react'

import { faSquare } from '@fortawesome/pro-regular-svg-icons'
import { RecordTypeFragment } from 'types/graphql'

import { routes, useLocation, useParams } from '@redwoodjs/router'

import Navigation from 'src/components/Sidebar/Navigation/Navigation'

import {
  useAmIInspector,
  useAmIReviewer,
  useAppStateContext,
  useIsRestrictedReviewer,
} from '../../layouts/AppStateContextLayout/utils'
import { getIcon } from '../../utils'

type Props = {
  isOpen: boolean
  onToggleIsOpen: () => void
  recordType: RecordTypeFragment
}
export const RecordTypeNavItems = ({
  isOpen,
  onToggleIsOpen,
  recordType: { id, name, icon, category, recordName, allowInspections },
}: Props) => {
  const { identifier, recordTypeId, recordUuid, slug } = useParams()
  const { pathname } = useLocation()
  const isReviewer = useAmIReviewer()
  const isRestrictedReviewer = useIsRestrictedReviewer()
  const isInspector = useAmIInspector()
  const appState = useAppStateContext()
  const currentlyViewedRecordTypeId = appState?.currentlyViewedRecordTypeId

  const checkIsActive = useCallback(
    (href: string) => pathname === href,
    [pathname]
  )
  const getIsPermitsActive = useCallback(() => {
    const isOnRecordPage = recordUuid
      ? checkIsActive(routes.record({ recordUuid }))
      : false
    if (category === 'Violations') {
      if (slug) {
        return checkIsActive(routes.submitComplaintRecordType({ slug }))
      }
      return checkIsActive(routes.recordsList({ recordTypeId: id }))
    }
    if (!identifier && !recordTypeId) {
      return false
    }
    if (!identifier && recordTypeId) {
      return (
        isOnRecordPage ||
        checkIsActive(routes.recordsList({ recordTypeId: id })) ||
        (slug &&
          checkIsActive(routes.recordTemplatePick({ recordTypeId: id, slug })))
      )
    }
    if (identifier && !recordTypeId) {
      return false
    }
    return (
      isOnRecordPage || checkIsActive(routes.recordsList({ recordTypeId: id }))
    )
  }, [category, checkIsActive, id, identifier, recordTypeId, recordUuid, slug])

  const getIsPlanReviewsActive = useCallback(() => {
    if (!recordTypeId) {
      return false
    }
    return checkIsActive(routes.recordsPlanReviews({ recordTypeId: id }))
  }, [checkIsActive, id, recordTypeId])

  const getIsInspectionsActive = useCallback(() => {
    if (!recordTypeId) {
      return false
    }
    return checkIsActive(routes.recordsInspections({ recordTypeId: id }))
  }, [checkIsActive, id, recordTypeId])

  const showInspections = useMemo(() => {
    if (!allowInspections) {
      return false
    }
    if (isRestrictedReviewer && !isInspector) {
      return false
    }
    return true
  }, [allowInspections, isInspector, isRestrictedReviewer])

  const isPermitsActive = getIsPermitsActive()

  return (
    <Navigation.Group isOpen={isOpen} onToggleIsOpen={onToggleIsOpen}>
      <Navigation.GroupHeader
        icon={icon ? getIcon(icon) : faSquare}
        title={name}
      />
      <Navigation.GroupList>
        {!isReviewer && (
          <Navigation.GroupListItem
            title={recordName ? `${recordName}s` : 'Permits'}
            isActive={isPermitsActive || currentlyViewedRecordTypeId === id}
            href={routes.recordsList({ recordTypeId: id })}
          />
        )}
        {category === 'Permits' && (
          <Navigation.GroupListItem
            title="Plan Reviews"
            isActive={getIsPlanReviewsActive()}
            href={routes.recordsPlanReviews({ recordTypeId: id })}
          />
        )}
        {showInspections && (
          <Navigation.GroupListItem
            title="Inspections"
            isActive={getIsInspectionsActive()}
            href={routes.recordsInspections({ recordTypeId: id })}
          />
        )}
      </Navigation.GroupList>
    </Navigation.Group>
  )
}
