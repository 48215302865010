import { saveAs } from 'file-saver'
import JSZip from 'jszip'

export interface DownloadSingleFileParams {
  url: string
  name: string
  type: string
}

export interface DownloadZipFileParams {
  zipFileName: string
  files: DownloadSingleFileParams[]
}

const makeFileName = (fileName: string, type: string) => {
  const parsedType = type === 'text/plain' ? 'txt' : type.split('/')?.[1]
  const res = fileName.endsWith(parsedType)
    ? fileName
    : `${fileName}.${parsedType}`
  return res
}

export const downloadZip = async (params: DownloadZipFileParams) => {
  const { zipFileName, files } = params
  const zip = new JSZip()

  // Fetch files and add them to the zip
  const fetchPromises = files.map(async (file) => {
    const response = await fetch(file.url)
    const blob = await response.blob()
    const name = makeFileName(file.name, file.type)
    zip.file(name, blob)
  })

  await Promise.all(fetchPromises)

  // Generate the zip file
  zip
    .generateAsync({ type: 'blob' })
    .then((content) => {
      // Save the zip file
      saveAs(content, zipFileName)
    })
    .catch((error) => {
      console.error('Error generating zip file:', error)
    })
}

export const downloadFile = async (params: DownloadSingleFileParams) => {
  const { url, name, type } = params
  const response = await fetch(url)
  const blob = await response.blob()

  const fileName = makeFileName(name, type)

  saveAs(blob, fileName)
}
