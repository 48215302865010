import { makeAutoObservable, runInAction } from 'mobx'
import {
  ViewColumnTemplateFragment,
  ViewColumnType,
  ViewFilterFragment,
} from 'types/graphql'

import { TableColumnTemplate } from './TableColumnTemplate'
import { TableViewFilter } from './TableViewFilter'

export class FilterManager {
  public readonly columnTemplates: TableColumnTemplate[]

  private _filtersByColumnType: Map<ViewColumnType, TableViewFilter> = new Map<
    ViewColumnType,
    TableViewFilter
  >()

  constructor(args: {
    filters: ViewFilterFragment[]
    columnTemplates: ViewColumnTemplateFragment[]
  }) {
    this.columnTemplates = runInAction(() =>
      args.columnTemplates
        .map((ct) => TableColumnTemplate.fromProtocol(ct))
        .sort((a, b) =>
          a.defaultLabelSentenceCase.localeCompare(b.defaultLabelSentenceCase)
        )
    )
    runInAction(() => {
      const filterEntries: [ViewColumnType, TableViewFilter][] = []
      args.filters.forEach((f) => {
        const columnTemplate = this.columnTemplates.find(
          (ct) => ct.columnType === f.columnType
        )
        if (!columnTemplate) {
          return // Should not happen
        }
        filterEntries.push([
          f.columnType,
          new TableViewFilter({
            columnTemplate,
            protocol: f,
          }),
        ])
      })
      this._filtersByColumnType = new Map(filterEntries)

      // Make sure every column type is represented in the map, even if none were saved to the db because they were not populated
      this.columnTemplates.forEach((ct) => {
        if (!this._filtersByColumnType.has(ct.columnType)) {
          this._filtersByColumnType.set(
            ct.columnType,
            new TableViewFilter({
              columnTemplate: ct,
              protocol: {
                columnType: ct.columnType,
                bool: undefined,
                optionsSelected: undefined,
              },
            })
          )
        }
      })
    })
    makeAutoObservable(this)
  }

  public get filterableColumnTemplates(): TableColumnTemplate[] {
    return this.columnTemplates.filter((ct) => ct.isFilterable)
  }

  public get sortableColumnTemplates(): TableColumnTemplate[] {
    return this.columnTemplates.filter((ct) => ct.isSortable)
  }

  public get populatedFilters(): TableViewFilter[] {
    return this.filters.filter((f) => f.isPopulated)
  }

  public get filters(): TableViewFilter[] {
    return Array.from(this._filtersByColumnType.values())
  }

  public get hasUnsavedChanges(): boolean {
    return Array.from(this._filtersByColumnType.values()).some(
      (f) => f.hasUnsavedChanges
    )
  }

  public clearAllFilters() {
    this._filtersByColumnType = new Map<ViewColumnType, TableViewFilter>()
  }

  public getFilterByColumnType(columnType: ViewColumnType): TableViewFilter {
    const filter = this._filtersByColumnType.get(columnType)
    if (!filter) {
      throw new Error('Filter matching column type not found')
    }
    return filter
  }

  public reset() {
    this._filtersByColumnType.forEach((f) => {
      f.reset()
    })
  }

  public save() {
    this._filtersByColumnType.forEach((f) => {
      f.save()
    })
  }
}
