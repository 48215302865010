import omit from 'lodash.omit'
import { makeAutoObservable } from 'mobx'
import {
  ViewColumnTemplateFragment,
  ViewFragment,
  ViewType,
} from 'types/graphql'

import { TableView } from './TableView'
export class TableViewManager {
  public readonly recordTypeId?: number
  public readonly viewType: ViewType

  private _columnTemplates: ViewColumnTemplateFragment[] = []
  private _views: TableView[] = []
  public selectedView: TableView | undefined

  constructor(args: {
    columnTemplates: ViewColumnTemplateFragment[]
    recordTypeId?: number
    views: ViewFragment[]
    viewType: ViewType
  }) {
    this.recordTypeId = args.recordTypeId
    this.viewType = args.viewType
    this.initializeViews({
      columnTemplates: args.columnTemplates,
      views: args.views,
    })
    makeAutoObservable(this)
  }

  public initializeViews(args: {
    columnTemplates: ViewColumnTemplateFragment[]
    views: ViewFragment[]
  }) {
    this._columnTemplates = args.columnTemplates
    this._views = args.views
      .sort((a, b) => a.displayIndex - b.displayIndex)
      .map((v) => TableView.fromProtocol(v, this._columnTemplates))
    this.selectedView = this._views[0]
  }

  public get columnTemplates(): ViewColumnTemplateFragment[] {
    return this._columnTemplates
  }

  public get views(): TableView[] {
    return this._views
  }

  public get viewsInDatabaseOrder(): TableView[] {
    return this._views
      .slice()
      .sort((a, b) => a.databaseDisplayIndex - b.databaseDisplayIndex)
  }

  public getCreateViewInput(view: ViewFragment | undefined) {
    return {
      ...omit(view, 'id'),
      recordTypeId: this.recordTypeId,
    }
  }

  public appendView(protocol: ViewFragment): TableView {
    const view = TableView.fromProtocol(protocol, this._columnTemplates)
    this._views.push(view)
    this._updateDisplayIndices()
    return view
  }

  public deleteView(index: number) {
    if (!this._isViewIndexValid(index)) {
      return
    }
    const viewToDelete = this._views[index]
    this._views.splice(index, 1)
    this._updateDisplayIndices()

    // Update the selected view if needed
    if (!this._views.length) {
      this.selectedView = undefined
    } else if (viewToDelete.id === this.selectedView?.id) {
      this.selectedView = this._views[0]
    }
  }

  public duplicateView(index: number) {
    if (!this._isViewIndexValid(index)) {
      return
    }
    const sourceView = this._views[index]
    const clone = sourceView.clone()
    clone.displayIndex = this._views.length
    this._views.push(clone)
  }

  public getViewById(viewId: number) {
    return this._getViewsById().get(viewId)
  }

  public reorderViews(viewIds: number[]) {
    if (viewIds.length !== this._views.length) {
      return
    }

    const viewsById = this._getViewsById()
    const newViews: TableView[] = []
    for (let i = 0; i < viewIds.length; i++) {
      const view = viewsById.get(viewIds[i])
      if (!view) {
        break
      }
      newViews.push(view)
    }
    if (newViews.length !== this._views.length) {
      return
    }

    this._views = newViews
    this._updateDisplayIndices()
  }

  public reset() {
    this._columnTemplates = []
    this._views = []
  }

  public saveViewsOrder() {
    this._views.forEach((v, index) => {
      v.databaseDisplayIndex = index
    })
  }

  public setSelectedViewId(viewId: number) {
    const view = this._views.find((v) => v.id === viewId)
    if (view) {
      this.selectedView = view
    }
  }

  private _getViewsById(): Map<number, TableView> {
    return new Map(this._views.map((v) => [v.id, v]))
  }

  private _isViewIndexValid(index: number) {
    return index >= 0 && index < this._views.length
  }

  private _updateDisplayIndices() {
    this._views.forEach((v, index) => {
      v.displayIndex = index
    })
  }
}
