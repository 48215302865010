import { useCallback, useMemo, useState } from 'react'

import { Reveal, themes } from '@evervault/react'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ForeignValueType } from '@prisma/client'
import { Button, Flex, Skeleton } from 'antd'

import Text, { TextSize, getFontSize } from 'src/components/Typography/Text'
import {
  EnvVariables,
  EvervaultRelayUrl,
  GovWellTokenHeader,
} from 'src/constants'
import { useForeignStorageTokenMutation } from 'src/fetch/foreignStorage'
import useDisclosure from 'src/hooks/use-disclosure'
import { ForeignValueTypeMaskMap } from 'src/utils/foreignStorage'

const WIDTH = '108px' // unable to identify a way of overriding the fixed 300px width of the evervault iframe body
const PADDING_BOTTOM = '2px' // unable to identify a way of addressing the fixed 2px bottom padding of the evervault iframe body

type ForeignValueViewProps = {
  type: ForeignValueType
  id: number
  userHasAccessToForeignValue: boolean
}

export const ForeignValueDisplay = ({
  id,
  type,
  userHasAccessToForeignValue,
}: ForeignValueViewProps) => {
  const { mutateAsync: getToken, isPending: isLoadingToken } =
    useForeignStorageTokenMutation()
  const { isOpen, open, close } = useDisclosure()
  const {
    isOpen: isReady,
    open: markIsReady,
    close: markIsNotReady,
  } = useDisclosure()
  const [token, setToken] = useState('')
  const retrieveToken = useCallback(async () => {
    const result = await getToken({})
    setToken(result.getForeignStorageToken.token)
    open()
  }, [getToken, open])

  const getRequest = useCallback(() => {
    return new Request(`${EvervaultRelayUrl}?foreignValueId=${id}`, {
      headers: {
        'X-Evervault-Api-Key': EnvVariables.EvervaultApiKey ?? '',
        'X-Evervault-App-Id': EnvVariables.EvervaultAppId ?? '',
        [GovWellTokenHeader]: token,
        'ngrok-skip-browser-warning': 'true',
      },
      mode: 'cors',
    })
  }, [id, token])

  const format = useMemo(() => {
    switch (type) {
      case 'SocialSecurityNumber':
        return {
          regex: /(.{3})(.{2})(.{4})/g,
          replace: '$1-$2-$3',
        }
      case 'EmployerIdentificationNumber':
        return {
          regex: /(.{2})(.{7})/g,
          replace: '$1-$2',
        }
      default:
        return undefined
    }
  }, [type])

  if (!isOpen) {
    return (
      <Flex align="center">
        <Text
          style={{
            width: WIDTH,
            paddingBottom: PADDING_BOTTOM,
            letterSpacing: '2px',
          }}
        >
          {ForeignValueTypeMaskMap[type]}
        </Text>
        {userHasAccessToForeignValue && (
          <Button
            type="link"
            icon={<FontAwesomeIcon icon={faEye} />}
            onClick={retrieveToken}
            loading={isLoadingToken}
          />
        )}
      </Flex>
    )
  }

  return (
    <Flex align="center" justify="start">
      {token ? (
        <div style={{ width: WIDTH }}>
          {!isReady && (
            <Skeleton.Input
              active
              style={{
                width: '90px',
                minWidth: '90px',
                marginRight: '12px',
                height: '22px',
                paddingBottom: PADDING_BOTTOM,
              }}
            />
          )}
          <div style={{ display: isReady ? 'block' : 'none' }}>
            <Reveal
              request={getRequest()}
              onReady={markIsReady}
              onError={() => setToken(undefined)}
            >
              <Reveal.Text
                path="$.value"
                format={format}
                theme={themes.minimal({
                  fonts: ['Public Sans'],
                  styles: {
                    '&': {
                      fontSize: getFontSize(TextSize.Base),
                    },
                  },
                })}
              />
            </Reveal>
          </div>
        </div>
      ) : (
        <Text>Failed to retrieve value.</Text>
      )}
      <Button
        type="link"
        icon={<FontAwesomeIcon icon={faEyeSlash} />}
        onClick={() => {
          close()
          markIsNotReady()
        }}
      />
    </Flex>
  )
}
