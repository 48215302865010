export const RecordTaskTemplateGroupOptionFragment = gql`
  fragment RecordTaskTemplateGroupOptionFragment on RecordTaskTemplateGroup {
    id
    identifier
    type
    workflowTemplate {
      id
      name
    }
  }
`
