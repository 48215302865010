import { ReactNode } from 'react'

import {
  faCheck,
  faCircleQuestion as faCircleQuestionRegular,
} from '@fortawesome/pro-regular-svg-icons'
import { faCircleQuestion as faCircleQuestionSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import useDisclosure, { UseDisclosureReturn } from '../../hooks/use-disclosure'
import { useHover } from '../../hooks/use-hover'
import { useTheme } from '../../hooks/use-theme'
import Modal from '../Modal'
import Text, { TextSize } from '../Typography/Text'

import { RenderedRichTextInput } from './RichTextInput'

interface Props {
  label: ReactNode
  tooltip?: string
}

const LabelContainer = styled.div`
  display: flex;
  gap: 4px;
  color: ${({ theme }) => theme.colorTextLabel};
`

// https://github.com/zenoamaro/react-quill/pull/913
const EMPTY_TOOLTIP = '<p><br></p>'

export const Label = (props: Props) => {
  const { label, tooltip } = props
  const { getTokenVal } = useTheme()
  const { hovered, hoverProps } = useHover()
  const modalState = useDisclosure()
  const onClick: React.MouseEventHandler = (e) => {
    e.preventDefault()
    modalState.open()
  }
  if (tooltip && tooltip !== EMPTY_TOOLTIP) {
    return (
      <LabelContainer>
        <div>{label}</div>
        <FontAwesomeIcon
          onClick={onClick}
          {...hoverProps}
          style={{ cursor: 'pointer' }}
          icon={hovered ? faCircleQuestionSolid : faCircleQuestionRegular}
          color={getTokenVal('colorPrimaryBase')}
        />
        <TooltipModal {...modalState} tooltip={tooltip} label={label} />
      </LabelContainer>
    )
  }
  if (typeof label === 'string') {
    return <Text colorToken="colorTextLabel">{label}</Text>
  }
  return label
}

interface TooltipModalProps extends UseDisclosureReturn {
  label: ReactNode
  tooltip: string
}
const TooltipModal = (props: TooltipModalProps) => {
  const { tooltip, label, ...rest } = props
  return (
    <Modal
      {...rest}
      title={label}
      okText="Ok"
      icon={faCheck}
      onOk={rest.close}
      hideCancelButton
    >
      <Text size={TextSize.Base} margin="0 0 24px 0">
        <RenderedRichTextInput>{tooltip}</RenderedRichTextInput>
      </Text>
    </Modal>
  )
}
