import { ReactNode, useContext, useState } from 'react'

import * as ReactRadioGroup from '@radix-ui/react-radio-group'
import { Flex } from 'antd'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import Text from 'src/components/Typography/Text'

type RadioGroupVariant = 'default' | 'combo-box'

type RadioGroupBaseProps<TValue extends string> = {
  children: ReactNode
  onValueChange: (value: TValue) => void
  value: TValue | undefined
  variant?: RadioGroupVariant
}
const RadioGroupBase = <TValue extends string>({
  children,
  onValueChange,
  value,
  variant = 'default',
}: RadioGroupBaseProps<TValue>) => {
  return (
    <RadioGroupContext.Provider value={{ variant, selectedValue: value }}>
      <ReactRadioGroup.Root value={value} onValueChange={onValueChange}>
        <Flex
          wrap={variant === 'combo-box' ? 'nowrap' : 'wrap'}
          gap={variant === 'combo-box' ? undefined : '6px'}
        >
          {children}
        </Flex>
      </ReactRadioGroup.Root>
    </RadioGroupContext.Provider>
  )
}

const StyledRadioGroupItemContainer = styled(Flex).attrs({
  flex: 1,
  align: 'center',
  justify: 'center',
  gap: '6px',
  wrap: 'nowrap',
})<{
  $isSelected: boolean
  $variant: RadioGroupVariant
}>`
  ${({ $isSelected, $variant, theme }) =>
    $variant === 'combo-box' &&
    `
    height: 32px;
    border: solid 1px ${
      $isSelected ? theme.colorPrimaryBase : theme.colorBorder
    };
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  `}
`
const StyledRadioGroupItem = styled(ReactRadioGroup.Item)<{
  $variant: RadioGroupVariant
}>`
  display: ${({ $variant }) => ($variant === 'default' ? 'block' : 'none')};
  cursor: pointer;
`
const StyledLabel = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
`

type RadioGroupItemProps<TValue extends string> = {
  children: ReactNode
  value: TValue
}
const RadioGroupItem = <TValue extends string>({
  children,
  value,
}: RadioGroupItemProps<TValue>) => {
  const [id] = useState(uuid())
  const { variant, selectedValue } = useRadioGroupContext<TValue>()
  const isSelected = selectedValue === value
  return (
    <StyledRadioGroupItemContainer $isSelected={isSelected} $variant={variant}>
      <StyledRadioGroupItem $variant={variant} value={value} id={id}>
        <ReactRadioGroup.Indicator />
      </StyledRadioGroupItem>
      <StyledLabel htmlFor={id} style={{ cursor: 'pointer' }}>
        {typeof children === 'string' ? (
          <Text
            colorToken={
              variant === 'combo-box' && isSelected
                ? 'colorPrimaryBase'
                : undefined
            }
            whiteSpace={variant === 'combo-box' ? 'nowrap' : undefined}
            center={variant === 'combo-box'}
            style={{ cursor: 'pointer' }}
          >
            {children}
          </Text>
        ) : (
          children
        )}
      </StyledLabel>
    </StyledRadioGroupItemContainer>
  )
}

type RadioGroupContextType<TValue> = {
  variant: RadioGroupVariant
  selectedValue: TValue
}
const RadioGroupContext = React.createContext<RadioGroupContextType<any>>(
  {} as RadioGroupContextType<any>
)
const useRadioGroupContext = <TValue extends string>() =>
  useContext<RadioGroupContextType<TValue>>(RadioGroupContext)

export default Object.assign(RadioGroupBase, {
  Item: RadioGroupItem,
})
