import isNil from 'lodash.isnil'
import omit from 'lodash.omit'
import { makeAutoObservable, toJS } from 'mobx'
import {
  ViewColumnType,
  ViewFilter,
  ViewFilterDateFragment,
  ViewFilterFragment,
} from 'types/graphql'

import { TableColumnTemplate } from 'src/models/TableViews/TableColumnTemplate'
import { TableViewDateFilter } from 'src/models/TableViews/TableViewDateFilter'
import {
  RecordTaskGroupTypeToRecordTaskGroupNameMap,
  formatUserName,
} from 'src/utils'

import {
  RecordTaskStatusTypeDisplayMap,
  RecordTaskTypeDisplayMap,
  ViolationStatusToDisplayMap,
} from '../../constants'

const EMPTY_DATE_PROTOCOL: ViewFilterDateFragment = { type: 'Exact' }

export class TableViewFilter {
  public readonly columnType: ViewColumnType
  public readonly columnTemplate: TableColumnTemplate

  public optionsSelected: string[] = []
  public bool: boolean | null | undefined
  public date: TableViewDateFilter = new TableViewDateFilter(
    EMPTY_DATE_PROTOCOL
  )
  public isNull: boolean | null | undefined

  // This value should always get overwritten in the constructor via _setProtocolValues, just satisfying initializer requirement
  private _databaseValue: ViewFilter = {
    columnType: 'Record',
  }

  constructor(args: {
    protocol: ViewFilter
    columnTemplate: TableColumnTemplate
  }) {
    const { protocol, columnTemplate } = args
    this.columnTemplate = columnTemplate
    this.columnType = protocol.columnType
    this._setProtocolValues(protocol)
    makeAutoObservable(this)
  }

  private _setProtocolValues(protocol: ViewFilter) {
    this.optionsSelected = protocol.optionsSelected ?? []
    this.bool = protocol.bool
    this.isNull = protocol.isNull
    this.date = new TableViewDateFilter(
      protocol.date ? omit(protocol.date, '__typename') : EMPTY_DATE_PROTOCOL
    )
    this._databaseValue = {
      ...omit(protocol, '__typename'),
      date: omit(protocol.date, '__typename'),
    }
  }

  public get hasUnsavedChanges() {
    return !this._equals(this._databaseValue)
  }

  public get isPopulated(): boolean {
    return TableViewFilter.isFilterPopulated(this.protocol)
  }

  public clear() {
    this.optionsSelected = []
    this.isNull = null
    this.bool = null
    this.date = new TableViewDateFilter(EMPTY_DATE_PROTOCOL)
  }

  public clone(): TableViewFilter {
    return new TableViewFilter({
      protocol: this.protocol,
      columnTemplate: this.columnTemplate,
    })
  }

  private _equals(otherFilterProtocol: ViewFilter): boolean {
    return (
      this.protocol.bool === otherFilterProtocol.bool &&
      this.protocol.isNull === otherFilterProtocol.isNull &&
      this.date.equals(otherFilterProtocol.date) &&
      (this.protocol.optionsSelected ?? []).slice().sort().join() ===
        (otherFilterProtocol.optionsSelected ?? []).slice().sort().join()
    )
  }

  public equals(otherFilter: TableViewFilter): boolean {
    return this._equals(otherFilter.protocol)
  }

  public static isFilterPopulated(protocol: ViewFilterFragment | undefined) {
    return (
      !!protocol &&
      (!isNil(protocol.bool) ||
        !isNil(protocol.isNull) ||
        !!protocol.optionsSelected?.length ||
        TableViewDateFilter.isDatePopulated(protocol.date))
    )
  }

  public getOptionDisplayValue(id: string): string {
    const filterOption = this.columnTemplate.filterOptions?.find(
      (o) => o.identifier === id
    )
    if (!filterOption) {
      return ''
    }

    const data = filterOption.data
    switch (data.__typename) {
      case 'ViewDataElementRecordActionRequiredBy':
        return data.recordActionRequiredBy
      case 'ViewDataElementRecordTaskGroupType':
        return RecordTaskGroupTypeToRecordTaskGroupNameMap[
          data.recordTaskGroupType
        ]
      case 'ViewDataElementRecordTemplate':
        return data.recordTemplate.name ?? ''
      case 'ViewDataElementRecordTypeStatus':
        return data.recordTypeStatus?.name
      case 'ViewDataElementString':
        return data.string
      case 'ViewDataElementUser':
        return formatUserName({
          firstName: data.user.firstName,
          lastName: data.user.lastName,
        })
      case 'ViewDataElementViolationStatus': {
        return ViolationStatusToDisplayMap[data.violationStatus]
      }
      case 'ViewDataElementViolationType': {
        return data.violationType.name
      }
      case 'ViewDataElementInspectionTemplate': {
        return data.inspectionTemplate.name ?? ''
      }
      case 'ViewDataElementRecordTaskInspectionResult': {
        return data.recordTaskInspectionResult?.name ?? ''
      }
      case 'ViewDataElementRecordTaskInspectionStatus': {
        return data.recordTaskInspectionStatus ?? ''
      }
      case 'ViewDataElementRecordTaskStatus': {
        return RecordTaskStatusTypeDisplayMap[data.recordTaskStatus]
      }
      case 'ViewDataElementRecordTaskType': {
        return RecordTaskTypeDisplayMap[data.recordTaskType]
      }
      default:
        return ''
    }
  }

  private _getIndexOfOption(option: string) {
    return this.optionsSelected?.findIndex((o) => o === option) ?? -1
  }

  public unselectOption(option: string) {
    const index = this._getIndexOfOption(option)
    if (index < 0) {
      return
    }
    const newOptionsSelected = this.optionsSelected.slice()
    newOptionsSelected.splice(index, 1)
    newOptionsSelected.sort()
    this.optionsSelected = newOptionsSelected
  }

  public selectOption(option: string) {
    const index = this._getIndexOfOption(option)
    if (index >= 0) {
      return
    }
    const newOptionsSelected = this.optionsSelected?.slice() ?? []
    newOptionsSelected.push(option)
    newOptionsSelected.sort()
    this.optionsSelected = newOptionsSelected
  }

  public reset() {
    this._setProtocolValues(this._databaseValue)
  }

  public save() {
    this._databaseValue = { ...toJS(this.protocol) }
  }

  public get protocol(): ViewFilter {
    return {
      columnType: this.columnType,
      bool: this.bool,
      date: toJS(this.date.protocol),
      isNull: this.isNull,
      optionsSelected: toJS(this.optionsSelected),
    }
  }

  public get databaseProtocol(): ViewFilter {
    return {
      ...this._databaseValue,
      date: TableViewDateFilter.isDatePopulated(this._databaseValue.date)
        ? toJS(this._databaseValue.date)
        : undefined,
      optionsSelected: toJS(this._databaseValue.optionsSelected),
    }
  }
}
