import styled from 'styled-components'

import {
  StylePropsMobile,
  getLightContainerStyleProps,
} from '../shared/StyledComponents'

export const FileCard = styled.div<StylePropsMobile>`
  ${(props) => getLightContainerStyleProps(props.theme)}
  display: flex;
  flex-direction: ${(props) => (props.$small ? 'row' : 'column')};
  padding: ${(props) => (props.$small ? '8px 16px' : '12px 24px')};
  gap: 12px;
  width: 100%;
  align-items: center;
  position: relative;
`
