import React, { useEffect, useState } from 'react'

import { useAuth as useClerkAuth } from '@clerk/clerk-react'

import { LoadingSpin } from 'src/components/shared/StyledComponents'
import { useAppStateQuery } from 'src/fetch/appState'
import { useMonitoring } from 'src/utils/monitoring'

import { clearRedirect } from '../../hooks/use-auth-redirect'
import { useSetUpCobrowse } from '../../hooks/use-set-up-cobrowse'
import { useSetUpIntercom } from '../../hooks/use-set-up-intercom'

import { AppStateContext, AppStateContextData } from './types'
import { useOrganizationBySlugQuery } from './use-organization-by-slug-query'
import { useReleaseNotification } from './use-release-notification'

type Props = {
  children?: React.ReactNode
}
const AppStateContextLayout = ({ children }: Props) => {
  const { isLoaded, isSignedIn } = useClerkAuth()
  const {
    data: { company, organization, user },
    refetch,
  } = useAppStateQuery()

  useEffect(() => {
    clearRedirect()
  }, [])

  useSetUpIntercom({ me: user, organization })
  useSetUpCobrowse()

  useReleaseNotification()

  const { organizationBySlug, isLoading: isLoadingOrganizationBySlug } =
    useOrganizationBySlugQuery()

  const [currentlyViewedRecordTypeId, setCurrentlyViewedRecordTypeId] =
    useState<number | undefined>(undefined)
  const contextData: AppStateContextData = {
    user,
    organization,
    organizationBySlug,
    company,
    recordTypes: user?.recordTypeAccesses?.map((rta) => rta.recordType) || [],
    currentlyViewedRecordTypeId,
    setCurrentlyViewedRecordTypeId,
    refetch,
  }

  useMonitoring(contextData)

  if (
    !isLoaded || // Not ready to make decision about which layout to show
    (!isSignedIn && isLoadingOrganizationBySlug) || // Not ready to render topbar for unauthenticated users; DO NOT show org loading state for authenticated users, will cause layout flash on record application page
    (isSignedIn && !user) // Not ready to render sidebar for authenticated users
  ) {
    return <LoadingSpin />
  }

  return (
    <AppStateContext.Provider value={contextData}>
      {children}
    </AppStateContext.Provider>
  )
}

export default AppStateContextLayout
