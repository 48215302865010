import {
  useMyOrg,
  useOrgSlugMetadata,
} from '../layouts/AppStateContextLayout/utils'
import { useRecordCellContext } from '../pages/RecordPage/RecordCell/Context'

export const useOrgUuid = () => {
  const recordCellContext = useRecordCellContext()
  const orgSlugMetadata = useOrgSlugMetadata()
  const myOrg = useMyOrg()
  return (
    recordCellContext?.orgUuid || orgSlugMetadata?.uuid || myOrg?.uuid || ''
  )
}
