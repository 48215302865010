import { useMemo } from 'react'

import { Flex } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

import DatePicker, {
  DatePickerAllowedDates,
} from 'src/components/form/DatePicker'
import { RadioGroup, RadioItem } from 'src/components/form/RadioGroup'
import Text from 'src/components/Typography/Text'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'

export type InspectionEndedAtEntryType = 'Automatic' | 'Manual'

const InspectionTimingInputs = () => {
  const form = useFormInstance()
  useWatch(['endedAtEntryType'], form)
  const endedAtEntryType: InspectionEndedAtEntryType =
    form.getFieldValue('endedAtEntryType')
  const endedAtRadioItems: RadioItem<InspectionEndedAtEntryType>[] = useMemo(
    () => [
      {
        display: 'When logged',
        value: 'Automatic',
      },
      {
        display: (
          <Flex align="center" gap="12px" justify="space-between">
            <Text whiteSpace="nowrap">Custom</Text>
            {endedAtEntryType === 'Manual' && (
              <DatePicker
                fieldName="endedAt"
                showTime
                noMargin
                allowedDates={DatePickerAllowedDates.All}
                required
                errorMessage="You must enter an inspection end date"
                width="240px"
              />
            )}
          </Flex>
        ),
        value: 'Manual',
      },
    ],
    [endedAtEntryType]
  )

  const { isSmallScreen } = useCurrentBreakpoint()

  return (
    <Flex justify="space-between" gap="24px" vertical={isSmallScreen()}>
      <Flex flex={1.5}>
        <DatePicker
          label="Started Inspection At"
          fieldName="startedAt"
          showTime
          noMargin
          allowClear={false}
          allowedDates={DatePickerAllowedDates.All}
        />
      </Flex>
      <Flex flex={2}>
        <RadioGroup
          label="Ended Inspection At"
          items={endedAtRadioItems}
          fieldName="endedAtEntryType"
          vertical
          width="100%"
        />
      </Flex>
    </Flex>
  )
}

export default React.memo(InspectionTimingInputs)
