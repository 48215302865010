import React from 'react'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

import { useMyUser } from '../AppStateContextLayout/utils'

type Props = {
  children?: React.ReactNode
}

const AuthGateLayout = ({ children }: Props) => {
  const { isAuthenticated, loading: isLoadingAuth } = useAuth()
  const user = useMyUser()

  const { pathname } = useLocation()
  if (!isAuthenticated && !isLoadingAuth) {
    navigate(routes.login(pathname ? { redirectTo: pathname } : {}))
  }

  if (!user) {
    return <></>
  }

  return <>{children}</>
}

export default AuthGateLayout
