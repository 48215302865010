import { useEffect } from 'react'

import { Form, FormListFieldData } from 'antd'

import {
  MultipleFormInputRow,
  SingleOrMultipleInput,
} from '../shared/SingleOrMultipleInput'
import Switch from '../Switch'
import { makeArrayFieldName } from '../util'

import {
  AddressAutocompleteInternal,
  AddressAutocompleteProps,
} from './Internals'

export type {
  AddressAutocompleteFieldValues,
  AddressAutocompleteOption
} from './Internals'

export const AddressAutocomplete = (props: AddressAutocompleteProps) => {
  const { fieldName } = props
  const { setFieldValue, getFieldValue } = Form.useFormInstance()

  useEffect(() => {
    const firstFieldName = makeArrayFieldName(
      makeArrayFieldName(fieldName, `${0}`),
      'isMainAddress'
    )
    if (getFieldValue(fieldName)?.length === 1) {
      setFieldValue(firstFieldName, true)
    }
  }, [])

  return (
    <SingleOrMultipleInput
      {...props}
      recordTitle="Address"
      renderInput={(params) => (
        <AddressAutocompleteInternal
          {...props}
          {...params}
          size={props.allowMultiple ? 'middle' : 'large'}
        />
      )}
      getAdditionalColumns={(fields: FormListFieldData[]) => {
        return [
          {
            title: 'Is Main Address?',
            render: (row: MultipleFormInputRow) => {
              const index = fields.findIndex((f) => f.name === row.field.name)
              const currFieldName = makeArrayFieldName(
                makeArrayFieldName(fieldName, `${index}`),
                'isMainAddress'
              )
              return (
                <Switch
                  fieldName={currFieldName}
                  onChange={(newValue: boolean) => {
                    if (!newValue) {
                      const firstFieldName = makeArrayFieldName(
                        makeArrayFieldName(fieldName, `${0}`),
                        'isMainAddress'
                      )
                      setFieldValue(firstFieldName, true)
                    } else {
                      fields.forEach((f) => {
                        if (f.name !== row.field.name) {
                          const otherIndex = fields.findIndex(
                            (field) => f.name === field.name
                          )
                          const otherFieldMainAddressName = makeArrayFieldName(
                            makeArrayFieldName(fieldName, `${otherIndex}`),
                            'isMainAddress'
                          )
                          setFieldValue(otherFieldMainAddressName, false)
                        }
                      })
                    }
                  }}
                />
              )
            },
          },
        ]
      }}
    />
  )
}
