import { faTrash } from '@fortawesome/pro-regular-svg-icons'

import { useCancelRecordTaskInspectionAttemptMutation } from '../../fetch/recordTaskInspectionAttempts'
import Modal from '../Modal'
import Text, { TextSize } from '../Typography/Text'

import { InspectionsTabModalProps } from './types'

export const CancelInspectionModal = (props: InspectionsTabModalProps) => {
  const {
    modalState,
    recordTaskInspectionAttemptId,
    inspectionTemplateName,
    refetch,
    status,
  } = props
  const { mutateAsync: cancel } = useCancelRecordTaskInspectionAttemptMutation()
  const onOk = async () => {
    await cancel({
      id: recordTaskInspectionAttemptId,
    })
    await refetch()
    modalState.close()
  }

  if (status !== 'Requested' && status !== 'Scheduled') {
    return null
  }

  const title =
    status === 'Requested' ? 'Cancel Inspection Request' : `Cancel Inspection`

  const word = status === 'Requested' ? 'request for the' : 'scheduled'

  return (
    <Modal
      {...modalState}
      icon={faTrash}
      title={title}
      okText={title}
      onOk={onOk}
    >
      <Text size={TextSize.Large}>
        Are you sure you would like to cancel the {word}{' '}
        {inspectionTemplateName} inspection?
      </Text>
    </Modal>
  )
}
