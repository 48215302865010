export const RecordTaskTemplateGroupRequestedRecordFragment = gql`
  fragment RecordTaskTemplateGroupRequestedRecordFragment on RecordTaskTemplateGroupRequestedRecordConfig {
    id
    ownedByApplicant
    ownedByRecordOwner
    ownerUser {
      ...UserDataFragment
    }
    requestedRecordTemplate {
      ...RecordTemplateOptionFragment
    }
  }
`
