export const ViolationFragment = gql`
  fragment ViolationFragment on Violation {
    id
    identifier
    address
    createdAt
    reportedAt
    importedAt
    status
    submitterType
    submitterComments
    submitterEmail
    submitterFirstName
    submitterLastName
    submitterPhone
    locationSnapshot {
      ...LocationSnapshotInDepthFragment
      owner
    }
    organization {
      id
      shouldExposeViolations
    }
    otherViolationsAtParcel {
      ...ViolationFields
    }
    recordsAtParcel {
      ...RecordFragmentOverview
    }
    resolveComments
    resolvedAt
    resolvedByUserId
    resolvedByUser {
      ...UserDataFragment
    }
    createdByUser {
      ...UserDataFragment
    }
    violationCodes {
      id
      display
    }
    violationComments {
    ...ViolationCommentFragment
    }
    violationMailers {
      ...ViolationMailerFragment
    }
    violationInspections {
      ...ViolationInspectionFragment
    }
    violationFiles {
      ...ViolationFileFragment
    }
    violationHistory {
      ...ViolationTypeHistoryFragment
    }
    previousViolationHistory {
      ...ViolationTypeHistoryFragment
    }
  }
`

export const ViolationTypeFragment = gql`
  fragment ViolationTypeFragment on ViolationType {
    id
    name
    description
    organizationId
  }
`
export const ViolationCodeFragment = gql`
  fragment ViolationCodeFragment on ViolationCode {
    id
    display
  }
`
export const ViolationMailerTitleFragment = gql`
  fragment ViolationMailerTitleFragment on ViolationMailerTitle {
    id
    title
  }
`
export const ViolationInspectionFragment = gql`
  fragment ViolationInspectionFragment on ViolationInspection {
    id
    createdAt
    scheduledFor
    loggedAt
    status
    comments
    cancelledAt
    createdByUser {
      ...UserDataFragment
    }
    loggedByUser {
      ...UserDataFragment
    }
    cancelledByUser {
      ...UserDataFragment
    }
    violationInspectionFiles {
      id
      file {
        name
        url {
          signedUrl
        }
      }
    }
  }
`

export const ViolationMailerFragment = gql`
  fragment ViolationMailerFragment on ViolationMailer {
    id
    title
    followUps
    comments
    owner
    tenant
    violationCodesOnMailers {
      violationCodeId
    }
    file {
      createdAt
      createdByUser {
        ...UserDataFragment
      }
      name
      url {
        signedUrl
      }
    }
  }
`

export const ViolationFileFragment = gql`
  fragment ViolationFileFragment on ViolationFile {
    id
    file {
      createdAt
      createdByUser {
        ...UserDataFragment
      }
      name
      url {
        signedUrl
      }
    }
  }
`


export const ViolationCommentFragment = gql`
  fragment ViolationCommentFragment on ViolationComment {
    id
      comment
      createdAt
      createdByUser {
        ...UserDataFragment
      }
  }
`