import { ReactNode, useEffect, useRef } from 'react'

import {
  IconDefinition,
  faDollar,
  faEnvelope,
  faHashtag,
  faPhone,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input as AntdInput, InputProps } from 'antd'
import Form, { FormInstance, Rule } from 'antd/es/form'
import isNil from 'lodash.isnil'
import styled, { css } from 'styled-components'

import { PhoneNumberRegex, ZipCodeRegex } from '../../constants'
import useDebounce from '../../hooks/use-debounce'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

type SizeProps = {
  $size: React.ComponentProps<typeof AntdInput>['size']
}
const HeightCss = css<SizeProps>`
  height: ${({ $size }) => {
    switch ($size) {
      case 'small':
        return '24px'
      case 'middle':
        return '32px'
      default:
        return '40px'
    }
  }};
`

const StyledInput = styled(AntdInput)<SizeProps>`
  ${HeightCss};

  .ant-input-affix-wrapper {
    ${HeightCss};
  }

  :not(.ant-input-affix-wrapper) > input {
    ${HeightCss};
  }
`

export enum InputTypes {
  Email = 'email',
  Phone = 'tel',
  Text = 'text',
  Number = 'number',
  Currency = 'currency',
  ZipCode = 'zip-code',
}

interface Props extends FormFieldWrapperProps {
  placeholder?: string
  type?: InputTypes
  prefix?: ReactNode
  suffix?: ReactNode
  suffixIcon?: IconDefinition
  prefixIcon?: IconDefinition
  size?: InputProps['size']
  min?: number
  max?: number
  id?: string
  onBlur?: (newVal?: string) => void | Promise<unknown> | Promise<void>
  validator?: (value: string) => boolean
  debounceMs?: number
  noIcon?: boolean
}

export function Input(props: Props) {
  const {
    placeholder,
    type = InputTypes.Text,
    prefix,
    size,
    min,
    max,
    onBlur,
    suffix,
    suffixIcon,
    debounceMs,
    prefixIcon,
    disabled,
    noIcon,
    rules: baseRules = [],
    ...formFieldWrapperProps
  } = props
  const { errorMessage, fieldName } = formFieldWrapperProps
  const getRules = (): Rule[] => {
    const required = !!errorMessage || props.required
    if (type === InputTypes.Phone) {
      return [
        {
          required,
          message: errorMessage,
          validateTrigger: 'onSubmit',
        },
        {
          pattern: PhoneNumberRegex,
          message: 'Please enter a valid phone number',
          validateTrigger: 'onSubmit',
        },
      ]
    }
    if (type === InputTypes.Email) {
      return [
        { required, message: errorMessage },
        {
          message: 'Please enter a valid email address',
          type: 'email',
          validateTrigger: 'onSubmit',
        },
      ]
    }
    if (type === InputTypes.Number || type === InputTypes.Currency) {
      return [{ required, message: errorMessage, validateTrigger: 'onSubmit' }]
    }
    if (type === InputTypes.ZipCode) {
      return [
        { required, message: errorMessage },
        {
          pattern: ZipCodeRegex,
          message: 'Please enter a valid zip code',
          validateTrigger: 'onSubmit',
        },
      ]
    }
    if (errorMessage) {
      return [{ required, message: errorMessage, validateTrigger: 'onSubmit' }]
    }
    return []
  }
  const rules = [...getRules(), ...baseRules]

  const getInputType = () => {
    switch (type) {
      case InputTypes.Currency: {
        return InputTypes.Number
      }
      default: {
        return type
      }
    }
  }

  const form = Form.useFormInstance()

  const getAddonBefore = () => {
    if (noIcon) {
      return null
    }
    if (prefixIcon) {
      return <FontAwesomeIcon icon={prefixIcon} />
    }
    switch (type) {
      case InputTypes.Currency: {
        return <FontAwesomeIcon icon={faDollar} />
      }
      case InputTypes.Number: {
        return <FontAwesomeIcon icon={faHashtag} />
      }
      case InputTypes.Email: {
        return <FontAwesomeIcon icon={faEnvelope} />
      }
      case InputTypes.Phone: {
        return <FontAwesomeIcon icon={faPhone} />
      }
      default: {
        return undefined
      }
    }
  }

  Form.useWatch(fieldName, form)

  const onBlurFn = async (
    value: string,
    setFieldValue: FormInstance['setFieldValue']
  ) => {
    if (type !== InputTypes.Currency && type !== InputTypes.Number) {
      await onBlur?.(value)
      return
    }
    let valueTemp = value
    // When the input field loses focus, enforce the minimum value
    if (min && parseFloat(value) < min) {
      setFieldValue(fieldName, min.toString())
      valueTemp = min.toString()
    }
    if (max && parseFloat(value) > max) {
      setFieldValue(fieldName, max.toString())
      valueTemp = max.toString()
    }

    if (
      (value && value.charAt(value.length - 1) === '.') ||
      (!isNil(min) && value === '-' && min >= 0)
    ) {
      valueTemp = value.slice(0, -1)
    }
    await onBlur?.(valueTemp)
  }

  const value = form.getFieldValue(fieldName)
  const debouncedValue = useDebounce(value, debounceMs)

  const isMounted = useRef(false)

  useEffect(() => {
    if (isMounted.current) {
      onBlurFn(debouncedValue, form.setFieldValue)
    } else {
      isMounted.current = true
    }
  }, [debouncedValue])

  // no scrolling at all
  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      const target = event.target as HTMLInputElement
      if (target.type === 'number') {
        event.preventDefault()
      }
    }
    // Attach the event listener to the document
    document.addEventListener('wheel', handleScroll, { passive: false })
    // Clean up
    return () => {
      document.removeEventListener('wheel', handleScroll)
    }
  }, [])

  return (
    <Wrapper {...formFieldWrapperProps} rules={rules}>
      {({ formInstance: { setFieldValue } }) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const inputValue = event.target.value
          const reg = /^-?\d+(\.\d{1,2})?$/
          const filteredVal =
            !isNil(min) && min >= 0 ? inputValue.replace('-', '') : inputValue
          if (reg.test(filteredVal) || filteredVal === '') {
            setFieldValue(fieldName, filteredVal)
          } else {
            setFieldValue(fieldName, filteredVal.slice(0, -1))
          }
        }

        return (
          <StyledInput
            disabled={disabled}
            placeholder={
              placeholder ||
              (typeof formFieldWrapperProps.label === 'string'
                ? formFieldWrapperProps.label
                : '')
            }
            suffix={suffixIcon ? <FontAwesomeIcon icon={suffixIcon} /> : suffix}
            type={getInputType()}
            step="any"
            size={size}
            $size={size}
            min={min}
            addonBefore={getAddonBefore()}
            onClick={(e) => e.stopPropagation?.()}
            onChange={
              type === InputTypes.Currency
                ? (event) => handleChange(event)
                : undefined
            }
            {...(prefix ? { prefix } : {})}
          />
        )
      }}
    </Wrapper>
  )
}

export default Input
