import {
  IconDefinition,
  faBuilding,
  faEnvelope,
  faFax,
  faIdCard,
  faLocationPin,
  faPhone,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from 'antd'
import styled from 'styled-components'

import { ForeignValueDisplay } from 'src/components/ForeignValues/ForeignValueDisplay'
import { RecordAutocompleteFieldValues } from 'src/components/form/shared/RecordAutocompleteCell/RecordAutocomplete'

import {
  FieldType,
  FileFragment,
  ForeignValue,
  State,
} from '../../../types/graphql'
import { formatPhoneNumber } from '../../utils'
import { DateFormats, formatDate } from '../../utils/date'
import RecordIdLinkCell from '../RecordIdLinkCell'
import { RenderedSignature } from '../RenderedSignature'
import { TableLinkNewPage } from '../shared/StyledComponents'

import { formatFieldContactAddress } from './util'

interface FieldDisplayInput {
  type: FieldType
  checked?: boolean | null
  num?: number | null
  str?: string | null
  strs?: (string | null)[] | null
  record?: RecordAutocompleteFieldValues['selectedOption'] | null
  inspectionResult?: {
    id?: number
  } | null
  contact?: {
    id?: number
    firstName?: string | null
    lastName?: string | null
    middleName?: string | null
    email?: string | null
    companyName?: string | null
    phoneNumber?: string | null
    faxNumber?: string | null
    title?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: State | null
    zip?: string | null
  } | null
  file?: FileFragment | null
  files?: (FileFragment | null)[] | null
  violationTypes?: {
    id?: number
    name?: string
  }[]
  recordCollections?:
    | ({
        id?: number
        name?: string | null
      } | null)[]
    | null
  foreignValue?: ForeignValue | null
  userHasAccessToForeignValue?: boolean | null
}

type Props = {
  input: FieldDisplayInput
}

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

// the backend has related Field display code for documents in stringifyFormFieldInput
export const FieldDisplay = (props: Props) => {
  const {
    input: {
      type,
      checked,
      num,
      str,
      strs,
      record,
      inspectionResult,
      contact,
      file,
      files,
      recordCollections,
      violationTypes,
      foreignValue,
      userHasAccessToForeignValue,
    },
  } = props
  switch (type) {
    case 'ShortText': {
      const strings = (strs || []).length ? strs : [str]
      return (
        <Flex vertical gap="4px">
          {strings.map((string, index) => (
            <div key={index}>{string}</div>
          ))}
        </Flex>
      )
    }
    case 'LongText':
    case 'Address':
    case 'Radio': {
      return str || '-'
    }
    case 'Date': {
      return str ? formatDate(str, DateFormats.MonthNameDateNoTime) : '-'
    }
    case 'Dropdown': {
      return strs?.join(', ') || '-'
    }
    case 'Currency': {
      return num ? `$${num}` : '-'
    }
    case 'Number': {
      return num ? `${num}` : '-'
    }
    case 'Signature': {
      const url = file?.url?.signedUrl
      if (!url) {
        return '-'
      }
      return <RenderedSignature url={file?.url?.signedUrl ?? ''} type="modal" />
    }
    case 'InspectionResult': {
      return `${inspectionResult?.id}`
    }
    case 'Checkbox': {
      return checked ? 'Yes' : 'No'
    }
    case 'Record': {
      return record?.id ? <RecordIdLinkCell id={record.id} /> : '-'
    }
    case 'Contact': {
      if (!contact) {
        return '-'
      }
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        companyName,
        faxNumber,
        title,
      } = contact
      const makeIcon = (icon: IconDefinition) => (
        <FontAwesomeIcon icon={icon} style={{ marginRight: '8px' }} />
      )

      const renderLine = (icon: IconDefinition, val?: string) =>
        val ? (
          <div>
            {makeIcon(icon)}
            {val}
          </div>
        ) : (
          ''
        )

      const name = [firstName, lastName]
        .filter((a) => !!a)
        .map((a) => a?.trim())
        .join(' ')

      const formattedAddress = formatFieldContactAddress(contact)
      return (
        <>
          {renderLine(faUser, name)}
          {renderLine(faBuilding, companyName ?? '')}
          {renderLine(faIdCard, title ?? '')}
          {phoneNumber && renderLine(faPhone, formatPhoneNumber(phoneNumber))}
          {faxNumber && renderLine(faFax, formatPhoneNumber(faxNumber))}
          {email && renderLine(faEnvelope, email)}
          {formattedAddress && renderLine(faLocationPin, formattedAddress)}
        </>
      )
    }
    case 'File': {
      if (!file) {
        return '-'
      }
      return (
        <TableLinkNewPage href={file?.url?.signedUrl ?? ''}>
          {file?.name}
        </TableLinkNewPage>
      )
    }
    case 'Files': {
      if (!files?.length) {
        return '-'
      }
      return (
        <Vertical>
          {files.map((f) => (
            <TableLinkNewPage
              key={`file-${f?.id}`}
              href={f?.url?.signedUrl ?? ''}
            >
              {f?.name}
            </TableLinkNewPage>
          ))}
        </Vertical>
      )
    }
    case 'ViolationType': {
      if (!violationTypes?.length) {
        return ''
      }
      return (
        <Vertical>
          {violationTypes.map((vt) => (
            <div key={`vt-${vt.id}`}>{vt.name}</div>
          ))}
        </Vertical>
      )
    }
    case 'RecordCollection': {
      if (!recordCollections?.length) {
        return '-'
      }
      return (
        <Vertical>
          {recordCollections.map((rc) => (
            <div key={`rc-${rc?.id}`}>{rc?.name}</div>
          ))}
        </Vertical>
      )
    }
    case 'ForeignValue': {
      return foreignValue ? (
        <ForeignValueDisplay
          id={foreignValue.id}
          type={foreignValue.type}
          userHasAccessToForeignValue={!!userHasAccessToForeignValue}
        />
      ) : (
        '-'
      )
    }
  }
}
