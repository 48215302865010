import { useEffect } from 'react'

import CobrowseIO from 'cobrowse-sdk-js'

import { EnvVariables, Environments } from '../constants'

export const useSetUpCobrowse = () => {
  useEffect(() => {
    if (
      EnvVariables.CoBrowseLicenseKey &&
      EnvVariables.Environment === Environments.Production
    ) {
      CobrowseIO.license = EnvVariables.CoBrowseLicenseKey
      CobrowseIO.start()
    }
  }, [])
}
