import { makeAutoObservable } from 'mobx'
import {
  ViewColumnFragment,
  ViewColumnTemplateFragment,
  ViewColumnType,
} from 'types/graphql'

import { TableViewColumn } from 'src/models/TableViews/TableViewColumn'

export class ColumnManager {
  public columns: TableViewColumn[] = []

  private _columnTemplates: ViewColumnTemplateFragment[] = []
  constructor(args: {
    columns: ViewColumnFragment[]
    columnTemplates: ViewColumnTemplateFragment[]
  }) {
    this._columnTemplates = args.columnTemplates
    const columns: TableViewColumn[] = []
    args.columns.forEach((c, displayIndex) => {
      const columnTemplate = args.columnTemplates.find(
        (ct) => ct.columnType === c.columnType
      )
      if (!columnTemplate) {
        return
      }
      columns.push(
        new TableViewColumn({
          columnTemplate,
          columnType: c.columnType,
          displayIndex,
        })
      )
    })
    this.columns = columns
    makeAutoObservable(this)
  }

  public get columnsByColumnType(): Map<ViewColumnType, TableViewColumn> {
    return new Map(this.columns.map((c) => [c.columnType, c]))
  }

  public toggleColumnTypeVisibility(columnType: ViewColumnType) {
    const index = this.columns.findIndex((c) => c.columnType === columnType)
    if (index < 0) {
      const columnTemplate = this._columnTemplates.find(
        (ct) => ct.columnType === columnType
      )
      if (!columnTemplate) {
        return // Should not happen
      }
      this.columns.push(
        new TableViewColumn({
          columnTemplate,
          columnType,
          displayIndex: this.columns.length,
        })
      )
    }
    // don't allow hiding last column
    else if (this.columns.length > 1) {
      this.columns.splice(index, 1)
    }
  }
}
