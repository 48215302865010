import { useEffect } from 'react'

import { SelectProps as AntdSelectProps, Form } from 'antd'

import { CellLoadingProps, CellSuccessProps } from '@redwoodjs/web'

import { ViolationTypeSelectCellQuery } from '../../../../../types/graphql'
import { mapStrsToInts } from '../../../../utils'
import Select, { BasicOptionType, SelectProps } from '../../Select'
import { makeArrayFieldName, makeHiddenFieldName } from '../../util'

export const QUERY = gql`
  query ViolationTypeSelectCellQuery($slug: String!) {
    violationTypes: violationTypesBySlug(slug: $slug) {
      id
      organizationId
      name
      description
    }
  }
`

interface LoadingProps
  extends CellLoadingProps<ViolationTypeSelectCellQuery>,
    Omit<SelectProps<any>, 'options'> {}

export const Loading = (props: LoadingProps) => {
  return <Select {...props} options={[]} />
}

export interface ViolationTypeSelectFieldValues {
  selectedIds: string | string[]
  typeNames: string[]
}

interface Props
  extends CellSuccessProps<ViolationTypeSelectCellQuery>,
    Omit<SelectProps<any>, 'options'> {
  mode?: AntdSelectProps['mode']
}

export const Success = (props: Props) => {
  const { violationTypes, mode, ...rest } = props

  const options: BasicOptionType<number>[] = violationTypes.map((st) => ({
    label: st.name,
    value: st.id,
    ...(st.description ? { sublabel: st.description } : {}),
  }))

  const form = Form.useFormInstance()
  Form.useWatch([rest.fieldName], form)
  const value: ViolationTypeSelectFieldValues = form.getFieldValue(
    rest.fieldName
  )
  const selectedIds = Array.isArray(value?.selectedIds)
    ? value?.selectedIds || []
    : value?.selectedIds
    ? [value.selectedIds]
    : []
  const stringifiedIds = JSON.stringify(selectedIds)

  const typeNamesFieldName = makeHiddenFieldName(
    rest.fieldName,
    null,
    'typeNames'
  )

  useEffect(() => {
    const newTypeNames = options
      .filter((o) => mapStrsToInts(selectedIds).includes(o.value))
      .map((o) => o.label)
    form.setFieldValue(typeNamesFieldName, newTypeNames)
  }, [stringifiedIds])

  return (
    <>
      <Select
        {...rest}
        fieldName={makeArrayFieldName(rest.fieldName, 'selectedIds')}
        options={options}
        mode={mode}
        optionLabelProp="label"
      />
      <Form.Item name={typeNamesFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
    </>
  )
}
