import { ReactNode, useCallback, useMemo, useState } from 'react'

import { Flex } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  LocationSnapshotInDepthFragment,
  ViewDataRowFragment,
  ViewType,
} from 'types/graphql'

import { useTableViewManager } from '../fetch/views/use-table-view-manager'
import { useTable, UseTableProps } from '../hooks/use-table'
import { useTableViewsAntdConfig } from '../hooks/use-table-views-antd-config'
import { DataMap } from '../pages/DataMap/DataMap'
import { RefetchFnType } from '../types'
import { filterNil } from '../utils'

import EmptyState from './EmptyState'
import { LoadingSpin } from './shared/StyledComponents'
import { TableV2 } from './TableV2'
import TableViewEditor from './TableViews/TableViewEditor'

type Props<T extends { id: number }> = {
  recordTypeId?: number
  viewType: ViewType
  map?: {
    getLocationSnapshot: (
      datum: T
    ) => LocationSnapshotInDepthFragment | null | undefined
    renderPopup: (datum: T) => ReactNode
  }
  getAdditionalColumns?: (
    refetch: RefetchFnType
  ) => ColumnsType<ViewDataRowFragment>
  getDataFromRow: (row: ViewDataRowFragment) => T | null | undefined
  emptyStateMessage: string
  renderRowSelection?: (
    rows: ViewDataRowFragment[],
    refetch: RefetchFnType
  ) => ReactNode
  getCheckboxProps?: UseTableProps<ViewDataRowFragment>['rowSelection']['getCheckboxProps']
}

const PAGE_SIZE = 50

function TableViewBuilder<T extends { id: number }>({
  recordTypeId,
  viewType,
  getAdditionalColumns,
  map,
  getDataFromRow,
  emptyStateMessage,
  renderRowSelection,
  getCheckboxProps,
}: Props<T>) {
  const {
    columnTemplates,
    generateDefaultViews,
    hasViewsDataLoaded,
    isFetchingViewData,
    isLoadingViewData,
    isLoadingViews,
    onSaveViewChanges,
    refetchDatabaseCountForView,
    refetchViewData,
    rows,
    searchQuery,
    setPageOffset,
    setSearchQuery,
    tableViewManager,
  } = useTableViewManager({ recordTypeId, viewType })
  const {
    columns: baseColumns,
    onSortChanged,
    sort,
    sortInputs,
  } = useTableViewsAntdConfig({
    columnTemplates,
    tableViewManager,
  })
  const tableProps = useTable({
    data: rows,
    isLoading: isLoadingViews || isFetchingViewData,
    bypassDataStateManagement: true,
    initialPageSize: PAGE_SIZE,
    onChangePage: (offset: number) => {
      setPageOffset((offset - 1) * PAGE_SIZE)
    },
    count: tableViewManager.selectedView?.totalCount,
    showPageSizeChanger: false,
  })

  const data = useMemo(
    () => filterNil(rows.map(getDataFromRow) ?? []),
    [getDataFromRow, rows]
  )

  const handleRowActionCompleted = useCallback(async () => {
    await runInAction(async () => {
      const selectedView = tableViewManager.selectedView
      if (!selectedView) {
        return
      }
      return await Promise.all([
        refetchDatabaseCountForView(selectedView),
        refetchViewData(),
      ])
    })
  }, [
    refetchDatabaseCountForView,
    refetchViewData,
    tableViewManager.selectedView,
  ])

  const columns: ColumnsType<ViewDataRowFragment> = useMemo(() => {
    const additionalColumns =
      getAdditionalColumns?.(handleRowActionCompleted) || []
    return [...baseColumns, ...additionalColumns]
  }, [baseColumns, handleRowActionCompleted, getAdditionalColumns])

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const rowSelection: UseTableProps<ViewDataRowFragment>['rowSelection'] =
    useMemo(() => {
      return {
        type: 'checkbox',
        hideSelectAll: false,
        getCheckboxProps,
        selectedRowKeys,
        onChange: setSelectedRowKeys,
      }
    }, [getCheckboxProps, selectedRowKeys])
  const selectedRows = useMemo(() => {
    return rows.filter((row) => selectedRowKeys.includes(row.id))
  }, [rows, selectedRowKeys])

  return (
    <>
      <TableViewEditor
        generateDefaultViews={generateDefaultViews}
        isLoadingViewData={isLoadingViewData}
        isLoadingViews={isLoadingViews}
        onSaveViewChanges={onSaveViewChanges}
        onSelectedViewChange={refetchDatabaseCountForView}
        refetchViewData={refetchViewData}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        tableViewManager={tableViewManager}
      />
      {map && (
        <DataMap<T>
          data={data}
          isLoading={isLoadingViews || isLoadingViewData}
          hoveredId={tableProps.hoveredRow?.id}
          getLocationSnapshot={map.getLocationSnapshot}
          renderPopup={map.renderPopup}
        />
      )}
      <Flex vertical gap="12px">
        {!!selectedRows?.length && (
          <Flex justify="flex-end">
            {renderRowSelection?.(selectedRows, handleRowActionCompleted)}
          </Flex>
        )}
        <TableV2
          columns={columns}
          {...tableProps}
          paginate
          sortInputs={sortInputs}
          order={sort}
          setOrder={onSortChanged}
          renderRowSelection={(rowSelections: ViewDataRowFragment[]) =>
            renderRowSelection?.(rowSelections, handleRowActionCompleted)
          }
          {...(getCheckboxProps ? { rowSelection } : {})}
          emptyText={
            isLoadingViews || isLoadingViewData || !hasViewsDataLoaded ? (
              <LoadingSpin />
            ) : (
              <EmptyState hideBorder>
                <EmptyState.Image />
                <EmptyState.Message>{emptyStateMessage}</EmptyState.Message>
              </EmptyState>
            )
          }
          showRightVerticalLine
        />
      </Flex>
    </>
  )
}
export default observer(TableViewBuilder)
