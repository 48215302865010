import { Fragment, ReactNode } from 'react'

import { Form, FormListFieldData, FormListOperation } from 'antd'
import { ColumnsType } from 'antd/es/table'
import styled from 'styled-components'

import { useTable } from '../../../hooks/use-table'
import { TableV2 } from '../../TableV2'
import { DeleteButton } from '../../TableV2/DeleteButton'
import { TableHeaderProps } from '../../TableV2/types'
import { Label } from '../Label'
import { FormFieldWrapperProps } from '../Wrapper'

interface RenderInputParamsBase {
  fieldName: FormFieldWrapperProps['fieldName']
  index?: number
  noMargin?: boolean
  label?: FormFieldWrapperProps['label']
  allowClear?: boolean
}

interface Props extends FormFieldWrapperProps {
  allowMultiple?: boolean
  renderInput: (params: RenderInputParamsBase) => ReactNode
  recordTitle: string
  getAdditionalColumns?: (
    fields: FormListFieldData[],
    operations: FormListOperation
  ) => ColumnsType<MultipleFormInputRow>
  initialValue?: unknown
}

export interface MultipleFormInputRow {
  id: string
  field: FormListFieldData
}

export const SingleOrMultipleInput = (props: Props) => {
  const { allowMultiple, renderInput, initialValue } = props

  if (allowMultiple) {
    return (
      <Form.List
        name={props.fieldName}
        initialValue={[initialValue === undefined ? {} : '']}
      >
        {(fields, operations) => (
          <Table {...props} fields={fields} operations={operations} />
        )}
      </Form.List>
    )
  }

  return renderInput({ ...props, allowClear: true })
}

interface TableProps extends Props {
  fields: FormListFieldData[]
  operations: FormListOperation
}

const Container = styled.div`
  width: 100%;
`

const Table = (props: TableProps) => {
  const {
    fields,
    fieldName,
    label,
    tooltip,
    operations,
    renderInput,
    recordTitle,
    getAdditionalColumns = () => [],
  } = props
  const rows = fields.map((field) => ({ field, id: `${field.key}` }))

  const tableProps = useTable<MultipleFormInputRow>({ data: rows })
  const columns: ColumnsType<MultipleFormInputRow> = [
    {
      title: recordTitle,
      width: '100%',
      render: (row: MultipleFormInputRow) => (
        <Fragment key={row.field.key}>
          {renderInput({
            ...props,
            fieldName,
            index: row.field.name,
            noMargin: true,
            label: '',
            allowClear: false,
          })}
        </Fragment>
      ),
    },
    ...(getAdditionalColumns?.(fields, operations) || []),
    {
      title: '',
      render: (row: MultipleFormInputRow) => (
        <DeleteButton
          onClick={() => operations.remove(row.field.name)}
          disabled={rows.length <= 1}
        />
      ),
    },
  ]

  const header: TableHeaderProps = {
    name: <Label label={label} tooltip={tooltip} />,
    buttonProps: [
      {
        text: 'Add',
        onClick: () => operations.add(),
        type: 'default',
      },
    ],
  }

  return (
    <Container>
      <TableV2<MultipleFormInputRow>
        {...tableProps}
        columns={columns}
        showNumber
        header={header}
        showRightVerticalLine
        emptyText=" "
      />
    </Container>
  )
}
