import { ReactNode, useEffect, useRef, useState } from 'react'

import { TableProps as AntdTableProps, TablePaginationConfig } from 'antd'
import { GetRowKey } from 'antd/es/table/interface'

import { MakeOrderInput } from '../types'
import { deepEqual, reorderArray } from '../utils'

import useDisclosure, { UseDisclosureReturn } from './use-disclosure'

export interface TableRecordGenericType {
  id?: number | string | undefined
  key?: string
  header?: string
  headerField?: ReactNode
}

export const TableHeaderField = 'header'

export type RowSelectionType<RecordType extends TableRecordGenericType> = Pick<
  AntdTableProps<RecordType>['rowSelection'],
  'selectedRowKeys' | 'onChange' | 'hideSelectAll' | 'type' | 'getCheckboxProps'
>

export type SearchTableResponseType<RecordType extends TableRecordGenericType> =
  {
    count: number
    data: readonly RecordType[]
  }

export type ShouldReorderFnType<RecordType extends TableRecordGenericType> = (
  data: RecordType[]
) => boolean

export interface SearchFnParams<FiltersType = undefined> {
  query?: string
  page?: number
  filters?: FiltersType
  order?: MakeOrderInput<FiltersType>
  pageSize?: number
}

export type SearchFnType<
  RecordType extends TableRecordGenericType,
  FiltersType = undefined
> = (
  params: SearchFnParams<FiltersType>
) => Promise<SearchTableResponseType<RecordType> | undefined>

export interface SearchParams<
  RecordType extends TableRecordGenericType,
  FiltersType = undefined
> {
  search: SearchFnType<RecordType, FiltersType>
  placeholder?: string
  generateCSV?: (params: SearchFnParams<FiltersType>) => Promise<string>
}

export enum TableStatus {
  Default = 'Default',
  Searching = 'Searching',
}

export interface UseTableParams<
  TRecord extends TableRecordGenericType,
  TFilters
> {
  count?: number
  data: readonly TRecord[]
  isLoading?: boolean
  bypassDataStateManagement?: boolean /* using this flag to skip the internal state handling that updates currentData on effect */
  initialPageSize?: number
  showPageSizeChanger?: boolean
  onChangePage?: (
    newPage: number,
    pageSize?: number
  ) => Promise<readonly TRecord[]> | void
  searchParams?: SearchParams<TRecord, TFilters>
  onReorder?: (newData: TRecord[]) => Promise<unknown>
  shouldReorder?: ShouldReorderFnType<TRecord>
  enableRowSelection?: boolean
  rowSelectionAllowSelectAll?: boolean
  rowKey?: string | GetRowKey<TRecord>
  headerAccordionInitialOpen?: boolean
}

export interface UseTableProps<
  RecordType extends TableRecordGenericType,
  FiltersType = any
> {
  currentPage: TablePaginationConfig['current']
  count: TablePaginationConfig['total']
  onChangePage: (newPage: number, pageSize?: number) => Promise<void>
  currentData: readonly RecordType[]
  updateData: (id: number | string, updatedData: RecordType) => void
  removeData: (id: number | string) => void
  reorderData: (originalIndex: number, newIndex: number) => Promise<void>
  searchParams?: SearchParams<RecordType, FiltersType>
  search?: SearchFnType<RecordType, FiltersType>
  tableStatus: TableStatus
  query: string
  isLoading: boolean
  isReordering: boolean
  rowSelection: RowSelectionType<RecordType>
  rowKey?: string | GetRowKey<RecordType>
  stateKey: string // use this to distinguish between search/regular, page etc
  hoveredRow: RecordType | undefined
  headerAccordionState: UseDisclosureReturn
  setHoveredRow: (record: RecordType | undefined) => void
  hoveredRowIndex: number | undefined
  setHoveredRowIndex: (index: number) => void
  order: MakeOrderInput<FiltersType> | undefined
  setOrder: (newOrder: MakeOrderInput<FiltersType> | undefined) => void
  pagination: TablePaginationConfig
}

interface State<RecordType> {
  page: number
  data: readonly RecordType[]
}

interface SearchState<RecordType, FiltersType> {
  page: number
  data: readonly RecordType[]
  count: number
  query: string
  filters: FiltersType | undefined
  order: MakeOrderInput<FiltersType> | undefined
}

export function useTable<
  RecordType extends TableRecordGenericType,
  FiltersType = any
>(args: UseTableParams<RecordType, FiltersType>): UseTableProps<RecordType> {
  const {
    onChangePage,
    count,
    data: initialData,
    isLoading,
    bypassDataStateManagement,
    searchParams,
    onReorder,
    shouldReorder,
    enableRowSelection,
    rowSelectionAllowSelectAll,
    initialPageSize = 10,
    showPageSizeChanger = true,
    headerAccordionInitialOpen,
    rowKey,
  } = args
  const headerAccordionState = useDisclosure({
    initialOpen: headerAccordionInitialOpen || false,
  })
  const [state, setState] = useState<State<RecordType>>({
    page: 1,
    data: initialData,
  })
  const [pageSize, setPageSize] = useState<number>(initialPageSize)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isReordering, setIsReordering] = useState<boolean>(false)
  const [hoveredRow, setHoveredRow] = useState<RecordType | undefined>(
    undefined
  )
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | undefined>(
    undefined
  )
  const [order, setOrder] = useState<MakeOrderInput<FiltersType> | undefined>(
    undefined
  )
  const [searchState, setSearchState] = useState<
    SearchState<RecordType, FiltersType>
  >({
    page: 1,
    data: [],
    count: 0,
    query: '',
    filters: undefined,
    order,
  })
  const [tableStatus, setTableStatus] = useState<TableStatus>(
    TableStatus.Default
  )

  const stringifiedInitialData = JSON.stringify(
    initialData.map((d) => {
      const { headerField: _, ...rest } = d
      return rest
    })
  )

  const isInitialRender = useRef(true)

  const fetch = async () => {
    if (!searchParams?.search) {
      return
    }
    const res = await searchParams.search({
      query: searchState.query,
      page: 1,
      ...(searchState.filters ? { filters: searchState.filters } : {}),
      order,
      pageSize,
    })
    if (res) {
      setSearchState({
        page: 1,
        data: res.data,
        count: res.count,
        query: searchState.query,
        filters: searchState.filters,
        order,
      })
      setTableStatus(TableStatus.Searching)
    }
  }

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }
    fetch()
  }, [pageSize])

  useEffect(() => {
    if (
      order?.fieldName !== searchState?.order?.fieldName ||
      order?.mode !== searchState?.order?.mode
    ) {
      fetch()
    }
  }, [order])

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      data: initialData,
    }))
  }, [stringifiedInitialData])

  const updateData = (id: number | string, updatedData: RecordType) => {
    const newDataArray = [...state.data]
    const prevDataIndex = newDataArray.findIndex((d) => d.id === id)
    if (!prevDataIndex) {
      return
    }
    newDataArray[prevDataIndex] = updatedData
    setState((prevState) => ({
      ...prevState,
      data: newDataArray,
    }))
  }
  const removeData = (id: number | string) => {
    const newDataArray = [...state.data]
    const prevDataIndex = newDataArray.findIndex((d) => d.id === id)
    newDataArray[prevDataIndex] = undefined
    const newDataArrayFiltered = newDataArray.filter((a) => !!a)
    setState((prevState) => ({
      ...prevState,
      data: newDataArrayFiltered,
    }))
  }

  const reorderData = async (originalIndex: number, newIndex: number) => {
    const reordered = reorderArray<RecordType>(
      state.data,
      originalIndex,
      newIndex
    )
    if (shouldReorder && !shouldReorder?.(reordered)) {
      return
    }
    setIsReordering(true)
    setState((prevState) => ({ ...prevState, data: reordered }))
    await onReorder?.(reordered)
    setIsReordering(false)
  }

  const search = async (params: SearchFnParams<FiltersType>) => {
    const { page = 1, filters, order: newOrder } = params
    const query = params.query || ''
    const hasFilters = !!Object.keys(filters || {}).length
    if (isInitialRender.current) {
      return
    }
    if (
      !searchParams ||
      (!params.query && !hasFilters && !newOrder?.fieldName && !!newOrder?.mode)
    ) {
      return
    }
    if (
      (params.query || '') === searchState.query &&
      (params.page || 1) === searchState.page &&
      deepEqual(params.filters || {}, searchState.filters || {})
    ) {
      return
    }
    const res = await searchParams.search({
      query,
      page,
      ...(filters ? { filters } : {}),
      order: newOrder,
      pageSize,
    })
    if (res) {
      setSearchState({
        page,
        data: res.data,
        count: res.count,
        query,
        filters: params.filters,
        order: newOrder,
      })
      setTableStatus(TableStatus.Searching)
    }
    return res
  }

  const getPropsByStatus = () => {
    if (tableStatus === TableStatus.Default) {
      const computedCount = count
        ? count
        : initialData?.filter((d) => !('header' in d))?.length
      return {
        currentPage: state.page,
        count: computedCount,
        currentData: state.data,
        onChangePage: async (newPage: number, newPageSize: number) => {
          if (!onChangePage) {
            return
          }
          const newData = await onChangePage(newPage, newPageSize)
          setState((prevState) => ({
            ...prevState,
            page: newPage,
            ...(newData ? { data: newData } : {}),
          }))
        },
      }
    }
    return {
      currentPage: searchState.page,
      count: searchState.count,
      currentData: searchState.data,
      onChangePage: async (newPage: number) => {
        await search({
          page: newPage,
          query: searchState.query,
          filters: searchState.filters,
          order: searchState.order,
          pageSize,
        })
      },
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection: RowSelectionType<RecordType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    hideSelectAll: !rowSelectionAllowSelectAll,
  }

  const propsByStatus = getPropsByStatus()

  const pagination: TablePaginationConfig = {
    showSizeChanger: showPageSizeChanger,
    defaultCurrent: propsByStatus.currentPage,
    current: propsByStatus.currentPage,
    total: propsByStatus.count,
    onChange: propsByStatus.onChangePage,
    onShowSizeChange: (_: number, size: number) => {
      setPageSize(size)
    },
    pageSize,
  }
  return {
    ...propsByStatus,
    currentData: bypassDataStateManagement
      ? initialData
      : propsByStatus.currentData,
    updateData,
    removeData,
    ...(searchParams ? { search } : {}),
    tableStatus,
    searchParams,
    reorderData,
    query: searchState.query,
    isLoading,
    isReordering,
    rowSelection: enableRowSelection ? rowSelection : undefined,
    rowKey,
    stateKey: `${tableStatus}-${propsByStatus.currentPage}-${searchState.query}`,
    hoveredRow,
    setHoveredRow,
    hoveredRowIndex,
    setHoveredRowIndex,
    order,
    setOrder,
    pagination,
    headerAccordionState,
  }
}
