export interface Campaign {
  id: number
  name: string
  count: string
  viewCount: string
  sentOn: string
  sentBy: string
  sentTo: string
  content: string
  signature: string
  buttonText: string
  responses: Response[]
}

export interface Response {
  id: number
  name: string
  message: string
  sentOn: string
}

export const Campaigns: Campaign[] = [
  {
    id: 1,
    name: 'Road Closure',
    count: '1,304',
    viewCount: '876',
    sentOn: 'Wednesday, June 12 at 4:00pm',
    sentBy: 'Troy LeCaire',
    sentTo: 'Business Owners with Construction Permits',
    content: `Dear Business Owner,\nPlease be advised that Main Street will be closed for construction on June 15th.\nPlan your deliveries and operations accordingly.`,
    signature: 'Best regards,\nTroy LeCaire\nCity Planning Department',
    buttonText: 'View Details',
    responses: [
      {
        id: 1,
        name: 'Alice Johnson',
        message: "Thanks for the heads up! We'll plan accordingly.",
        sentOn: 'Wednesday, June 12 at 4:00pm',
      },
      {
        id: 2,
        name: 'Bob Smith',
        message: 'Will there be detour signs posted?',
        sentOn: 'Wednesday, June 12 at 4:00pm',
      },
      {
        id: 3,
        name: 'Carol Davis',
        message: 'Can we get more details on the closure?',
        sentOn: 'Wednesday, June 12 at 4:00pm',
      },
    ],
  },
  {
    id: 2,
    name: 'Weather Alert',
    count: '25,000',
    viewCount: '18,456',
    sentOn: 'Tuesday, June 11 at 9:00am',
    sentBy: 'Jane Smith',
    sentTo: 'All Residents with Event Licenses',
    content: `Dear Resident,\nA severe weather alert has been issued for our area.\nPlease take necessary precautions and stay safe.`,
    signature: 'Stay safe,\nJane Smith\nCity Weather Services',
    buttonText: 'More Info',
    responses: [
      {
        id: 1,
        name: 'David Lee',
        message: 'Thanks for the alert, we will stay prepared.',
        sentOn: 'Tuesday, June 11 at 9:00am',
      },
      {
        id: 2,
        name: 'Emma White',
        message: 'Is there any specific area more affected?',
        sentOn: 'Tuesday, June 11 at 9:00am',
      },
      {
        id: 3,
        name: 'Frank Harris',
        message: 'Appreciate the timely update.',
        sentOn: 'Tuesday, June 11 at 9:00am',
      },
    ],
  },
  {
    id: 3,
    name: 'Community Event',
    count: '3,500',
    viewCount: '2,783',
    sentOn: 'Monday, June 10 at 2:00pm',
    sentBy: 'Sam Green',
    sentTo: 'Vendors with Street Vendor Permits',
    content: `Dear Vendor,\nJoin us for the annual summer festival on June 20th.\nMake sure your permits are up to date.`,
    signature: 'Cheers,\nSam Green\nCity Events Coordinator',
    buttonText: 'RSVP Now',
    responses: [
      {
        id: 1,
        name: 'Grace Kim',
        message: 'Looking forward to the event!',
        sentOn: 'Monday, June 10 at 2:00pm',
      },
      {
        id: 2,
        name: 'Henry Wilson',
        message: 'Will there be a setup area for vendors?',
        sentOn: 'Monday, June 10 at 2:00pm',
      },
      {
        id: 3,
        name: 'Isabella Martinez',
        message: 'Thank you for the reminder.',
        sentOn: 'Monday, June 10 at 2:00pm',
      },
    ],
  },
  {
    id: 4,
    name: 'Public Health Notice',
    count: '20,000',
    viewCount: '15,120',
    sentOn: 'Friday, June 7 at 5:00pm',
    sentBy: 'Dr. Lisa Brown',
    sentTo: 'All Applicants of Health Permits in 2024',
    content: `Dear Permit Holder,\nA new public health guideline has been issued.\nPlease review the updated regulations.`,
    signature: 'Sincerely,\nDr. Lisa Brown\nCity Health Department',
    buttonText: 'Read Guidelines',
    responses: [
      {
        id: 1,
        name: 'Jack Taylor',
        message: 'Got it, will review the guidelines.',
        sentOn: 'Friday, June 7 at 5:00pm',
      },
      {
        id: 2,
        name: 'Karen Anderson',
        message: 'Are there any major changes?',
        sentOn: 'Friday, June 7 at 5:00pm',
      },
      {
        id: 3,
        name: 'Liam Thompson',
        message: 'Thanks for keeping us informed.',
        sentOn: 'Friday, June 7 at 5:00pm',
      },
    ],
  },
  {
    id: 5,
    name: 'Utility Maintenance',
    count: '4,200',
    viewCount: '3,056',
    sentOn: 'Thursday, June 6 at 8:00am',
    sentBy: 'John Doe',
    sentTo: 'Homeowners with Electrical Permits',
    content: `Dear Homeowner,\nScheduled maintenance will occur on June 12th.\nExpect temporary outages in your area.`,
    signature: 'Regards,\nJohn Doe\nCity Utility Services',
    buttonText: 'Maintenance Schedule',
    responses: [
      {
        id: 1,
        name: 'Mia Roberts',
        message: 'Thanks for letting us know.',
        sentOn: 'Thursday, June 6 at 8:00am',
      },
      {
        id: 2,
        name: 'Noah Evans',
        message: 'How long will the outage last?',
        sentOn: 'Thursday, June 6 at 8:00am',
      },
      {
        id: 3,
        name: 'Olivia Scott',
        message: 'Will this affect all homes?',
        sentOn: 'Thursday, June 6 at 8:00am',
      },
    ],
  },
  {
    id: 6,
    name: 'School Closure',
    count: '2,100',
    viewCount: '1,674',
    sentOn: 'Wednesday, June 5 at 7:00am',
    sentBy: 'Amy White',
    sentTo: 'Childcare Facility License Holders',
    content: `Dear License Holder,\nAll schools will be closed on June 10th due to unforeseen circumstances.\nPlease inform the parents accordingly.`,
    signature: 'Best,\nAmy White\nCity Education Department',
    buttonText: 'Closure Details',
    responses: [
      {
        id: 1,
        name: 'Paul Baker',
        message: 'Thanks for the notice.',
        sentOn: 'Wednesday, June 5 at 7:00am',
      },
      {
        id: 2,
        name: 'Quinn Murphy',
        message: 'Any idea when schools will reopen?',
        sentOn: 'Wednesday, June 5 at 7:00am',
      },
      {
        id: 3,
        name: 'Rachel Bell',
        message: 'We will notify the parents right away.',
        sentOn: 'Wednesday, June 5 at 7:00am',
      },
    ],
  },
  {
    id: 7,
    name: 'Emergency Services Update',
    count: '1,500',
    viewCount: '1,230',
    sentOn: 'Tuesday, June 4 at 10:00am',
    sentBy: 'Chief Mike Davis',
    sentTo: 'Emergency Responders with Certified Licenses',
    content: `Dear Emergency Responder,\nNew protocols have been introduced for emergency services.\nPlease review them before your next shift.`,
    signature: 'Stay vigilant,\nChief Mike Davis\nCity Emergency Services',
    buttonText: 'View Protocols',
    responses: [
      {
        id: 1,
        name: 'Steven Clark',
        message: 'Will do, Chief. Thanks!',
        sentOn: 'Tuesday, June 4 at 10:00am',
      },
      {
        id: 2,
        name: 'Tina Adams',
        message: 'Are there any training sessions?',
        sentOn: 'Tuesday, June 4 at 10:00am',
      },
      {
        id: 3,
        name: 'Ursula Nguyen',
        message: 'Noted, will review the protocols.',
        sentOn: 'Tuesday, June 4 at 10:00am',
      },
    ],
  },
  {
    id: 8,
    name: 'City Council Meeting',
    count: '500',
    viewCount: '432',
    sentOn: 'Monday, June 3 at 3:00pm',
    sentBy: 'Mayor Jill Roberts',
    sentTo: 'City Officials with Operational Permits',
    content: `Dear Official,\nA city council meeting is scheduled for June 5th.\nYour attendance is requested.`,
    signature: 'Sincerely,\nMayor Jill Roberts\nCity Council',
    buttonText: 'Meeting Agenda',
    responses: [
      {
        id: 1,
        name: 'Victor Lee',
        message: "I'll be there.",
        sentOn: 'Monday, June 3 at 3:00pm',
      },
      {
        id: 2,
        name: 'Wendy Hall',
        message: 'Can we get the agenda in advance?',
        sentOn: 'Monday, June 3 at 3:00pm',
      },
      {
        id: 3,
        name: 'Xander Price',
        message: 'Thanks for the reminder.',
        sentOn: 'Monday, June 3 at 3:00pm',
      },
    ],
  },
  {
    id: 9,
    name: 'Parks and Recreation Programs',
    count: '8,000',
    viewCount: '6,543',
    sentOn: 'Sunday, June 2 at 1:00pm',
    sentBy: 'Mark Wilson',
    sentTo: 'Applicants of Park Event Permits',
    content: `Dear Applicant,\nNew recreational programs are available starting June 15th.\nCheck out the schedule and join us.`,
    signature: 'Enjoy the parks,\nMark Wilson\nCity Parks and Recreation',
    buttonText: 'Program Schedule',
    responses: [
      {
        id: 1,
        name: 'Yvonne King',
        message: 'Excited to join the new programs!',
        sentOn: 'Sunday, June 2 at 1:00pm',
      },
      {
        id: 2,
        name: 'Zachary Brown',
        message: 'Is registration required?',
        sentOn: 'Sunday, June 2 at 1:00pm',
      },
      {
        id: 3,
        name: 'Aaron Mitchell',
        message: 'Thanks for the update.',
        sentOn: 'Sunday, June 2 at 1:00pm',
      },
    ],
  },
  {
    id: 10,
    name: 'Public Transportation Update',
    count: '15,000',
    viewCount: '11,890',
    sentOn: 'Saturday, June 1 at 6:00am',
    sentBy: 'Transit Authority',
    sentTo: 'Drivers with Commercial Transport Licenses',
    content: `Dear Driver,\nImportant updates have been made to the public transportation system.\nPlease review the changes.`,
    signature: 'Thank you,\nCity Transit Authority',
    buttonText: 'View Updates',
    responses: [
      {
        id: 1,
        name: 'Betty Ross',
        message: 'Will these changes affect all routes?',
        sentOn: 'Saturday, June 1 at 6:00am',
      },
      {
        id: 2,
        name: 'Charles Edwards',
        message: 'Thanks for the update, will review.',
        sentOn: 'Saturday, June 1 at 6:00am',
      },
      {
        id: 3,
        name: 'Daniel Lewis',
        message: 'Noted, thank you for the information.',
        sentOn: 'Saturday, June 1 at 6:00am',
      },
    ],
  },
]
