import { makeAutoObservable } from 'mobx'
import { ViewSortFragment } from 'types/graphql'

export class SortManager {
  public sorts: ViewSortFragment[]
  private _databaseValue: ViewSortFragment[]

  constructor(args: { sorts: ViewSortFragment[] }) {
    this.sorts = args.sorts
    this._databaseValue = args.sorts
    makeAutoObservable(this)
  }

  public get hasUnsavedChanges(): boolean {
    if (this.sorts.length !== this._databaseValue.length) {
      return true
    }

    for (let i = 0; i < this.sorts.length; i++) {
      const clientSort = this.sorts[i]
      const databaseSort = this._databaseValue[i]
      if (
        clientSort.columnType !== databaseSort.columnType ||
        clientSort.mode !== databaseSort.mode
      ) {
        return true
      }
    }
    return false
  }

  public reset() {
    this.sorts = Array.from(this._databaseValue)
  }

  public save() {
    this._databaseValue = Array.from(this.sorts)
  }

  public get databaseProtocol(): ViewSortFragment[] {
    return this._databaseValue
  }
}
