import { useEffect } from 'react'

import {
  faCircleUser,
  faDollar,
  faLineChart,
  faListCheck,
  faMegaphone,
  faSearch,
  faUsers,
  faUsersRectangle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useLocalStorage } from 'usehooks-ts'

import { routes, useLocation } from '@redwoodjs/router'

import Navigation from 'src/components/Sidebar/Navigation/Navigation'
import Text from 'src/components/Typography/Text'
import { KeyboardKey, LocalStorageKey } from 'src/types'
import { getCurrentOS, getIsDesktopOS, OperatingSystem } from 'src/utils/window'

import { Environments, EnvVariables, OrgSlugs } from '../../constants'
import useDisclosure from '../../hooks/use-disclosure'
import {
  useAmIAdmin,
  useAmIReviewer,
  useIsGovWellStaff,
  useMyOrg,
  useMyRecordTypes,
} from '../../layouts/AppStateContextLayout/utils'
import UniversalSearchModal from '../UniversalSearchModal/UniversalSearchModal'

import { RecordTypeNavItems } from './RecordTypeNavItems'
import { CodeViolationsNavItems } from './sections/CodeViolationsNavItems'
import {
  CityFooterDisplay,
  SettingsNavItem,
  UserProfileNavItem,
} from './SidebarFooter'
import { SidebarPrimitive } from './SidebarPrimitive'

const StyledSearchButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
  padding: 4px 12px;
  margin: 0px 8px;
  width: calc(100% - 16px);

  background-color: rgba(255, 255, 255, 0.06);
  border: solid 1px rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  color: ${({ theme }) => theme.colorWhite};
  cursor: pointer;

  &:focus {
    outline: solid 1px ${({ theme }) => theme.colorWhite};
    outline-offset: 2px;
  }
`

interface Props {
  fullWidth?: boolean
}

export const StaffSidebar = (props: Props) => {
  const { fullWidth } = props
  const { pathname } = useLocation()
  const isContactsActive = pathname.includes(routes.contacts())
  const recordTypes = useMyRecordTypes()
  const [isRecordTypeOpenMap, setIsRecordTypeOpenMap] = useLocalStorage<
    Map<number, boolean>
  >(LocalStorageKey.NavigationRecordTypeIsOpenMap, new Map<number, boolean>(), {
    initializeWithValue: true,
    serializer: (v) => JSON.stringify(Array.from(v.entries())),
    deserializer: (v) => {
      const entries = JSON.parse(v)
      if (!Array.isArray(entries)) {
        return new Map<number, boolean>()
      }
      return new Map<number, boolean>(entries)
    },
  })

  const isReviewer = useAmIReviewer()
  const isAdmin = useAmIAdmin()

  const hasCodeEnforcementModule = useMyOrg()?.hasCodeEnforcementModule
  const slug = useMyOrg()?.slug
  const isHutto = slug === OrgSlugs.Hutto

  const hasRecordTypes = !!recordTypes?.length

  const isGovWellStaff = useIsGovWellStaff()

  const showPayments = recordTypes?.some((rt) => rt.allowFees) && !isReviewer

  const isProduction = EnvVariables.Environment === Environments.Production

  const searchModalState = useDisclosure()

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === KeyboardKey.K) {
        searchModalState.open()
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [searchModalState])

  return (
    <SidebarPrimitive fullWidth={fullWidth}>
      <SidebarPrimitive.Header>
        {(hasCodeEnforcementModule || hasRecordTypes) && (
          <>
            <StyledSearchButton onClick={searchModalState.open}>
              <Text color="inherit">
                <FontAwesomeIcon icon={faSearch} />
              </Text>
              <Text color="inherit" style={{ flex: 1, textAlign: 'left' }}>
                Search
              </Text>
              {getIsDesktopOS() && (
                <Text color="rgba(255,255,255,0.4)">{`${
                  getCurrentOS() === OperatingSystem.Windows ? 'Ctrl' : '⌘'
                }+K`}</Text>
              )}
            </StyledSearchButton>
            {searchModalState.isOpen && (
              <UniversalSearchModal modalState={searchModalState} />
            )}
          </>
        )}
      </SidebarPrimitive.Header>
      <SidebarPrimitive.Content>
        {recordTypes?.flatMap((rt) =>
          rt.activeForStaff ? (
            <RecordTypeNavItems
              recordType={rt}
              isOpen={!!isRecordTypeOpenMap.get(rt.id)}
              onToggleIsOpen={() => {
                setIsRecordTypeOpenMap((prev) => {
                  const next = new Map(prev)
                  next.set(rt.id, !next.get(rt.id))
                  return next
                })
              }}
              key={`nav-${rt.id}`}
            />
          ) : (
            []
          )
        )}
        {hasCodeEnforcementModule && !isReviewer && <CodeViolationsNavItems />}
        {hasRecordTypes && (
          <Navigation.Item
            icon={faListCheck}
            title="Tasks"
            href={routes.tasks()}
            isActive={pathname.includes(routes.tasks())}
          />
        )}
        {showPayments && (
          <Navigation.Item
            icon={faDollar}
            title="Payments"
            isActive={
              pathname.includes(routes.payments()) ||
              (!!slug && pathname.includes(routes.cityPay({ slug })))
            }
            href={routes.payments()}
          />
        )}
        {isAdmin && (
          <Navigation.Item
            icon={faLineChart}
            title="Reporting"
            isActive={pathname.includes(routes.reporting())}
            href={routes.reporting()}
          />
        )}
        {isHutto && (
          <Navigation.Item
            icon={faCircleUser}
            title="Contacts"
            isActive={isContactsActive}
            href={routes.contacts()}
          />
        )}
        {isGovWellStaff && (
          <>
            <Navigation.Item
              icon={faUsers}
              title="Org Management"
              isActive={pathname.includes(routes.adminOrgs())}
              href={routes.adminOrgs()}
            />
            <Navigation.Item
              icon={faUsersRectangle}
              title="User Management"
              isActive={pathname.includes(routes.adminUsers())}
              href={routes.adminUsers()}
            />
          </>
        )}
        {!isProduction && (
          <Navigation.Item
            icon={faMegaphone}
            title="Communications"
            isActive={
              pathname === routes.communications() ||
              pathname === routes.newCampaign() ||
              pathname.includes('campaign')
            }
            href={routes.communications()}
          />
        )}
      </SidebarPrimitive.Content>
      <SidebarPrimitive.Footer>
        <CityFooterDisplay />
        <UserProfileNavItem />
        <SettingsNavItem />
      </SidebarPrimitive.Footer>
    </SidebarPrimitive>
  )
}
