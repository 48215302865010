import { ReactNode } from 'react'

import * as Icons from '@fortawesome/pro-regular-svg-icons'
import { parsePhoneNumber } from 'awesome-phonenumber'
import isNil from 'lodash.isnil'

import {
  AutomationActionType,
  RecordTaskGroupType,
  State,
  UserDataFragment,
  ViolationCode,
  ViolationMailerTitle,
} from '../../types/graphql'
import { ViolationSettingsCellViolationType } from '../components/ViolationSettingsCell/types'
import { StateAbbreviationMap } from '../constants/states'

export const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  if (!firstName && !lastName) {
    return ''
  }
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase()
}

export const getInitialsOneStr = (str: string) => {
  const split = str.split(' ')
  const first = split?.[0]?.[0] || ''
  const second = split?.[1]?.[0] || ''
  return `${first}${second}`.toUpperCase()
}

export const formatName = (
  firstName: string | undefined,
  lastName: string | undefined
) => `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`

export const formatViolationCode = (violationCode: Partial<ViolationCode>) => {
  const res = `${violationCode.corpus} ${violationCode.sectionName} (${violationCode.title})`
  return removeLinebreaks(res).trim()
}

export const getUserInitials = (user?: UserDataFragment | null) =>
  getInitials(user?.firstName, user?.lastName)

export const openUrlInNewTab = (url: string) => window.open(url, '_blank')

export const sortViolationTypes = (
  violationTypes: ViolationSettingsCellViolationType[]
) => {
  return [...violationTypes].sort(
    (
      violationType1: ViolationSettingsCellViolationType,
      violationType2: ViolationSettingsCellViolationType
    ) => {
      return violationType1.name > violationType2.name ? 1 : -1
    }
  )
}

export const sortViolationCodes = (violationCodes: ViolationCode[]) => {
  return [...violationCodes].sort(
    (violationCode1: ViolationCode, violationCode2: ViolationCode) => {
      if (violationCode1.corpus > violationCode2.corpus) {
        return 1
      }
      if (violationCode1.corpus < violationCode2.corpus) {
        return -1
      }
      if (violationCode1.sectionName > violationCode2.sectionName) {
        return 1
      }
      if (violationCode1.sectionName < violationCode2.sectionName) {
        return -1
      }
      return 0
    }
  )
}

export const sortViolationMailerTitles = <
  TMailer extends Pick<ViolationMailerTitle, 'title'>
>(
  violationMailerTitles: TMailer[]
) => {
  return [...violationMailerTitles].sort((mt1: TMailer, mt2: TMailer) => {
    if (mt1.title > mt2.title) {
      return 1
    }
    return -1
  })
}

export const removeLinebreaks = (str: string) => {
  return str.replace(/[\r\n]+/gm, '')
}

export const isNumber = (number?: number | string) =>
  number !== undefined && number !== null && typeof number === 'number'

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) {
    return ''
  }
  if (!+bytes) {
    return '0 Bytes'
  }
  const c = 0 > decimals ? 0 : decimals,
    d = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${parseFloat((bytes / Math.pow(1024, d)).toFixed(c))} ${
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  }`
}

export const removeElementAtIndex = (array: any[], index: number) => {
  return [...array].filter(
    (_: any, elementIndex: number) => index !== elementIndex
  )
}

const appendUSIntlCode = (phoneNumber: string) =>
  phoneNumber.startsWith('+1') ? phoneNumber : `+1${phoneNumber}`

export const formatPhoneNumber = (phoneNumber: string): string => {
  return (
    parsePhoneNumber(appendUSIntlCode(phoneNumber), {
      regionCode: 'US',
    })?.number?.national ?? phoneNumber
  )
}

interface ViolationHistoryPartial {
  violationTypeOther?: string
  violationTypes?: {
    id: number
    name: string
  }[]
}

export const getStringifiedViolationTypeForViolation = (
  violationHistory: ViolationHistoryPartial
) => {
  if (violationHistory.violationTypes?.length) {
    return violationHistory.violationTypes.map((t) => t.name).join(', ')
  }
  return 'Other'
}

export const getStringifiedViolationTypesForViolation = (
  violationHistory: ViolationHistoryPartial
): string[] => {
  if (violationHistory.violationTypes?.length) {
    return violationHistory.violationTypes.map((t) => t.name)
  }
  return ['Other']
}

export const getArray0ToN = (number: number): number[] => {
  return [...Array(number).keys()]
}

export const formatAddress = (inputString?: string): string => {
  const words: string[] = inputString?.split(' ') || []

  const formattedWords = words.map((word) => {
    if (Object.values(StateAbbreviationMap).includes(word)) {
      return word.toUpperCase()
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  })

  return formattedWords.map((str) => str.trim()).join(' ')
}

export const formatUserName = (
  user:
    | {
        firstName: string
        lastName: string
        emailAddress?: string | null | undefined
      }
    | null
    | undefined
): string => {
  return (
    formatName(user?.firstName, user?.lastName) || user?.emailAddress || '-'
  )
}

export const getAvg = (nums: number[]): number => {
  if (nums.length === 0) {
    return 0
  }
  const total = nums.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )
  return total / nums.length
}

export const formatCityState = (
  city: string,
  state: State | null | undefined
): string => {
  if (!state) {
    return city
  }
  return `${city}, ${StateAbbreviationMap[state]}`
}

export const isUserGov = (user?: UserDataFragment): boolean =>
  Boolean(user && user.organizationId)
export const isUserCitizen = (user?: UserDataFragment): boolean =>
  Boolean(!user || !user.organizationId)

export const mapStrsToInts = (strs: string[]): number[] => {
  return strs.map((a) => parseInt(a))
}

export const getInt = (value: string | number): number => {
  return typeof value === 'number' ? value : parseInt(value)
}

export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Convert the arrays to sets
  const set1 = new Set(arr1)
  const set2 = new Set(arr2)

  // Compare the sets
  for (const num of set1) {
    if (!set2.has(num)) {
      return false
    }
  }

  return true
}

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export enum ListEnding {
  AND = 'and',
  OR = 'or',
}
interface FormatListSentenceProps {
  words: string[]
  hideFinalPeriod: boolean
  finalItemDelimiter: ListEnding
}

// DUPLICATED ON BE
export const formatListSentence = ({
  words,
  hideFinalPeriod,
  finalItemDelimiter,
}: FormatListSentenceProps): string => {
  const length = words.length
  const finalPeriod = hideFinalPeriod ? '' : '.'

  if (length === 0) {
    return ''
  }

  if (length === 1) {
    return `${words[0]}${finalPeriod}`
  }

  if (length === 2) {
    return `${words[0]} ${finalItemDelimiter} ${words[1]}${finalPeriod}`
  }

  const last = words[length - 1]
  const others = words.slice(0, length - 1).join(', ')

  return `${others}, ${finalItemDelimiter} ${last}${finalPeriod}`
}

export const RecordTaskGroupTypeToRecordTaskGroupNameMap: Record<
  RecordTaskGroupType,
  string
> = {
  ApplicationReview: 'Application Review',
  ApplicationReviewMultiple: 'Application Review',
  Checklist: 'Checklist',
  CreateRequestedRecords: 'Submit Additional Records',
  Custom: 'Custom',
  DocumentGeneration: 'Document Generation',
  Inspections: 'Inspections',
  Issue: 'Issue',
  Migrate: 'Migrate',
  Payment: 'Payment',
  PlanReview: 'Plan Review',
  Renewal: 'Renewal',
  RequestRecords: 'Request Additional Records',
  ScheduleWorkflow: 'Schedule Workflow',
  SendNotification: 'Send Email',
  Expiration: 'Set Expiration Date',
}

export const AutomationActionTypeNameMap: Record<AutomationActionType, string> =
  {
    AddWorkflowStep: 'Add workflow step',
    BeginWorkflow: 'Begin workflow',
    Notification: 'Notification',
    // ScheduleInspections: 'Schedule inspections',
    StatusChange: 'Change status',
  }

export const trimFinalPeriods = (inputString: ReactNode): ReactNode => {
  // Use a regular expression to match one or more periods at the end of the string and replace them with an empty string.
  if (typeof inputString === 'string') {
    return inputString.replace(/\.*$/, '')
  }
  return inputString
}

export const filterNumeric = (str: string): string => {
  return str.replace(/[^\d.]/g, '')
}

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds)
  })
}

export function reorderArray<T>(
  arr: T[] | readonly T[],
  originalIndex: number,
  newIndex: number
): T[] {
  const newArr = [...arr]
  const [movedElement] = newArr.splice(originalIndex, 1)

  // Insert the removed element at the 'toIndex'
  newArr.splice(newIndex, 0, movedElement)
  return newArr
}

export function findIndicesOfObjectsInNewArray<T extends { id: number }>(
  oldData: T[],
  newData: T[]
): number[] {
  const indices: number[] = []

  for (let i = 0; i < oldData.length; i++) {
    const obj1 = oldData[i]
    const indexInArr2 = newData.findIndex((obj2) => obj1.id === obj2.id)

    if (indexInArr2 !== -1) {
      indices.push(indexInArr2)
    }
  }

  return indices
}

export const maybeMakeString = (
  value?: number | boolean | null
): string | undefined => {
  return !isNil(value) ? `${value}` : undefined
}

export const maybeMakeInt = (value?: number | string | null): number => {
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'undefined' || value === null) {
    return NaN
  }
  return parseInt(value)
}

interface OnAddedOrDeletedElementParams {
  arr1: number[]
  arr2: number[]
  onDeleted: (deleted: number) => Promise<void>
  onAdded: (added: number) => Promise<void>
}
export const onAddedOrDeletedElement = async (
  params: OnAddedOrDeletedElementParams
) => {
  const { arr1, arr2, onDeleted, onAdded } = params
  const firstSet = new Set(arr1)
  const secondSet = new Set(arr2)

  for (const element of secondSet) {
    if (!firstSet.has(element)) {
      await onAdded(element)
    }
  }

  for (const element of firstSet) {
    if (!secondSet.has(element)) {
      await onDeleted(element)
    }
  }
}

export interface FormatRecordTypeParams {
  name: string
  recordName?: string | null
}

export const formatRecordType = (
  recordType: FormatRecordTypeParams | null | undefined
): string => {
  return recordType ? `${recordType.name} ${recordType.recordName}` : '-'
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules
const OrdinalSuffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
  zero: 'th', // not returned for ordinal rules for English, just a placeholder value to appease the typing
  many: 'th', // not returned for ordinal rules for English, just a placeholder value to appease the typing
}
export const getOrdinalNumber = (value: number) => {
  const ordinalPluralRules = new Intl.PluralRules('en', { type: 'ordinal' })
  const suffix = OrdinalSuffixes[ordinalPluralRules.select(value)]
  return `${value}${suffix}`
}

export const getIcon = (icon: string) =>
  Icons[icon as keyof typeof Icons] as Icons.IconDefinition

export const isImage = (fileType: string) => {
  return ['image/jpeg', 'image/jpg', 'image/png'].includes(fileType)
}
export const isPdf = (fileType: string) => fileType === 'application/pdf'

export function areSetsEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
  if (setA.size !== setB.size) {
    return false
  }
  for (const elem of setA) {
    if (!setB.has(elem)) {
      return false
    }
  }
  return true
}

export const isHtmlString = (str: string) => {
  // Regular expression to detect HTML tags
  const htmlRegex = /<[^>]*>/
  return htmlRegex.test(str)
}

export const isFunctionAsync = (
  fn: () => void | Promise<void> | Promise<unknown>
) => {
  try {
    const isAsync = fn[Symbol.toStringTag] === 'AsyncFunction'
    return isAsync
  } catch (e) {
    return false
  }
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true
  }

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 == null ||
    obj2 == null
  ) {
    return false
  }

  const keysA = Object.keys(obj1)
  const keysB = Object.keys(obj2)

  if (keysA.length !== keysB.length) {
    return false
  }

  for (const key of keysA) {
    if (!keysB.includes(key)) {
      return false
    }

    if (!deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const filterNil = <T>(arr: (T | null | undefined)[]): T[] =>
  arr.flatMap((i) => (isNil(i) ? [] : [i]))
