import { ReactNode } from 'react'

import styled from 'styled-components'

import { PageTitle } from './StyledComponents'

const LayoutContainer = styled.div<{
  $minHeight?: React.CSSProperties['minHeight']
  $maxHeight?: React.CSSProperties['maxHeight']
  $overflowY?: React.CSSProperties['overflowY']
}>`
  width: 100%;
  height: 100%;
  ${({ $minHeight }) => `min-height: ${$minHeight ?? 'fit-content'};`};
  ${({ $maxHeight }) => ($maxHeight ? `max-height: ${$maxHeight};` : '')};
  ${({ $overflowY }) => ($overflowY ? `overflow-y: ${$overflowY};` : '')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
`

const Main = styled.main<{
  $hasTitle: boolean
  $overflowY?: React.CSSProperties['overflowY']
}>`
  flex: 1;
  padding-top: ${({ $hasTitle }) => ($hasTitle ? '24px' : '0px')};
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${({ $overflowY }) => ($overflowY ? `overflow-y: ${$overflowY};` : '')};
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

type LayoutType = 'stretch' | 'overflow'

type Props = {
  children: ReactNode
  layoutType?: LayoutType
  title?: ReactNode
  titleButton?: ReactNode
}
export const PageContent = (props: Props) => {
  const { children, layoutType = 'stretch', title, titleButton } = props
  return (
    <LayoutContainer
      {...(layoutType === 'overflow'
        ? {
            $minHeight: '0',
            $maxHeight: '100%',
          }
        : {})}
    >
      {title && (
        <Header>
          <PageTitle title={title} />
          {titleButton}
        </Header>
      )}
      <Main
        $hasTitle={!!title}
        {...(layoutType === 'overflow' ? { $overflowY: 'auto' } : {})}
      >
        {children}
      </Main>
    </LayoutContainer>
  )
}

export default PageContent
