export const EnumerationFragment = gql`
  fragment EnumerationFragment on Enumeration {
    id
    name
    gisAttributeId
    values {
      ...EnumerationValueFragment
    }
  }
  fragment EnumerationValueFragment on EnumerationValue {
    id
    name
    gisAttributeValuesToMatch
  }
`
