import { Ref } from 'react'

import { Input } from 'antd'
import { styled } from 'styled-components'

import { getLabelStr } from './util'
import Wrapper, { FormFieldWrapperProps } from './Wrapper'

const { TextArea: AntdTextArea } = Input

interface Props extends FormFieldWrapperProps {
  placeholder?: string
  minRows?: number
  maxRows?: number
  maxLength?: number
  noAutoSize?: boolean
  onKeyDown?: React.TextareaHTMLAttributes<HTMLTextAreaElement>['onKeyDown']
  onBlur?: (newVal: string) => Promise<void>
  textAreaRef?: Ref<HTMLTextAreaElement>
}

const StyledTextArea = styled(AntdTextArea)`
  .countClass {
    bottom: -25px;
  }
`

export function TextArea(props: Props) {
  const {
    placeholder,
    minRows,
    maxRows,
    maxLength,
    noAutoSize,
    onKeyDown,
    onBlur,
    disabled,
    textAreaRef,
    ...formFieldWrapperProps
  } = props
  return (
    <Wrapper {...formFieldWrapperProps}>
      <StyledTextArea
        ref={textAreaRef}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder || getLabelStr(formFieldWrapperProps.label)}
        classNames={{ count: 'countClass' }}
        onBlur={async (event) => await onBlur?.(event.target.value)}
        onKeyDown={onKeyDown}
        {...(noAutoSize ? {} : { autoSize: { minRows, maxRows } })}
      />
    </Wrapper>
  )
}

export default TextArea
