import { ReactNode } from 'react'

import {
  IconDefinition,
  faCircleExclamation,
  faFileCertificate,
  faHouse,
  faSquareDashed,
} from '@fortawesome/pro-regular-svg-icons'

export enum UniversalSearchOptionType {
  Parcel,
  Address,
  Violation,
  Record,
}

export const UniversalSearchOptionTypeToIconDefinitionMap: Record<
  UniversalSearchOptionType,
  IconDefinition
> = {
  [UniversalSearchOptionType.Parcel]: faSquareDashed,
  [UniversalSearchOptionType.Address]: faHouse,
  [UniversalSearchOptionType.Violation]: faCircleExclamation,
  [UniversalSearchOptionType.Record]: faFileCertificate,
}

export type UniversalSearchOption = {
  type: UniversalSearchOptionType
  title: string
  subtitle: ReactNode
  subtitleSortString: string
  url: string
  uuid: string
}

export type UniversalSearchType = {
  label: string
  mode: 'all' | 'addresses' | 'parcels' | 'records' | 'violations'
  recordTypeId?: number
}
