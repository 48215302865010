import { ColumnsType } from 'antd/es/table'

import { navigate, routes } from '@redwoodjs/router'

import PageContent from '../../components/shared/PageContent'
import { PageTitle, TableLink } from '../../components/shared/StyledComponents'
import { TableV2 } from '../../components/TableV2'
import { TableHeaderProps } from '../../components/TableV2/types'
import { useTable } from '../../hooks/use-table'

import { Campaign, Campaigns } from './types'

export const CommunicationsPage = () => {
  const tableHeader: TableHeaderProps = {
    name: 'Campaigns',
    buttonProps: {
      text: 'Create New Campaign',
      onClick: () => navigate(routes.newCampaign()),
    },
  }
  const columns: ColumnsType<Campaign> = [
    {
      title: 'Name',
      render: (c: Campaign) => (
        <TableLink href={routes.campaign({ campaignId: c.id })}>
          {c.name}
        </TableLink>
      ),
    },
    {
      title: 'Sent On',
      render: (c: Campaign) => c.sentOn,
    },
    {
      title: 'Sent By',
      render: (c: Campaign) => c.sentBy,
    },
    {
      title: 'Number of Recipients',
      render: (c: Campaign) => c.count,
    },
    {
      title: 'Number of Email Opens',
      render: (c: Campaign) => c.viewCount,
    },
    {
      title: 'Recipient Segmentation',
      render: (c: Campaign) => c.sentTo,
    },
  ]
  const tableProps = useTable<Campaign>({ data: Campaigns })
  return (
    <PageContent>
      <PageTitle title="Campaigns" />
      <TableV2<Campaign>
        {...tableProps}
        header={tableHeader}
        columns={columns}
      />
    </PageContent>
  )
}

export default CommunicationsPage
