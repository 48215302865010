import { Collapse, Form } from 'antd'
import styled from 'styled-components'

import { areSetsEqual } from '../../utils'
import Checkbox from '../form/Checkbox'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import SelectCreate from '../form/SelectCreate'
import TrueFalseSelect from '../form/shared/TrueFalseSelect'
import Text, { TextSize } from '../Typography/Text'

import { FilterInputRenderType, FilterInput as FilterInputType } from './types'

const LabelContainer = styled.div`
  display: flex;
  gap: 4px;
`

interface Props {
  filterInput: FilterInputType
  renderType: FilterInputRenderType
}

export const RenderedFilterInput = (props: Props) => {
  const {
    filterInput: { label, checkboxOptions, type, caption },
    renderType,
  } = props

  const fieldName = props.filterInput.fieldName as string

  const commonProps = {
    fieldName: fieldName as string,
    caption,
    ...(renderType === FilterInputRenderType.FormFields ? { label } : {}),
  }

  const { setFieldValue, getFieldValue } = Form.useFormInstance()

  const getChildren = () => {
    if (checkboxOptions) {
      const value = getFieldValue(fieldName)
      const currValues = new Set(value)
      const allValues = new Set(checkboxOptions.map((co) => co.value))
      const showSelectAll = !areSetsEqual(currValues, allValues)
      return (
        <Checkbox
          {...commonProps}
          label={
            <LabelContainer>
              {commonProps.label}
              <Text
                size={TextSize.Small}
                pointer
                colorToken="cyan-7"
                margin="0"
                onClick={() => {
                  if (showSelectAll) {
                    setFieldValue(
                      fieldName,
                      checkboxOptions.map((co) => co.value)
                    )
                  } else {
                    setFieldValue(fieldName, [])
                  }
                }}
              >
                {showSelectAll ? 'Select all' : 'Select none'}
              </Text>
            </LabelContainer>
          }
          noMargin
          options={checkboxOptions}
          mode="vertical"
        />
      )
    }
    if (type === 'Date') {
      return (
        <DatePicker
          {...commonProps}
          range
          noMargin
          allowedDates={DatePickerAllowedDates.All}
        />
      )
    }

    if (type === 'Strings') {
      return <SelectCreate {...commonProps} noMargin placeholder={label} />
    }

    if (type === 'Bool') {
      return <TrueFalseSelect {...commonProps} noMargin />
    }
  }

  if (renderType === FilterInputRenderType.Collapse) {
    return (
      <CollapseContainer>
        <Collapse
          size="small"
          bordered={false}
          items={[
            {
              label: <Text size={TextSize.Large}>{label}</Text>,
              children: getChildren(),
            },
          ]}
        />
      </CollapseContainer>
    )
  }

  return getChildren()
}

const CollapseContainer = styled.div`
  .ant-collapse-header {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
  .ant-collapse-borderless {
    background-color: transparent;
    &:hover {
      background-color: ${(props) => props.theme.colorFillAlter};
    }
  }
  .ant-collapse-expand-icon {
    display: none !important;
  }
`
