import { useCallback, useMemo } from 'react'

import { faCloud, faNotebook } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Flex, Skeleton } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'

import { makeInitialFormValues } from 'src/components/Field/be-to-fe'
import { RenderedFormFieldInput } from 'src/components/FieldAndFileInputs/RenderedFormFieldInput'
import Modal from 'src/components/Modal'
import { FormContainer } from 'src/components/shared/StyledComponents'
import { useLogInspectionModalQuery } from 'src/fetch/logInspectionModal'
import useDisclosure from 'src/hooks/use-disclosure'
import { useMyOrg } from 'src/layouts/AppStateContextLayout/utils'
import { filterNil } from 'src/utils'
import { useAnnounceFirstInvalidField } from 'src/utils/forms'
import { QueryKey } from 'src/utils/queryClient'

import { AcceptedFileTypes } from '../../../constants'
import {
  useConvertRecordTaskInspectionAttemptToDraftMutation,
  useLogRecordTaskInspectionAttemptMutation,
  useSubmitRecordTaskInspectionAttemptDraftMutation,
  useUpdateRecordTaskInspectionAttemptDraftMutation,
} from '../../../fetch/recordTaskInspectionAttempts'
import {
  mapFieldToSingleFormFieldInput,
  mapMultipleFieldsToFormFieldInputs,
} from '../../Field/fe-to-be'
import Text, { TextSize } from '../../Typography/Text'
import { InspectionsTabModalProps } from '../types'

import InspectionTimingInputs, {
  InspectionEndedAtEntryType,
} from './InspectionTimingInputs'

type FormValues = {
  startedAt: dayjs.Dayjs
  endedAt?: dayjs.Dayjs
  endedAtEntryType: InspectionEndedAtEntryType
} & Record<string, unknown>

type Props = InspectionsTabModalProps & {
  recordTaskInspectionAttemptId: number
}
const LogInspectionModal = (props: Props) => {
  const {
    refetch,
    modalState,
    inspectionTemplateName,
    recordTaskInspectionId,
    recordTaskInspectionAttemptId,
    status,
    addressEntryType,
  } = props
  const { data, isLoading } = useLogInspectionModalQuery(recordTaskInspectionId)
  const inspection = data?.inspection
  const latestAttempt = inspection?.latestAttempt
  const wasLoggedWithStatus = !!status
  const wasSavedAsDraft = status === 'Draft'

  const { mutateAsync: logInspectionAttempt } =
    useLogRecordTaskInspectionAttemptMutation()
  const { mutateAsync: convertToDraft } =
    useConvertRecordTaskInspectionAttemptToDraftMutation()
  const { mutateAsync: updateDraft } =
    useUpdateRecordTaskInspectionAttemptDraftMutation()
  const { mutateAsync: submitDraft } =
    useSubmitRecordTaskInspectionAttemptDraftMutation()

  const inspectionTemplateFields = useMemo(
    () => inspection?.inspectionTemplate.fieldGroup?.fields || [],
    [inspection?.inspectionTemplate.fieldGroup?.fields]
  )
  const orgSlug = useMyOrg()?.slug

  const queryClient = useQueryClient()

  const initialFormValues: FormValues = useMemo(
    () =>
      wasSavedAsDraft
        ? {
            ...makeInitialFormValues({
              fields: latestAttempt?.fieldGroup?.fields ?? [],
            }),
            startedAt: dayjs(latestAttempt?.inspectionStartedAt),
            endedAt: latestAttempt?.inspectionEndedAt
              ? dayjs(latestAttempt.inspectionEndedAt)
              : undefined,
            endedAtEntryType: latestAttempt?.inspectionEndedAt
              ? 'Manual'
              : 'Automatic',
          }
        : {
            startedAt: dayjs(),
            endedAtEntryType: 'Automatic',
          },
    [
      wasSavedAsDraft,
      latestAttempt?.fieldGroup?.fields,
      latestAttempt?.inspectionEndedAt,
      latestAttempt?.inspectionStartedAt,
    ]
  )

  const [form] = useForm<FormValues>()
  const announceInvalidField = useAnnounceFirstInvalidField(form)
  const {
    isOpen: isSubmitting,
    open: startSubmitting,
    close: stopSubmitting,
  } = useDisclosure()
  const {
    isOpen: isSubmittingDraft,
    open: startSubmittingDraft,
    close: stopSubmittingDraft,
  } = useDisclosure()
  const onOk = useCallback(
    async (args?: { saveAsDraft: true }): Promise<void> => {
      const values = form.getFieldsValue()
      if (!values || !orgSlug || isLoading) {
        return
      }
      try {
        if (!args?.saveAsDraft) {
          await form.validateFields()
          startSubmitting()
        } else {
          startSubmittingDraft()
        }
        const formFields = mapMultipleFieldsToFormFieldInputs({
          fields: filterNil(inspectionTemplateFields),
          values,
        })

        const input = {
          id: recordTaskInspectionAttemptId,
          input: {
            formFields,
            orgSlug,
            startedAt: values.startedAt?.toISOString(),
            endedAt: values.endedAt?.toISOString(),
          },
        }
        if ((!wasLoggedWithStatus || !wasSavedAsDraft) && args?.saveAsDraft) {
          await convertToDraft(input)
        } else if (wasSavedAsDraft && args?.saveAsDraft) {
          await updateDraft(input)
        } else if (wasSavedAsDraft && !args?.saveAsDraft) {
          await submitDraft(input)
        } else {
          await logInspectionAttempt(input)
        }
        await refetch()
        await queryClient.refetchQueries({ queryKey: [QueryKey.Workflow] })
        modalState.close()
      } catch (e) {
        announceInvalidField()
        console.error(e)
      } finally {
        stopSubmitting()
        stopSubmittingDraft()
      }
    },
    [
      form,
      orgSlug,
      isLoading,
      inspectionTemplateFields,
      recordTaskInspectionAttemptId,
      wasLoggedWithStatus,
      wasSavedAsDraft,
      refetch,
      queryClient,
      modalState,
      startSubmitting,
      startSubmittingDraft,
      convertToDraft,
      updateDraft,
      submitDraft,
      logInspectionAttempt,
      announceInvalidField,
      stopSubmitting,
      stopSubmittingDraft,
    ]
  )

  return (
    <Modal
      {...modalState}
      icon={faNotebook}
      title="Log Inspection"
      okText="Log inspection"
      onOk={onOk}
      footer={
        <Flex justify="flex-end" align="center" gap="6px">
          <Button onClick={modalState.close}>Cancel</Button>
          <Button
            onClick={() => onOk({ saveAsDraft: true })}
            loading={isSubmittingDraft}
            disabled={isSubmitting}
            icon={<FontAwesomeIcon icon={faCloud} />}
          >
            {wasSavedAsDraft ? 'Update draft' : 'Save as draft'}
          </Button>
          <Button
            type="primary"
            onClick={() => onOk()}
            loading={isSubmitting}
            disabled={isSubmittingDraft}
            icon={<FontAwesomeIcon icon={faNotebook} />}
          >
            Log inspection
          </Button>
        </Flex>
      }
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <FormContainer form={form} initialValues={initialFormValues}>
          <Flex vertical gap="12px">
            <Text size={TextSize.Large} margin="0 0 12px 0">
              Inspection Type: {inspectionTemplateName}
            </Text>
            <InspectionTimingInputs />
          </Flex>
          {filterNil(inspectionTemplateFields).map((f) => (
            <RenderedFormFieldInput
              key={`field-${f.id}`}
              formFieldInput={{
                id: f.id,
                input: mapFieldToSingleFormFieldInput({ field: f }),
              }}
              fieldNameSuffix={`${f.id}`}
              errorMessage={
                f.required ? 'Please input a valid value.' : undefined
              }
              acceptFileTypes={AcceptedFileTypes}
              addressEntryType={addressEntryType}
            />
          ))}
        </FormContainer>
      )}
    </Modal>
  )
}

export default LogInspectionModal
