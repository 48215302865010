import { MouseEventHandler } from 'react'

import { faRotate, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { UploadMultipleFilesFileUploadState } from '../../hooks/use-files-upload-state'
import { useTheme } from '../../hooks/use-theme'
import { formatFileProgress } from '../../utils/file'
import { Progress } from '../FileUpload/Progress'
import Text, { TextSize } from '../Typography/Text'

import { FileCard } from './StyledComponents'
const ButtonContainer = styled.div`
  right: 24px;
  top: 12px;
`

interface Props extends UploadMultipleFilesFileUploadState {
  cancelUpload: () => void
}

const onClick: MouseEventHandler = (e: React.MouseEvent<Element, MouseEvent>) =>
  e.stopPropagation()

export const UploadingFileCard = (props: Props) => {
  const { progress, name } = props
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)
  if (small) {
    return <UploadingFileCardSmall {...props} />
  }
  return (
    <FileCard onClick={onClick} $small={false}>
      <ButtonContainer>
        <CancelButton {...props} />
      </ButtonContainer>
      <Tooltip trigger={['hover', 'click']} title={name}>
        <Text size={TextSize.Base} maxLines={1}>
          {name}
        </Text>
      </Tooltip>
      <Progress progress={progress} />
    </FileCard>
  )
}

export const UploadingFileCardSmall = (props: Props) => {
  const { getTokenVal } = useTheme()
  const { progress, name } = props
  return (
    <FileCard $small>
      <FontAwesomeIcon
        icon={faRotate}
        style={{
          height: 16,
          width: 16,
          color: getTokenVal('colorPrimaryBase'),
        }}
      />
      <Tooltip trigger={['hover', 'click']} title={name}>
        <Text size={TextSize.Base} style={{ flex: 1, textAlign: 'left' }}>
          {name}
        </Text>
      </Tooltip>
      {formatFileProgress(progress)}%
      <CancelButton {...props} />
    </FileCard>
  )
}

const CancelButton = (props: Props) => {
  const { cancelUpload } = props
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)
  return (
    <Button
      type="text"
      size="small"
      onClick={(e) => {
        onClick(e)
        cancelUpload()
      }}
      icon={<FontAwesomeIcon icon={faXmark} />}
    >
      {!small && 'Cancel'}
    </Button>
  )
}
