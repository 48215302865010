import { ReactNode, useEffect, useMemo, useState } from 'react'

import { LocationSnapshotInDepthFragment } from 'types/graphql'

import { Map } from '../../components/shared/Map'
import { MapShape } from '../../components/shared/Map/types'

type Props<T extends { id: number }> = {
  getLocationSnapshot: (
    datum: T
  ) => LocationSnapshotInDepthFragment | null | undefined
  renderPopup: (datum: T) => ReactNode
  data: T[]
  hoveredId?: number
  isLoading?: boolean
}

export function DataMap<T extends { id: number }>({
  hoveredId,
  isLoading,
  data,
  renderPopup,
  getLocationSnapshot,
}: Props<T>) {
  const shapes: MapShape[] = useMemo(
    () =>
      data?.flatMap((datum) => {
        const locationSnapshot = getLocationSnapshot(datum)
        const coordinates = locationSnapshot?.parcelCoordinates || []
        if (!coordinates?.length) {
          return []
        }
        return {
          coordinates,
          shapeKey: `shape-${datum.id}`,
          showPin: true,
          isHovered: hoveredId === datum.id,
          renderPopup: () => renderPopup(datum),
        }
      }) ?? [],
    [data, getLocationSnapshot, hoveredId, renderPopup]
  )
  const [shapesToDisplay, setShapesToDisplay] = useState(shapes)
  useEffect(() => {
    if (isLoading) {
      return // Show the previously loaded map until the new query is complete, otherwise it's a long period of showing a blank map
    }
    setShapesToDisplay(shapes)
  }, [isLoading, shapes])

  if (!shapesToDisplay?.length) {
    return null
  }

  return <Map shapes={shapes} isLoading={isLoading} />
}
