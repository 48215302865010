import { ReactNode } from 'react'

import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { Tag } from 'antd'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'

import ForeignValueInput from 'src/components/form/ForeignValueInput'
import CodeBookItemSelect from 'src/components/form/shared/CodeBookItemSelect'

import {
  FormFieldInputWithId,
  OrganizationAddressEntryType,
} from '../../../types/graphql'
import { AcceptedPdf } from '../../constants'
import { PathPrefixes } from '../../constants/file'
import { useSlug } from '../../hooks/use-slug'
import { useMyOrg } from '../../layouts/AppStateContextLayout/utils'
import { filterNil, isHtmlString } from '../../utils'
import {
  destructureFieldParameters,
  makeFieldNameForFieldFragment,
} from '../Field/util'
import { AddressAutocomplete } from '../form/AddressAutocomplete'
import Checkbox from '../form/Checkbox'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import { FileInput } from '../form/FileInput'
import { FilesInput } from '../form/FilesInput'
import Input, { InputTypes } from '../form/Input'
import { RadioGroup } from '../form/RadioGroup'
import { RenderedRichTextInput } from '../form/RichTextInput'
import Select, { BasicOptionType } from '../form/Select'
import RecordAutocompleteCell from '../form/shared/RecordAutocompleteCell'
import { RenderRecordAutocompleteModalContentFn } from '../form/shared/RecordAutocompleteCell/RecordAutocomplete'
import RecordCollectionSelect from '../form/shared/RecordCollectionSelect'
import { SingleOrMultipleInput } from '../form/shared/SingleOrMultipleInput'
import ViolationTypeSelectCell from '../form/shared/ViolationTypeSelectCell'
import { Signature } from '../form/Signature'
import TextArea from '../form/TextArea'
import Text, { TextSize } from '../Typography/Text'

import FormFieldErrorBoundary from './FormFieldErrorBoundary'
import { getAcceptedFileTypesForFileInput } from './modals/util'
import { RenderedContactInputs } from './RenderedContactInputs'
import { RenderedFormFieldInputProps } from './types'

interface LineBreakProps {
  $hidden: boolean
}
const LineBreak = styled.div<LineBreakProps>`
  width: 100%;
  background-color: ${(props) =>
    props.$hidden ? 'transparent' : props.theme.colorBorder};
  height: 1px;
`

interface Props {
  formFieldInput: FormFieldInputWithId
  mode?: 'create' | 'edit'
  errorMessage?: string
  fieldNameSuffix: string
  noValidation?: boolean
  inputBucket?: string
  acceptFileTypes?: string
  renderRecordAutocompleteModalContent?: RenderRecordAutocompleteModalContentFn
  disabled?: boolean
  noMargin?: boolean
  addressEntryType: OrganizationAddressEntryType | undefined
  userHasAccessToForeignValue?: boolean
}
export const RenderedFormFieldInput = (props: Props): ReactNode[] => {
  const {
    fieldNameSuffix,
    mode = 'create',
    errorMessage,
    formFieldInput,
    formFieldInput: {
      input: {
        label,
        required,
        tooltip,
        type,
        foreignValueType,
        allowMultiple,
        recordTemplateIdParams,
      },
    },
    noValidation,
    inputBucket,
    acceptFileTypes,
    renderRecordAutocompleteModalContent,
    disabled,
    noMargin,
    addressEntryType = 'SearchAndManual',
    userHasAccessToForeignValue,
  } = props
  const parameters = destructureFieldParameters(
    props.formFieldInput.input.parameters || {}
  )

  const slug = useSlug()

  const fieldName = makeFieldNameForFieldFragment(fieldNameSuffix)

  const inputProps: RenderedFormFieldInputProps = {
    label,
    required: required || false,
    tooltip: tooltip ?? '',
    noValidation,
    placeholder: label ?? '',
    ...(errorMessage ? { errorMessage } : {}),
    fieldName,
    parameters,
    fieldNameSuffix,
    disabled,
    noMargin,
    allowMultiple: allowMultiple || false,
    addressEntryType,
    recordTemplateIdParams: recordTemplateIdParams?.flatMap((id) => id || []),
  }

  const key = `input-${fieldNameSuffix}`

  const inspectionResults = (useMyOrg()?.inspectionResults || []).filter(
    (ir) => ir.isActive
  )

  const render = () => {
    switch (type) {
      case 'ShortText': {
        return [
          <SingleOrMultipleInput
            key={key}
            recordTitle="Text"
            renderInput={(params) => {
              const fieldNameForInput = allowMultiple
                ? [`${params.index}`]
                : fieldName
              return <Input {...params} fieldName={fieldNameForInput} />
            }}
            initialValue=""
            {...inputProps}
          />,
        ]
      }
      case 'LongText': {
        return [<TextArea key={key} {...inputProps} noAutoSize />]
      }
      case 'Number': {
        return [<Input key={key} {...inputProps} type={InputTypes.Number} />]
      }
      case 'Checkbox': {
        const { checkboxText } = parameters
        const options = [
          {
            label: checkboxText || '',
            value: checkboxText || '',
          },
        ]
        return [
          <Checkbox
            key={key}
            {...inputProps}
            fieldName={inputProps.fieldName as string}
            options={options}
          />,
        ]
      }
      case 'Date': {
        return [
          <DatePicker
            key={key}
            size="large"
            {...inputProps}
            allowedDates={DatePickerAllowedDates.All}
          />,
        ]
      }
      case 'Radio': {
        const { options } = parameters
        const items = (options || []).map((val) => ({
          value: val,
          display: val,
        }))
        return [
          <RadioGroup size="large" {...inputProps} key={key} items={items} />,
        ]
      }
      case 'Dropdown': {
        const { options, dropdown } = parameters
        const items = (options || []).map((val) => ({
          value: val,
          id: val,
        }))
        return [
          <Select
            key={key}
            size="large"
            {...inputProps}
            options={items}
            suffixIcon={faChevronDown}
            {...(dropdown?.limit ? {} : { mode: 'multiple' })}
          />,
        ]
      }
      case 'Currency': {
        return [<Input key={key} {...inputProps} type={InputTypes.Currency} />]
      }
      case 'Address': {
        return [
          <AddressAutocomplete
            key={key}
            {...inputProps}
            placeholder={
              addressEntryType === 'ManualOnly'
                ? 'Enter an address'
                : 'Search for an address'
            }
            addressEntryType={addressEntryType}
          />,
        ]
      }
      case 'File': {
        const acceptedFileTypes = getAcceptedFileTypesForFileInput(
          parameters,
          slug
        )
        return [
          <FileInput
            {...inputProps}
            key={key}
            errorMessage={errorMessage}
            fieldName={makeFieldNameForFieldFragment(formFieldInput?.id ?? -1)}
            uploadParams={{
              inputBucket,
              pathPrefix: PathPrefixes.NewPermitTypeForm,
              accept: acceptedFileTypes || acceptFileTypes || AcceptedPdf,
            }}
          />,
        ]
      }
      case 'Files': {
        const acceptedFileTypes = getAcceptedFileTypesForFileInput(
          parameters,
          slug
        )
        return [
          <FilesInput
            {...inputProps}
            key={key}
            errorMessage={errorMessage}
            fieldName={makeFieldNameForFieldFragment(formFieldInput.id ?? -1)}
            uploadParams={{
              inputBucket,
              pathPrefix: PathPrefixes.NewPermitTypeForm,
              accept: acceptedFileTypes || acceptFileTypes || AcceptedPdf,
            }}
          />,
        ]
      }
      case 'InspectionResult': {
        const options: BasicOptionType<number>[] = filterNil(
          inspectionResults
        ).map((ir) => ({
          label: <Tag color={ir.isPassing ? 'green' : 'red'}>{ir.name}</Tag>,
          value: ir.id,
        }))
        return [
          <Select<BasicOptionType<number>>
            key={key}
            {...inputProps}
            options={options}
            filterOption={(input: string, value: number) => {
              const id = options.find((o) => o.value === value)?.value
              return !!inspectionResults
                .find((ir) => ir?.id === id)
                ?.name.toLowerCase()
                .startsWith(input.toLowerCase())
            }}
          />,
        ]
      }
      case 'Contact': {
        return RenderedContactInputs(inputProps)
      }
      case 'Header': {
        const { label: header } = inputProps
        return [
          <Title key={key} style={{ margin: 0 }} level={2}>
            {header}
          </Title>,
        ]
      }
      case 'ExplanationText': {
        const { label: explanationText } = inputProps
        if (
          typeof explanationText === 'string' &&
          isHtmlString(explanationText)
        ) {
          return [
            <RenderedRichTextInput key={key}>
              {explanationText}
            </RenderedRichTextInput>,
          ]
        }
        return [
          <Text size={TextSize.Large} key={key}>
            {explanationText}
          </Text>,
        ]
      }
      case 'LineBreak': {
        return [
          <LineBreak
            key={key}
            $hidden={parameters?.lineBreak?.hidden || false}
          />,
        ]
      }
      case 'Signature': {
        return [
          <Signature
            key={key}
            {...inputProps}
            disabled={inputProps.disabled}
          />,
        ]
      }
      case 'Record': {
        if (!recordTemplateIdParams) {
          return []
        }
        return [
          <RecordAutocompleteCell
            key={key}
            {...inputProps}
            recordTemplateIds={recordTemplateIdParams}
            submitText={parameters?.record?.submitText ?? ''}
            preventSubmission={parameters?.record?.preventSubmission || false}
            renderRecordAutocompleteModalContent={
              renderRecordAutocompleteModalContent
            }
          />,
        ]
      }
      case 'ViolationType': {
        return [
          <ViolationTypeSelectCell
            slug={slug}
            key={key}
            mode="multiple"
            {...inputProps}
          />,
        ]
      }
      case 'RecordCollection': {
        return [
          <RecordCollectionSelect
            orgSlug={slug}
            key={key}
            mode="multiple"
            size="large"
            {...inputProps}
          />,
        ]
      }
      case 'ForeignValue': {
        return [
          <ForeignValueInput
            key={key}
            type={foreignValueType ?? 'Other'}
            userHasAccessToForeignValue={userHasAccessToForeignValue}
            mode={mode}
            {...inputProps}
          />,
        ]
      }
      case 'CodeBookItem': {
        return [
          <CodeBookItemSelect
            key={key}
            mode="multiple"
            size="large"
            {...inputProps}
          />,
        ]
      }
    }
  }

  if (type === 'Contact') {
    return render() || [] // Contacts have multiple fields, do not wrap in one component
  }
  return [<FormFieldErrorBoundary key={key}>{render()}</FormFieldErrorBoundary>]
}
