import {
  faListCheck,
  faLocationDot,
  faProjectDiagram,
} from '@fortawesome/pro-regular-svg-icons'

import { routes, useLocation, useParams } from '@redwoodjs/router'

import Navigation from 'src/components/Sidebar/Navigation/Navigation'

import { UserCompanyNavItem, UserProfileNavItem } from './SidebarFooter'
import { SidebarPrimitive } from './SidebarPrimitive'

interface Props {
  fullWidth?: boolean
}

export const ApplicantSidebar = (props: Props) => {
  const { fullWidth } = props
  const { pathname } = useLocation()
  const { recordUuid } = useParams()
  const activeUrls: string[] = [routes.applicantProjects()]
  if (recordUuid) {
    activeUrls.push(routes.record({ recordUuid }))
  }

  return (
    <SidebarPrimitive fullWidth={fullWidth}>
      <SidebarPrimitive.Header />
      <SidebarPrimitive.Content>
        <Navigation.Item
          icon={faProjectDiagram}
          title="Projects"
          isActive={activeUrls.includes(pathname)}
          href={routes.applicantProjects()}
        />
        <Navigation.Item
          icon={faListCheck}
          title="Tasks"
          href={routes.tasks()}
          isActive={pathname.includes(routes.tasks())}
        />
        <Navigation.Item
          icon={faLocationDot}
          title="Places"
          isActive={pathname === routes.applicantCityPick()}
          href={routes.applicantCityPick()}
        />
      </SidebarPrimitive.Content>
      <SidebarPrimitive.Footer>
        <UserProfileNavItem />
        <UserCompanyNavItem />
      </SidebarPrimitive.Footer>
    </SidebarPrimitive>
  )
}
