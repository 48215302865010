import { UserDataFragment } from '../../../../types/graphql'
import Select, {
  BasicOptionType,
  SelectProps,
} from '../../../components/form/Select'
import { useMyOrgActiveUsers } from '../../../layouts/AppStateContextLayout/utils'
import { formatUserName } from '../../../utils'
import { filterNil } from '../../../utils'
import { getLabelStr } from '../util'
import { FormFieldWrapperProps } from '../Wrapper'

interface Props
  extends Omit<SelectProps<any>, 'fieldName' | 'label' | 'options'>,
    FormFieldWrapperProps {
  disabledUserIds?: number[]
  noLabel?: boolean
  users?: UserDataFragment[]
  formatUser?: (u: UserDataFragment) => string
  additionalOptions?: BasicOptionType<number | string>[]
  filterUser?: (user: UserDataFragment) => boolean
}

export const OwnerSelect = (props: Props) => {
  const {
    disabledUserIds,
    noLabel,
    additionalOptions,
    formatUser,
    filterUser,
    ...rest
  } = props

  const orgUsers = useMyOrgActiveUsers()
  const baseUsers = props.users || orgUsers
  const users = filterUser ? filterNil(baseUsers).filter(filterUser) : baseUsers

  const ownerOptions: BasicOptionType<number | string>[] = filterNil(users).map(
    (user) => ({
      label: formatUser ? formatUser(user) : formatUserName(user),
      value: user.id,
      disabled: disabledUserIds?.includes(user.id),
    })
  )
  if (additionalOptions?.length) {
    ownerOptions.push(...additionalOptions)
  }

  return (
    <>
      <Select
        {...rest}
        fieldName={rest.fieldName || 'ownerUserId'}
        label={noLabel ? undefined : rest.label || 'Owner'}
        placeholder={rest.placeholder || getLabelStr(rest.label) || 'Owner'}
        options={ownerOptions}
        {...(rest.mode ? { mode: rest.mode } : {})}
      />
    </>
  )
}
