import { SingleOrMultipleInput } from '../SingleOrMultipleInput'

import {
  RecordAutocomplete,
  RecordAutocompleteProps,
} from './RecordAutocomplete'

export const QUERY = gql`
  query RecordAutocompleteCellQuery($recordTemplateIds: [Int!]!) {
    recordTemplates: recordTemplatesByIds(ids: $recordTemplateIds) {
      recordTypeId
      name
      uuid
      recordType {
        name
        recordName
      }
    }
  }
`
export const Success = (props: RecordAutocompleteProps) => {
  return (
    <SingleOrMultipleInput
      {...props}
      recordTitle="Linked Record"
      renderInput={(params) => <RecordAutocomplete {...props} {...params} />}
    />
  )
}
export const Loading = (props: RecordAutocompleteProps) => {
  return (
    <SingleOrMultipleInput
      {...props}
      recordTitle="Linked Record"
      renderInput={(params) => <RecordAutocomplete {...props} {...params} />}
    />
  )
}
