export const WorkflowOptionFragment = gql`
  fragment WorkflowOptionFragment on Workflow {
    id
    completedAt
    createdAt
    status
    workflowTemplate {
      id
      name
    }
  }
`
