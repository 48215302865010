import {
  IconDefinition,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonProps, Dropdown, Tooltip } from 'antd'

export interface DropdownMenuItem {
  label: string
  key: string
  icon: IconDefinition
  onClick: () => void | Promise<void>
  id?: string
  disabled?: boolean
  tooltip?: string
}

interface Props {
  items: DropdownMenuItem[]
  id?: string
  icon?: IconDefinition
  iconColor?: string
  buttonType?: ButtonProps['type']
  buttonSize?: ButtonProps['size']
  title?: string
}
export const EllipsisButton = (props: Props) => {
  const {
    items: inputItems,
    id,
    icon,
    iconColor,
    buttonSize,
    buttonType,
    title = 'More',
  } = props
  const items = inputItems.map((item: DropdownMenuItem) => ({
    ...item,
    ...(item.tooltip
      ? {
          label: <Tooltip title={item.tooltip}>{item.label}</Tooltip>,
        }
      : {}),
    icon: <FontAwesomeIcon icon={item.icon} id={item.id} />,
    onClick: async (e) => {
      e?.domEvent?.stopPropagation?.()
      await item.onClick()
    },
  }))
  return (
    <Dropdown trigger={['click']} menu={{ items }} placement="bottomRight">
      <Button
        id={id}
        onClick={(e) => e.stopPropagation()}
        size={buttonSize || 'small'}
        icon={
          <FontAwesomeIcon icon={icon || faChevronDown} color={iconColor} />
        }
        type={buttonType}
      >
        {!icon && title}
      </Button>
    </Dropdown>
  )
}
