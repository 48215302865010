import { useState } from 'react'

import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'

interface Props {
  onClick: () => void | Promise<void>
  loading?: boolean
  tooltip?: string
  disabled?: boolean
}
export const DeleteButton = (props: Props) => {
  const { onClick, loading, tooltip, disabled } = props
  const [loadingState, setLoadingState] = useState(false)
  const button = (
    <Button
      icon={<FontAwesomeIcon icon={faTrash} />}
      type="default"
      size="small"
      disabled={disabled}
      // using onMouseDown instead of onClick to stop propagation from Plan Review <Page />
      onMouseDown={async (e) => {
        e.preventDefault()
        e.stopPropagation()
        setLoadingState(true)
        try {
          await onClick()
        } catch (error) {
          console.log(error)
        } finally {
          setLoadingState(false)
        }
      }}
      loading={loadingState || loading}
    />
  )

  if (tooltip) {
    return <Tooltip title={tooltip}>{button}</Tooltip>
  }
  return button
}
