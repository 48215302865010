import { MutableRefObject, createContext, useContext } from 'react'

import { RecordCellMenuKey } from 'src/pages/RecordPage/RecordCell/constants'

import { MenuState } from '../../../components/Menu/types'
import { SetState } from '../../../components/PlanReviewModal/PlanReviewContext'
import { UseDisclosureReturn } from '../../../hooks/use-disclosure'
import { RefetchFnType } from '../../../types'

export type RecordCellContextType = {
  orgSlug: string
  orgUuid: string
  detailsTabOverviewState: UseDisclosureReturn
  detailsTabFormSubmittalState: UseDisclosureReturn
  mailerIdempotancy: string
  setMailerIdempotancy: SetState<string>
  refetch: RefetchFnType
  isUpdatingApplication: boolean
  setIsUpdatingApplication: SetState<boolean>
  updateApplicationRef: MutableRefObject<HTMLDivElement | null>
  setUpdateApplicationRef: SetState<MutableRefObject<HTMLDivElement | null>>
  tabsMenuState: MenuState
  getIsMenuKeySelected: (key: RecordCellMenuKey) => boolean
}

export const RecordCellContext = createContext<RecordCellContextType>(
  {} as RecordCellContextType
)
export const useRecordCellContext = () => useContext(RecordCellContext)
