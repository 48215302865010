import { Tag, TagProps } from 'antd'

import { RecordTaskStatus } from '../../../types/graphql'
import { RecordTaskStatusTypeDisplayMap } from '../../constants'

interface Props {
  status: RecordTaskStatus
}
export const TaskStatusDisplay = (props: Props) => {
  const { status } = props

  const getColor = (): TagProps['color'] => {
    switch (status) {
      case 'Done':
        return 'green'
      case 'Future':
        return 'purple'
      case 'ToDo':
        return 'gold'
    }
  }

  return <Tag color={getColor()}>{RecordTaskStatusTypeDisplayMap[status]}</Tag>
}
