import { FontMapToken } from 'antd/es/theme/interface'

export const FontMap: Partial<FontMapToken> = {
  fontSizeSM: 12,
  fontSize: 14,
  fontSizeLG: 16,
  fontSizeXL: 20,
  fontSizeHeading1: 38,
  fontSizeHeading2: 30,
  fontSizeHeading3: 24,
  fontSizeHeading4: 20,
  fontSizeHeading5: 16,
  lineHeight: 1,
  lineHeightLG: 1.5,
  lineHeightSM: 1.66,
  lineHeightHeading1: 1.2,
  lineHeightHeading2: 1.26,
  lineHeightHeading3: 1.5,
  lineHeightHeading4: 1.4,
  lineHeightHeading5: 1.5,
}
