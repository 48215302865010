import { useToken } from 'antd/es/theme/internal'

import { ColorToken } from '../constants/theme/types'

export const useTheme = () => {
  const [_, tokens] = useToken()
  const getTokenVal = (token: ColorToken) => tokens[token]
  return {
    getTokenVal,
  }
}
