import { useEffect } from 'react'

import { KeyboardKey } from 'src/types'

export const useSelectKeyboardShortcuts = (args: {
  hasFocus: boolean
  isOpen: boolean
  onBackSpace?: () => void
  onClose: () => void
  onOpen: () => void
}) => {
  const { hasFocus, isOpen, onBackSpace, onClose, onOpen } = args
  useEffect(() => {
    if (!hasFocus) {
      return
    }
    const listener = (e: KeyboardEvent) => {
      switch (e.key) {
        case KeyboardKey.Enter:
        case KeyboardKey.ArrowDown:
        case KeyboardKey.ArrowUp:
          if (!isOpen) {
            onOpen()
            e.stopPropagation()
            e.preventDefault()
          }
          break
        case KeyboardKey.Backspace:
          onBackSpace?.()
          e.stopPropagation()
          break
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [hasFocus, isOpen, onBackSpace, onClose, onOpen])
}
