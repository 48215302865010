export const RecordEmailFileFragment = gql`
  fragment RecordEmailFileFragment on RecordEmailFile {
    id
    recordEmail {
      ...RecordEmailFragment
    }
    file {
      ...FileFragment
    }
  }
`
