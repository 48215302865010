import { useMemo } from 'react'

import { Flex, Skeleton } from 'antd'
import styled from 'styled-components'

import { BasicOptionType, SelectProps } from 'src/components/form/Select'
import SelectGroup, { OptionGroupType } from 'src/components/form/SelectGroup'
import { useCodeBooksQuery } from 'src/fetch/codeBooks'

const InputSkeleton = styled(Skeleton.Input)`
  height: 16px;
  .ant-skeleton-input {
    height: 16px;
    max-height: 16px;
  }
`

type Props = Omit<SelectProps<BasicOptionType<number>>, 'options'>
const CodeBookItemSelect = (props: Props) => {
  const { data, isLoading } = useCodeBooksQuery()
  const options: OptionGroupType<number>[] = useMemo(
    () =>
      data?.codeBooks?.flatMap((book) => ({
        label: book.name,
        options:
          book.items.flatMap((item) => ({
            label: item.formattedName,
            value: item.id,
          })) ?? [],
      })) ?? [],
    [data?.codeBooks]
  )

  return (
    <SelectGroup<number>
      noMargin
      options={options}
      loading={isLoading}
      {...props}
      placeholder={
        isLoading ? (
          <Flex vertical justify="center">
            <InputSkeleton active size="small" />
          </Flex>
        ) : (
          props.placeholder || 'Select code book item'
        )
      }
      fieldName={isLoading ? '' : props.fieldName}
    />
  )
}

export default React.memo(CodeBookItemSelect)
