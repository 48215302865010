import { useParams } from '@redwoodjs/router'

import {
  useMyOrg,
  useOrgSlugMetadata,
} from '../layouts/AppStateContextLayout/utils'
import { useRecordCellContext } from '../pages/RecordPage/RecordCell/Context'

export const useSlug = () => {
  const { slug } = useParams()
  const orgSlugMetadata = useOrgSlugMetadata()
  const myOrg = useMyOrg()
  const recordCellContext = useRecordCellContext()
  return (
    slug ||
    recordCellContext?.orgSlug ||
    orgSlugMetadata?.slug ||
    myOrg?.slug ||
    ''
  )
}
