import { ReactNode } from 'react'

import styled from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { StylePropsMobile } from '../shared/StyledComponents'
import { Logo, LogoColor } from '../svgs/Logo'

interface ContainerProps extends StylePropsMobile {
  $fullWidth: boolean
}
const StyledSidebarBase = styled.nav<ContainerProps>`
  width: ${(props) => (props.$fullWidth ? '100%' : 'var(--sidebar-width)')};
  min-width: ${(props) => (props.$fullWidth ? '100%' : 'var(--sidebar-width)')};
  background-color: ${(props) => props.theme.colorMenuDarkBg};
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: white;
  box-sizing: border-box;
  overflow-y: auto;
`

const StyledSidebarHeader = styled.div``

const StyledSidebarLogoContainer = styled.div`
  padding: 14px 21px;
`

const StyledSidebarContent = styled.div`
  overflow-y: auto;
  scrollbar-color: ${({ theme }) => theme.colorBgLayout} transparent;
  scrollbar-gutter: stable;
  flex: 1;
  margin-top: 6px;
  padding: 2px 2px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`
const StyledSidebarFooter = styled.div`
  background-color: ${({ theme }) => theme.colorMenuDarkBg};
  border-top: solid 1px rgba(255, 255, 255, 0.15);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 1px;
`

type Props = {
  fullWidth?: boolean
  children?: ReactNode
}
const SidebarBase = (props: Props) => {
  const { fullWidth, children } = props
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.Sidebar)
  return (
    <StyledSidebarBase $fullWidth={!!fullWidth} $small={small}>
      {children}
    </StyledSidebarBase>
  )
}

type SidebarHeaderProps = {
  children?: ReactNode
}
const SidebarHeader = ({ children }: SidebarHeaderProps) => {
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.Sidebar)
  return (
    <StyledSidebarHeader>
      {!small && (
        <StyledSidebarLogoContainer>
          <Logo color={LogoColor.White} />
        </StyledSidebarLogoContainer>
      )}
      {children}
    </StyledSidebarHeader>
  )
}

type SidebarContentProps = {
  children?: ReactNode
}
const SidebarContent = ({ children }: SidebarContentProps) => {
  return <StyledSidebarContent>{children}</StyledSidebarContent>
}

type SidebarFooterProps = {
  children?: ReactNode
}
const SidebarFooter = ({ children }: SidebarFooterProps) => {
  return <StyledSidebarFooter>{children}</StyledSidebarFooter>
}

export const SidebarPrimitive = Object.assign(SidebarBase, {
  Header: SidebarHeader,
  Content: SidebarContent,
  Footer: SidebarFooter,
})
