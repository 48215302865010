import { makeAutoObservable } from 'mobx'
import {
  ViewColumnFragment,
  ViewColumnTemplateFragment,
  ViewColumnType,
} from 'types/graphql'

export class TableViewColumn {
  public readonly columnTemplate: ViewColumnTemplateFragment
  public readonly columnType: ViewColumnType
  public displayIndex: number

  constructor(args: {
    displayIndex: number
    columnTemplate: ViewColumnTemplateFragment
    columnType: ViewColumnType
  }) {
    this.columnTemplate = args.columnTemplate
    this.columnType = args.columnType
    this.displayIndex = args.displayIndex
    makeAutoObservable(this)
  }

  get protocol(): ViewColumnFragment {
    return {
      columnType: this.columnType,
    }
  }
}
