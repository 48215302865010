import { ExternalLinkProps } from '../constants'

export const isURL = (str?: string): boolean => {
  return str?.includes('https://') || false
}

export const renderGISFieldValue = (val: string) => {
  if (!val) {
    return undefined
  }
  if (typeof val === 'string' && isURL(val)) {
    return (
      <a href={val} {...ExternalLinkProps}>
        <div style={{ color: 'rgba(8, 151, 156, 1)' }}>{val}</div>
      </a>
    )
  }
  return val
}
