import { useCallback, useContext, useMemo } from 'react'

import {
  IconDefinition,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { Link } from '@redwoodjs/router'

import Text from 'src/components/Typography/Text'
import { useTheme } from 'src/hooks/use-theme'

const StyledExpandIcon = styled(Text)`
  color: rgba(255, 255, 255, 0.5);
`
const StyledCollapseIcon = styled(Text)`
  color: rgba(255, 255, 255, 0.5);
`
const StyledDetails = styled.details`
  &[open] ${StyledExpandIcon} {
    display: none;
  }
  &:not([open]) ${StyledCollapseIcon} {
    display: none;
  }
`
type NavGroupProps = {
  isOpen?: boolean
  onToggleIsOpen?: () => void
  children: React.ReactNode
}
const NavGroup = ({ isOpen, onToggleIsOpen, children }: NavGroupProps) => {
  return (
    <StyledDetails open={isOpen}>
      <NavigationGroupContext.Provider value={{ isOpen, onToggleIsOpen }}>
        {children}
      </NavigationGroupContext.Provider>
    </StyledDetails>
  )
}

type NavItemStyleProps = {
  $isActive?: boolean
  $borderBottomLeftRadius?: React.CSSProperties['borderBottomLeftRadius']
  $borderTopLeftRadius?: React.CSSProperties['borderTopLeftRadius']
  $paddingLeft?: React.CSSProperties['paddingLeft']
  $pointerEvents?: React.CSSProperties['pointerEvents']
}
const SharedNavItemStyles = css<NavItemStyleProps>`
  position: relative;
  display: flex;
  gap: 12px;
  padding: 6px 8px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.$isActive ? props.theme.colorItemTextDisabled : 'transparent'};
  &:hover {
    background-color: ${({ theme }) => theme.colorItemTextDisabled};
  }
  &:focus {
    outline: solid 1px ${({ theme }) => theme.colorWhite};
  }

  ${({ $borderBottomLeftRadius }) =>
    $borderBottomLeftRadius
      ? `border-bottom-left-radius: ${$borderBottomLeftRadius};`
      : ''};
  ${({ $borderTopLeftRadius }) =>
    $borderTopLeftRadius
      ? `border-top-left-radius: ${$borderTopLeftRadius};`
      : ''};
  ${({ $paddingLeft }) =>
    $paddingLeft ? `padding-left: ${$paddingLeft};` : ''};
  ${({ $pointerEvents }) =>
    $pointerEvents ? `pointer-events: ${$pointerEvents};` : ''};
`
const StyledLink = styled(Link)<NavItemStyleProps>`
  ${SharedNavItemStyles}
`
const StyledDiv = styled.div<NavItemStyleProps>`
  ${SharedNavItemStyles}
`
type ClickHandlerProps =
  | {
      href: string
      onClick?: never
    }
  | {
      href?: never
      onClick: () => void
    }

type NavItemInternalProps = {
  id?: string
  isActive?: boolean
  isHeader?: boolean
  isSubItem?: boolean
  icon?: IconDefinition
  title: string
} & Partial<ClickHandlerProps>
const NavItemInternal = (props: NavItemInternalProps) => {
  const {
    href = '',
    id,
    icon,
    isActive = false,
    isHeader = false,
    isSubItem = false,
    onClick,
    title,
  } = props

  const { getTokenVal } = useTheme()

  const colorToken = 'colorWhite'

  const content = useMemo(
    () => (
      <>
        {icon && (
          <div>
            <FontAwesomeIcon
              style={{ height: 20, width: 20, color: getTokenVal(colorToken) }}
              icon={icon}
            />
          </div>
        )}
        <Text strong colorToken={colorToken} style={{ flex: 1 }}>
          {title}
        </Text>
        {isHeader && (
          <>
            <StyledExpandIcon>
              <FontAwesomeIcon icon={faChevronDown} />
            </StyledExpandIcon>
            <StyledCollapseIcon>
              <FontAwesomeIcon icon={faChevronUp} />
            </StyledCollapseIcon>
          </>
        )}
      </>
    ),
    [getTokenVal, icon, isHeader, title]
  )

  const $pointerEvents: React.CSSProperties['pointerEvents'] = isHeader
    ? 'none'
    : 'all'
  const commonContainerProps = {
    id,
    $isActive: isActive,
    $pointerEvents,
    ...(isSubItem
      ? {
          $borderBottomLeftRadius: '0px',
          $borderTopLeftRadius: '0px',
          $paddingLeft: '21px',
        }
      : {}),
  }

  if (onClick) {
    return (
      <StyledDiv {...commonContainerProps} onClick={onClick}>
        {content}
      </StyledDiv>
    )
  }

  return (
    <StyledLink
      {...commonContainerProps}
      to={href}
      href={href}
      tabIndex={isHeader ? -1 : 0}
    >
      {content}
    </StyledLink>
  )
}

type NavItemProps = {
  icon: IconDefinition
  id?: string
  isActive: boolean
  title: string
} & ClickHandlerProps
const NavItem = (props: NavItemProps) => {
  return <NavItemInternal {...props} />
}

const StyledSummary = styled.summary`
  cursor: pointer;
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }

  border-radius: 8px;
  margin-bottom: 1px;
  &:hover {
    background-color: ${({ theme }) => theme.colorItemTextDisabled};
  }
  &:focus {
    outline: solid 1px ${({ theme }) => theme.colorWhite};
  }
`
type NavGroupHeaderProps = {
  icon: IconDefinition
  id?: string
  title: string
}
const NavGroupHeader = (props: NavGroupHeaderProps) => {
  const { onToggleIsOpen } = useNavigationGroupContext()

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (onToggleIsOpen) {
        e.preventDefault()
        onToggleIsOpen()
      }
    },
    [onToggleIsOpen]
  )

  return (
    <StyledSummary onClick={handleClick}>
      <NavItemInternal {...props} isHeader />
    </StyledSummary>
  )
}

const StyledNavGroupList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin: 0 0 0 17px;
  padding: 0;
  border-left: solid 2px rgba(255, 255, 255, 0.5);
`
type NavGroupListProps = {
  children: React.ReactNode
}
const NavGroupList = ({ children }: NavGroupListProps) => {
  return <StyledNavGroupList>{children}</StyledNavGroupList>
}

const StyledNavGroupListItem = styled.li`
  list-style: none;
`
type NavGroupListItemProps = {
  id?: string
  isActive: boolean
  title: string
} & ClickHandlerProps
const NavGroupListItem = (props: NavGroupListItemProps) => {
  return (
    <StyledNavGroupListItem>
      <NavItemInternal {...props} isSubItem />
    </StyledNavGroupListItem>
  )
}

type NavigationGroupContextType = {
  isOpen?: boolean
  onToggleIsOpen?: () => void
}
const NavigationGroupContext = React.createContext<NavigationGroupContextType>(
  {} as NavigationGroupContextType
)
const useNavigationGroupContext = () => {
  return useContext(NavigationGroupContext)
}

export default {
  Group: NavGroup,
  GroupHeader: NavGroupHeader,
  Item: NavItem,
  GroupList: NavGroupList,
  GroupListItem: NavGroupListItem,
}
