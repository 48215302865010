import {
  CreateRecordTaskInspection,
  CreateRecordTaskInspectionVariables,
  UpdateRecordTaskInspectionMutation,
  UpdateRecordTaskInspectionMutationVariables,
} from 'types/graphql'

import { useMutation } from 'src/utils/queryClient'

const UpdateRecordTaskInspection = gql`
  mutation UpdateRecordTaskInspectionMutation(
    $id: Int!
    $input: UpdateRecordTaskInspectionInput!
  ) {
    updateRecordTaskInspection(id: $id, input: $input) {
      id
    }
  }
`

export const useUpdateRecordTaskInspectionMutation = () => {
  return useMutation<
    UpdateRecordTaskInspectionMutation,
    UpdateRecordTaskInspectionMutationVariables
  >({
    mutationDocument: UpdateRecordTaskInspection,
  })
}

const Create = gql`
  mutation CreateRecordTaskInspection(
    $input: CreateRecordTaskInspectionInput!
  ) {
    createRecordTaskInspection(input: $input) {
      id
    }
  }
`
export const useCreateRecordTaskInspection = () => {
  return useMutation<
    CreateRecordTaskInspection,
    CreateRecordTaskInspectionVariables
  >({
    mutationDocument: Create,
  })
}
