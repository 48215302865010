import 'leaflet/dist/leaflet.css'
import { useEffect, useRef, useState } from 'react'

import { centroid, polygon } from '@turf/turf'
import { Flex, Tag } from 'antd'
import {
  Marker as LeafletMarker,
  MapContainer,
  Polygon,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet'
import styled from 'styled-components'

import { LoadingSpin } from 'src/components/shared/StyledComponents'
import { useTheme } from 'src/hooks/use-theme'

import { Coordinate } from '../../../../types/graphql'

import EmptyMap from './empty-map.webp'
import { LeafletCoordinate, MapPin, MapProps, MapShape } from './types'
import {
  areCoordinatesEqual,
  getAllCoordinates,
  mapGWCoordinateToLeafletCoordinate,
} from './util'
export const MapHeightPx = 291

const Container = styled.div`
  position: relative;
  isolation: isolate;
  .leaflet-top,
  leaflet-right {
    right: 10px;
    left: auto;
    top: 0;
  }
`

const MapChild = (props: MapProps) => {
  const { onClick, center } = props
  const leafletCoordinates = getAllCoordinates(props)
  const map = useMapEvents({
    click: async (event) => {
      const {
        latlng: { lat, lng },
      } = event
      const coordinate: Coordinate = {
        latitude: lat,
        longitude: lng,
      }

      const popupPane = document.querySelector('.leaflet-popup-pane')
      const isPopupOpen = !!popupPane.innerHTML.trim()

      // Check if the div is empty
      if (!isPopupOpen) {
        await onClick?.(coordinate)
      }
    },
  })

  useEffect(() => {
    if (leafletCoordinates?.length) {
      map.fitBounds(leafletCoordinates, { maxZoom: 17 })
    } else if (center) {
      map.fitBounds([mapGWCoordinateToLeafletCoordinate(center)], {
        maxZoom: 17,
      })
    }
  }, [])
  return null
}

const getShapeCoordinates = (
  coordinates: Coordinate[]
): LeafletCoordinate[] => {
  const leafletCoordinates: LeafletCoordinate[] = coordinates.map(
    mapGWCoordinateToLeafletCoordinate
  )
  if (
    leafletCoordinates.length > 1 &&
    !areCoordinatesEqual(
      leafletCoordinates[0],
      leafletCoordinates[leafletCoordinates.length - 1]
    )
  ) {
    // need to close out the loop
    return [...leafletCoordinates, leafletCoordinates[0]]
  }
  return leafletCoordinates
}

const Marker = (props: MapPin) => {
  const { pinKey, coordinate, renderPopup, isHovered } = props
  const leafletCoordinate = mapGWCoordinateToLeafletCoordinate(coordinate)
  const leafletRef = useRef<any>()
  useEffect(() => {
    if (!leafletRef) {
      return
    }
    if (isHovered) {
      leafletRef?.current?.openPopup()
    } else {
      leafletRef?.current?.closePopup()
    }
  }, [isHovered])
  return (
    <LeafletMarker key={pinKey} position={leafletCoordinate} ref={leafletRef}>
      {renderPopup && <Popup>{renderPopup()}</Popup>}
    </LeafletMarker>
  )
}

const Shape = (props: MapShape) => {
  const { coordinates, showPin, shapeKey, renderPopup, isHovered } = props

  const leafletCoordinates = getShapeCoordinates(coordinates)

  const renderedPolygon = (
    <Polygon
      key={`${shapeKey}-polygon`}
      pathOptions={{
        color: 'rgba(10, 46, 87, 1)',
        fillColor: 'rgba(10, 46, 87, 1)',
        fillOpacity: 0.1,
      }}
      positions={leafletCoordinates}
    />
  )

  const center = centroid(polygon([leafletCoordinates]))
  const centerCoordinate: Coordinate = {
    latitude: center?.geometry?.coordinates?.[0],
    longitude: center?.geometry?.coordinates?.[1],
  }

  return (
    <>
      {renderedPolygon}
      {showPin ? (
        <Marker
          pinKey={`${shapeKey}-marker`}
          coordinate={centerCoordinate}
          renderPopup={renderPopup}
          isHovered={isHovered}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const StyledFlex = styled(Flex)`
  position: absolute;
  z-index: 400; // riddle me why the map z-index is 400?!
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 48px);
`

const StyledEmptyMapImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${EmptyMap}) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledNoResultsTag = styled(Tag)`
  padding: 18px;
  line-height: 0px;
`

export const Map = (props: MapProps) => {
  const { pins, isLoading } = props
  const allCoordinates = getAllCoordinates(props)
  const center = props.center
    ? mapGWCoordinateToLeafletCoordinate(props.center)
    : allCoordinates?.[0]

  const [shapes, setShapes] = useState(props.shapes)
  useEffect(() => {
    if (isLoading) {
      return // Show the previously loaded map until the new query is complete, otherwise it's a long period of showing a blank map
    }
    setShapes(props.shapes)
  }, [isLoading, props.shapes])
  const theme = useTheme()

  return (
    <Container>
      <MapContainer
        center={center}
        style={{
          width: '100%',
          height: `${MapHeightPx}px`,
          minHeight: `${MapHeightPx}px`,
          borderRadius: '8px',
          border: `solid 1px ${theme.getTokenVal('colorSplit')}`,
        }}
        key={JSON.stringify(shapes?.map((s) => s.coordinates))}
      >
        {!!shapes?.length && (
          <>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MapChild {...props} />

            {!!shapes?.length &&
              shapes.map((shape) => <Shape key={shape.shapeKey} {...shape} />)}
            {!!pins?.length &&
              pins.map((pin) => <Marker key={pin.pinKey} {...pin} />)}
          </>
        )}
      </MapContainer>
      {isLoading && (
        <StyledFlex>
          <LoadingSpin />
        </StyledFlex>
      )}
      {!isLoading && !shapes?.length && (
        <StyledEmptyMapImage>
          <StyledNoResultsTag>
            No results for the given query
          </StyledNoResultsTag>
        </StyledEmptyMapImage>
      )}
    </Container>
  )
}
