import { useEffect, useMemo, useState } from 'react'

import { faRefresh } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

import { useRouteName } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { useNotification } from 'src/components/shared/Notification/NotificationContext'
import { EnvVariables, Environments } from 'src/constants'

const TWENTY_SECONDS_IN_MS = 20000

export const useReleaseNotification = () => {
  const { isAuthenticated } = useAuth()
  const { notification } = useNotification()

  const [hasOpened, setHasOpened] = useState(false)

  const routeName = useRouteName()

  const isOnRecordForm = useMemo(() => {
    return (
      routeName === 'recordTemplateApply' || routeName === 'editRecordDraft'
    )
  }, [routeName])

  useEffect(() => {
    if (EnvVariables.Environment === Environments.Local) {
      return // disable in local to avoid console error spam
    }

    const timeout = setInterval(async () => {
      try {
        if (!isAuthenticated) {
          // Don't show the release notification banner to unauthenticated users
          return
        }

        if (hasOpened) {
          // Don't repeatedly bother the user with release notices if they dismiss the first one
          return
        }

        const response = await fetch(`${window.location.origin}/commit.txt`)
        if (response.status === 404) {
          // Don't show a false positive if we fail to fetch the file
          return
        }

        const latestReleaseSHA = await response.text()
        if (
          latestReleaseSHA !== EnvVariables.VercelDeploymentSha &&
          !isOnRecordForm
        ) {
          setHasOpened(true)
          notification.open({
            message: 'New Version Available',
            description:
              'A new version of GovWell is available. Click here to refresh the page for the latest updates.',
            btn: (
              <Button
                icon={<FontAwesomeIcon icon={faRefresh} />}
                type="primary"
                onClick={() => window.location.reload()}
              >
                Get updates
              </Button>
            ),
            duration: 0, // this means do not close automatically
          })
        }
      } catch (e) {
        console.error(e)
      }
    }, TWENTY_SECONDS_IN_MS)

    return () => clearTimeout(timeout)
  }, [hasOpened, isAuthenticated, isOnRecordForm, notification])
}
