import { useCallback } from 'react'

import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import { observer } from 'mobx-react-lite'

import { routes } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'

import {
  RecordTaskInspectionAttemptFragmentOverview,
  RecordTypeFragment,
  ViewDataRowFragment,
} from '../../../types/graphql'
import {
  InspectionsTableButtons,
  InspectionTableViewResultsType,
} from '../../components/InspectionsTableButtons'
import { ReassignMultipleInspectionsButton } from '../../components/Records/Inspections/ReassignMultipleInspectionsModal'
import ScheduleMultipleInspectionAttemptsModal from '../../components/Records/Inspections/ScheduleMultipleInspectionAttemptsModal'
import { PageTitle, TableLink } from '../../components/shared/StyledComponents'
import TableViewBuilder from '../../components/TableViewBuilder'
import useDisclosure from '../../hooks/use-disclosure'
import { RefetchFnType } from '../../types'

type Props = {
  recordType: RecordTypeFragment
}
const RecordsInspectionsContent = ({ recordType }: Props) => {
  const getAdditionalColumns = useCallback(
    (refetch: RefetchFnType) => {
      return [
        {
          key: 'buttons',
          title: '',
          width: '1px',
          render: (r: ViewDataRowFragment) => {
            const attempt = r.recordTaskInspectionAttempt
            if (!attempt) {
              return null
            }
            return (
              <InspectionsTableButtons
                attempt={attempt}
                recordTaskInspectionId={attempt.recordTaskInspection?.id ?? 0}
                isVisibleToApplicant={
                  attempt.recordTaskInspection?.visibleToApplicant ?? false
                }
                refetch={refetch}
                inspectionCoordinationType={
                  attempt.recordTaskInspection?.inspectionGroup?.record
                    ?.organization?.inspectionCoordinationType ||
                  'SchedulingRequest'
                }
                showViewResults={attempt.status === 'Done'}
                viewResultsType={InspectionTableViewResultsType.Attempt}
                isLatestAttempt={!!attempt.isLatestAttempt}
                inspectionCutoffTime={
                  attempt.recordTaskInspection?.inspectionGroup?.record
                    ?.organization?.inspectionCutoffTime
                }
                isLocked={
                  !!attempt.recordTaskInspection?.inspectionGroup
                    ?.recordTaskGroup?.isLocked
                }
                isAvailable
                addressEntryType={recordType?.addressEntryType}
              />
            )
          },
        },
      ]
    },
    [recordType?.addressEntryType]
  )
  const getLocationSnapshot = useCallback(
    (attempt: RecordTaskInspectionAttemptFragmentOverview) => {
      return attempt.recordTaskInspection?.inspectionGroup?.record?.addressField
        ?.locationSnapshot
    },
    []
  )
  const renderPopup = useCallback(
    (attempt: RecordTaskInspectionAttemptFragmentOverview) => {
      const record = attempt.recordTaskInspection?.inspectionGroup?.record
      if (!record) {
        return null
      }
      return (
        <TableLink href={routes.record({ recordUuid: record?.uuid })}>
          #{record?.issuedIdentifier || record?.identifier}
        </TableLink>
      )
    },
    []
  )
  const getDataFromRow = useCallback((row: ViewDataRowFragment) => {
    return row.recordTaskInspectionAttempt
  }, [])

  const getCheckboxProps = useCallback((row: ViewDataRowFragment) => {
    const attempt = row.recordTaskInspectionAttempt
    return {
      disabled: !attempt?.isLatestAttempt || !!attempt?.result?.isPassing,
    }
  }, [])

  const scheduleInspectionsModalState = useDisclosure()
  const renderRowSelection = useCallback(
    (rows: ViewDataRowFragment[], refetch: RefetchFnType) => {
      const recordTaskInspectionAttemptIds = rows.map((r) => r.id)
      return (
        <Flex gap="6px">
          <Button
            icon={<FontAwesomeIcon icon={faCalendar} />}
            onClick={scheduleInspectionsModalState.open}
          >
            Schedule
          </Button>
          <ScheduleMultipleInspectionAttemptsModal
            modalState={scheduleInspectionsModalState}
            recordTaskInspectionAttempts={rows.flatMap(
              (r) => r.recordTaskInspectionAttempt || []
            )}
            refetch={refetch}
          />
          <ReassignMultipleInspectionsButton
            recordTaskInspectionAttemptIds={recordTaskInspectionAttemptIds}
            refetch={refetch}
            size="middle"
          />
        </Flex>
      )
    },
    [scheduleInspectionsModalState]
  )

  return (
    <>
      <MetaTags title="Inspections" />
      <PageTitle title="Inspections" />
      <TableViewBuilder<RecordTaskInspectionAttemptFragmentOverview>
        recordTypeId={recordType.id}
        map={{ getLocationSnapshot, renderPopup }}
        viewType="RecordTaskInspectionAttemptsForStaffInModule"
        getAdditionalColumns={getAdditionalColumns}
        getDataFromRow={getDataFromRow}
        emptyStateMessage="No inspections found"
        renderRowSelection={renderRowSelection}
        getCheckboxProps={getCheckboxProps}
      />
    </>
  )
}
export default observer(RecordsInspectionsContent)
