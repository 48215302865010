import { ReactNode } from 'react'

import Title from 'antd/es/typography/Title'
import styled from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { StylePropsMobile } from '../shared/StyledComponents'

interface TopBarContainerProps {
  $color: string
}
export const TopBarContainer = styled.div<TopBarContainerProps>`
  width: 100%;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.$color};
  box-sizing: border-box;
  color: white;
`

export const ItemContainer = styled.div<StylePropsMobile>`
  flex: 2;
  display: flex;
  justify-content: ${(props) => (props.$small ? 'flex-start' : 'center')};
  margin: 0 auto;
`
const ImageContainer = styled.div`
  flex: 1;
  cursor: pointer;
`
const LoginButtonContainer = styled.div<StylePropsMobile>`
  ${(props) => !props.$small && 'flex: 1;'}
  ${(props) => props.$small && 'width: fit-content; margin-left: 12px;'}
  display: flex;
  justify-content: flex-end;
`
interface Props {
  onClickImage?: () => void
  image?: ReactNode
  title: ReactNode
  button?: ReactNode
  color: string
}

export const TopBarTemplate = (props: Props) => {
  const { onClickImage, image, title, button, color } = props
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.Topbar)
  return (
    <TopBarContainer $color={color}>
      {!small && (
        <ImageContainer onClick={onClickImage}>{image}</ImageContainer>
      )}
      <ItemContainer $small={small}>{title}</ItemContainer>
      <LoginButtonContainer $small={small}>{button}</LoginButtonContainer>
    </TopBarContainer>
  )
}

interface TopbarTitleProps {
  children: ReactNode
}
export const TopbarTitle = (props: TopbarTitleProps) => {
  const { children } = props
  const { isSmallerThanOrEqual } = useCurrentBreakpoint()
  const small = isSmallerThanOrEqual('sm')
  return (
    <Title
      level={small ? 4 : 3}
      type="secondary"
      style={{ margin: 0, textAlign: 'center', color: 'white' }}
    >
      {children}
    </Title>
  )
}

export default TopBarTemplate
