import { Button } from 'antd'
import styled from 'styled-components'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useAuth } from '../../auth'

export const ItemContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
`
export const TitleContainer = styled.div`
  color: white;
`

export const LoginButton = () => {
  const { logOut, isAuthenticated, loading } = useAuth()

  const { pathname } = useLocation()
  if (loading) {
    return null
  }

  const text = isAuthenticated ? 'Log out' : 'Sign in'
  const onClick = async () => {
    if (isAuthenticated) {
      await logOut()
    } else {
      navigate(routes.login(pathname ? { redirectTo: pathname } : {}))
    }
  }

  return (
    <>
      <Button onClick={onClick} type="default">
        {text}
      </Button>
    </>
  )
}

export default LoginButton
