export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ConfigConnection: [
      'ConfigConnectionOrganization',
      'ConfigConnectionRecordTemplate',
      'ConfigConnectionRecordType',
    ],
    FormulaOutput: ['FormulaOutputUser', 'FormulaOutputWorkflowTemplate'],
    ViewDataElement: [
      'ViewDataElementBoolean',
      'ViewDataElementDateTime',
      'ViewDataElementInspectionTemplate',
      'ViewDataElementRecord',
      'ViewDataElementRecordActionRequiredBy',
      'ViewDataElementRecordTaskGroupType',
      'ViewDataElementRecordTaskInspectionResult',
      'ViewDataElementRecordTaskInspectionStatus',
      'ViewDataElementRecordTaskStatus',
      'ViewDataElementRecordTaskType',
      'ViewDataElementRecordTemplate',
      'ViewDataElementRecordTypeStatus',
      'ViewDataElementString',
      'ViewDataElementUser',
      'ViewDataElementViolation',
      'ViewDataElementViolationStatus',
      'ViewDataElementViolationType',
    ],
  },
}
export default result
