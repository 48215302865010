import { useEffect, useState } from 'react'

import {
  faLink,
  faLocationDot,
  faPenNib,
  faSpinner,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex, Form, Tooltip } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import styled from 'styled-components'

import { OrgSlugs } from 'src/constants'

import {
  AddressEntryType,
  CreatedLocationSnapshotFragment,
  GISProperty,
  GISPropertySearchFragment,
  LocationSnapshot,
  OrganizationAddressEntryType,
} from '../../../../types/graphql'
import {
  useCreateLocationSnapshotMutation,
  useSearchGISMutation,
} from '../../../fetch/locations'
import { useSlug } from '../../../hooks/use-slug'
import { useTheme } from '../../../hooks/use-theme'
import { useIsGov } from '../../../layouts/AppStateContextLayout/utils'
import { formatAddress } from '../../../utils'
import { renderGISFieldValue } from '../../../utils/gis'
import { ColumnData, FieldValueTable } from '../../shared/FieldValueTable'
import { getContainerStyleProps } from '../../shared/StyledComponents'
import Text, { TextSize } from '../../Typography/Text'
import { AutoComplete, AutocompleteFieldValues } from '../AutoComplete'
import Input from '../Input'
import { SingleOrMultipleInputProps } from '../types'
import {
  makeHiddenFieldName as makeHiddenFieldNameFn,
  makeManualAddressFieldName,
  makeSelectedIdFieldName,
} from '../util'
import { FormFieldWrapperProps } from '../Wrapper'

import { useShowPropertyOwnerOnAddressAutocomplete } from './util'

export interface AddressAutocompleteProps
  extends FormFieldWrapperProps,
    SingleOrMultipleInputProps {
  placeholder?: string
  disabled?: boolean
  disabledAddressDisplayNames?: string[]
  addressEntryType: OrganizationAddressEntryType | AddressEntryType
  size?: SizeType
}

const Container = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  flex-direction: column;
`

const ConnectedToGisContainer = styled.div`
  ${(props) => getContainerStyleProps(props.theme)}
  padding: 12px;
  .ant-table-thead {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const formatGISProperty = (input: {
  isGov: boolean
  orgSlug: string
  data: GISProperty
}) => {
  const { isGov, orgSlug, data } = input

  const formattedAddr = formatAddress(data?.address ?? '')
  if (data.parcelId && (isGov || orgSlug === OrgSlugs.Champlain)) {
    return `${formattedAddr || 'No Address'} (Parcel ID: ${data.parcelId})`
  }
  return formattedAddr
}

export interface AddressAutocompleteOption {
  value: string
  label: string
  id: string
  address: string
  disabled?: boolean
}

export enum AddressAutocompleteEnterType {
  Autocomplete = 'Autocomplet',
  Manual = 'Manual',
}

export interface AddressAutocompleteFieldValues
  extends AutocompleteFieldValues<GISPropertySearchFragment> {
  locationSnapshotId?: number
  locationSnapshot?: CreatedLocationSnapshotFragment
  manualAddress?: string
  loading?: boolean
  isMainAddress?: boolean
}

export const AddressAutocompleteInternal = (
  props: AddressAutocompleteProps
) => {
  const {
    placeholder,
    disabledAddressDisplayNames,
    index,
    addressEntryType,
    size,
    ...formFieldWrapperProps
  } = props
  const { fieldName } = formFieldWrapperProps

  const { mutateAsync: searchGIS } = useSearchGISMutation()

  const { mutateAsync: createSnapshot } = useCreateLocationSnapshotMutation()

  const showPropertyOwnerOnAddressAutocomplete =
    useShowPropertyOwnerOnAddressAutocomplete()

  const disabledAddressDisplayNamesSet = new Set(disabledAddressDisplayNames)
  const form = Form.useFormInstance()

  const selectedIdFieldName = makeSelectedIdFieldName(fieldName, index)

  const makeHiddenFieldName = (str: string) => {
    return makeHiddenFieldNameFn(fieldName, index, str)
  }

  Form.useWatch([fieldName], form)
  const { setFieldValue, getFieldValue } = form

  const manualAddressFieldName = makeManualAddressFieldName(fieldName, index)
  const locationSnapshotIdFieldName = makeHiddenFieldName('locationSnapshotId')
  const locationSnapshotFieldName = makeHiddenFieldName('locationSnapshot')
  const loadingFieldName = makeHiddenFieldName('loading')

  Form.useWatch(manualAddressFieldName)
  Form.useWatch(locationSnapshotIdFieldName)
  Form.useWatch(locationSnapshotFieldName)
  Form.useWatch(loadingFieldName)
  Form.useWatch(selectedIdFieldName)

  const manualAddress = getFieldValue(manualAddressFieldName)

  const getInitialEnterType = () => {
    if (addressEntryType === 'ManualOnly') {
      return AddressAutocompleteEnterType.Manual
    } else if (addressEntryType === 'SearchOnly') {
      return AddressAutocompleteEnterType.Autocomplete
    } else {
      return manualAddress
        ? AddressAutocompleteEnterType.Manual
        : AddressAutocompleteEnterType.Autocomplete
    }
  }

  const [enterType, setEnterType] = useState<AddressAutocompleteEnterType>(
    getInitialEnterType()
  )

  const toggleEnterType = () => {
    if (enterType === AddressAutocompleteEnterType.Autocomplete) {
      setFieldValue(manualAddressFieldName, '')
      setEnterType(AddressAutocompleteEnterType.Manual)
    } else {
      setEnterType(AddressAutocompleteEnterType.Autocomplete)
    }
  }

  const { getTokenVal } = useTheme()

  const orgSlug = useSlug()
  const isGov = useIsGov()
  const search = async (query: string) => {
    const res = await searchGIS({ input: { query, orgSlug } })
    const results = res.searchGIS.results
    return results
  }
  const mapDataToOption = (data: GISProperty) => {
    const formattedAddress = formatGISProperty({ data, orgSlug, isGov })
    return {
      value: data.id ?? '',
      label: formattedAddress,
      id: data.id ?? '',
      address: formattedAddress,
      disabled: disabledAddressDisplayNamesSet.has(data.address || ''),
    }
  }

  const selectedId = form.getFieldValue(selectedIdFieldName)

  const handleChange = async (id: string) => {
    if (enterType !== AddressAutocompleteEnterType.Autocomplete || !id) {
      return
    }
    try {
      setFieldValue(loadingFieldName, true)
      const { createLocationSnapshot } = await createSnapshot({
        input: { locationObjectId: id, orgSlug },
      })
      setFieldValue(locationSnapshotFieldName, createLocationSnapshot)
      setFieldValue(locationSnapshotIdFieldName, createLocationSnapshot.id)
    } catch (e) {
      console.log(e)
    } finally {
      setFieldValue(loadingFieldName, false)
    }
  }

  const locationSnapshot = getFieldValue(locationSnapshotFieldName) as
    | LocationSnapshot
    | undefined
  const loading = getFieldValue(loadingFieldName)

  useEffect(() => {
    if (selectedId && locationSnapshot?.locationObjectId === selectedId) {
      form.setFields([
        {
          name: selectedIdFieldName,
          errors: [],
        },
      ])
    }
  }, [selectedId, locationSnapshot?.locationObjectId])

  const validateLocationSnapshotId = async () => {
    if (loading) {
      return Promise.reject('Loading GIS information')
    }
    return Promise.resolve()
  }

  const getCaption = () => {
    const baseTextManual = (
      <Text size={TextSize.Small} inline>
        Type in an address.
      </Text>
    )
    const baseTextSearch = (
      <Text size={TextSize.Small} inline>
        Type in an address and click on the result.
      </Text>
    )
    const propertyOwner = showPropertyOwnerOnAddressAutocomplete &&
      !!locationSnapshot?.owner && (
        <Text inline>Property Owner: {locationSnapshot.owner}</Text>
      )

    switch (addressEntryType) {
      case 'ManualOnly': {
        return baseTextManual
      }
      case 'SearchOnly': {
        return (
          <Flex vertical gap="4px">
            <Text size={TextSize.Small} inline>
              {baseTextSearch}
            </Text>
            {propertyOwner}
          </Flex>
        )
      }
      case 'SearchAndManual': {
        return (
          <Flex vertical gap="4px">
            <Text size={TextSize.Small} inline>
              {enterType === AddressAutocompleteEnterType.Autocomplete
                ? baseTextSearch
                : baseTextManual}{' '}
              <Text
                size={TextSize.Small}
                inline
                colorToken="cyan-7"
                pointer
                strong
                onClick={toggleEnterType}
              >
                {enterType === AddressAutocompleteEnterType.Autocomplete
                  ? 'Address not found? Enter address manually.'
                  : 'Search addresses'}
              </Text>
            </Text>
            {propertyOwner}
          </Flex>
        )
      }
    }
  }
  if (enterType === AddressAutocompleteEnterType.Autocomplete) {
    const dataSource: ColumnData[] = (locationSnapshot?.fields || [])?.map(
      (f) => ({
        fieldName: f.key,
        key: f.key,
        fieldValue: renderGISFieldValue(f.value),
      })
    )

    const getLabel = () => {
      if (
        locationSnapshot &&
        locationSnapshot?.locationObjectId ===
          // the selectedId set in Autocomplete does not update in the Form.List context for some reason
          getFieldValue(makeHiddenFieldName('selectedId'))
      ) {
        return (
          <Tooltip
            title={
              locationSnapshot && (
                <ConnectedToGisContainer>
                  <Text size={TextSize.Large}>
                    This information was imported from a GIS database that is
                    integrated with GovWell and will be attached to your
                    submittal.
                  </Text>
                  <FieldValueTable dataSource={dataSource} />
                </ConnectedToGisContainer>
              )
            }
            overlayStyle={{
              maxWidth: 'unset',
              width: 'fit-content',
              backgroundColor: 'white',
            }}
            overlayInnerStyle={{
              backgroundColor: getTokenVal('white'),
              padding: 0,
            }}
          >
            {baseLabel}{' '}
            <FontAwesomeIcon
              icon={faLink}
              style={{
                color: getTokenVal('colorSuccessBase'),
              }}
            />
          </Tooltip>
        )
      }
      if (loading) {
        return (
          <>
            {baseLabel}{' '}
            <FontAwesomeIcon
              icon={faSpinner}
              style={{
                color: getTokenVal('colorPrimaryBase'),
                animation: 'spin 2s linear infinite',
                marginLeft: '4px',
              }}
            />
          </>
        )
      }
      return baseLabel
    }

    const { label: baseLabel, ...rest } = formFieldWrapperProps

    return (
      <Container>
        <AutoComplete<GISProperty, AddressAutocompleteOption>
          {...rest}
          index={index}
          size={size}
          rules={[
            {
              validator: validateLocationSnapshotId,
              validateTrigger: ['onSubmit'],
            },
            ...(rest.required
              ? [{ required: rest.required, message: rest.errorMessage }]
              : []),
          ]}
          label={getLabel()}
          placeholder={placeholder}
          search={search}
          optionLabelProp="address"
          mapDataToOption={mapDataToOption}
          caption={getCaption()}
          suffixIcon={
            enterType === AddressAutocompleteEnterType.Autocomplete
              ? faLocationDot
              : faPenNib
          }
          onChange={handleChange}
        />
        <Form.Item name={locationSnapshotIdFieldName} hidden>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name={locationSnapshotFieldName} hidden>
          <input type="hidden" />
        </Form.Item>
        <Form.Item name={loadingFieldName} hidden>
          <input type="hidden" />
        </Form.Item>
      </Container>
    )
  }
  return (
    <Input
      placeholder={placeholder}
      suffixIcon={faPenNib}
      caption={getCaption()}
      {...formFieldWrapperProps}
      fieldName={manualAddressFieldName}
    />
  )
}
