import { useCallback } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { ViewColumnTemplateFragment, ViewColumnType } from 'types/graphql'

import Text from 'src/components/Typography/Text'

const IconOnlyButton = styled(Button)<{ $isVisible: boolean }>`
  border: none;
  ${({ $isVisible, theme }) => !$isVisible && `color: ${theme.colorIcon}`};
`

type Props = {
  columnTemplate: ViewColumnTemplateFragment
  isVisible: boolean
  onColumnTypeToggled: (value: ViewColumnType) => Promise<unknown>
}
const ManageColumnsDrawerItem = ({
  columnTemplate,
  isVisible,
  onColumnTypeToggled,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: columnTemplate.columnType })

  const handleColumnTypeToggled = useCallback(async () => {
    await runInAction(async () => {
      await onColumnTypeToggled(columnTemplate.columnType)
    })
  }, [columnTemplate.columnType, onColumnTypeToggled])

  return (
    <Flex
      align="center"
      justify="space-between"
      gap="12px"
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <Flex align="center" gap="3px">
        <div
          {...attributes}
          {...listeners}
          style={{ cursor: 'grab', padding: '8px' }}
        >
          <FontAwesomeIcon icon={faGripDotsVertical} />
        </div>
        <Text>{columnTemplate.defaultLabelSentenceCase}</Text>
      </Flex>
      <IconOnlyButton
        icon={<FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash} />}
        $isVisible={isVisible}
        onClick={handleColumnTypeToggled}
      />
    </Flex>
  )
}

export default observer(ManageColumnsDrawerItem)
