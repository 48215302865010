import { Tag, TagProps } from 'antd'
import type { ViolationStatus } from 'types/graphql'

import { ViolationStatusToDisplayMap } from '../../constants'

interface Props {
  status: ViolationStatus
}

const ViolationStatusToTagColorMap: Record<ViolationStatus, TagProps['color']> =
  {
    Submitted: 'blue',
    InProgress: 'yellow',
    Ticketed: 'orange',
    Closed: 'green',
    InViolation: 'red',
    NotInViolation: 'green',
    Abatement: 'orange',
    CivilHearingNotice: 'orange',
    Prosecution: 'red',
    Archived: 'purple',
  }

export const ViolationDisplay = (props: Props) => {
  const { status } = props
  return (
    <Tag color={ViolationStatusToTagColorMap[status]}>
      {ViolationStatusToDisplayMap[status]}
    </Tag>
  )
}
