import { Progress as AntdProgress } from 'antd'
import { styled } from 'styled-components'

import { useTheme } from '../../hooks/use-theme'
import { formatFileProgress } from '../../utils/file'

interface Props {
  progress: number
}

const ProgressContainerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Progress = (props: Props) => {
  const { progress } = props
  const { getTokenVal } = useTheme()

  return (
    <ProgressContainerContainer>
      <AntdProgress
        percent={formatFileProgress(progress)}
        strokeColor={getTokenVal('colorPrimaryBase')}
      />
    </ProgressContainerContainer>
  )
}
