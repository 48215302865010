import { faFileArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

import { ListEnding, formatListSentence } from 'src/utils'

import { BreakpointComponents } from '../../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../../hooks/use-current-breakpoint'
import { useTheme } from '../../../hooks/use-theme'
import Text, { TextSize } from '../../Typography/Text'

interface Props {
  disabled?: boolean
  accept?: string
}

export const Default = (props: Props) => {
  const { disabled, accept } = props
  const { getTokenVal } = useTheme()

  const supportedFileTypes = formatListSentence({
    words: accept?.replaceAll('.', '')?.toUpperCase()?.split(',') || [],
    hideFinalPeriod: true,
    finalItemDelimiter: ListEnding.OR,
  })

  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)

  return (
    <>
      <FontAwesomeIcon
        icon={faFileArrowUp}
        style={{
          height: 32,
          width: 24,
          color: getTokenVal(
            disabled ? 'colorPrimaryBgHover' : 'colorPrimaryBase'
          ),
        }}
      />
      <div>
        <Button type="text" size="small" disabled={disabled}>
          Click to Upload
        </Button>
        {!small && (
          <Text inline size={TextSize.Small} colorToken="colorTextTertiary">
            or drag and drop
          </Text>
        )}
      </div>
      {!small && (
        <Text size={TextSize.Small} colorToken="colorTextTertiary">
          {supportedFileTypes}
        </Text>
      )}
    </>
  )
}
