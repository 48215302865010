import { makeAutoObservable } from 'mobx'
import {
  ViewColumnDataType,
  ViewColumnTemplateFragment,
  ViewColumnType,
  ViewFilterOptionFragment,
} from 'types/graphql'

export class TableColumnTemplate {
  public readonly columnType: ViewColumnType
  public readonly dataType: ViewColumnDataType
  public readonly defaultLabelSentenceCase: string
  public readonly defaultLabelTitleCase: string
  public readonly filterOptions: ViewFilterOptionFragment[]
  public readonly includeByDefault: boolean
  public readonly isFilterable: boolean
  public readonly isSortable: boolean
  public readonly isNullable: boolean

  constructor(args: {
    columnType: ViewColumnType
    dataType: ViewColumnDataType
    defaultLabelSentenceCase: string
    defaultLabelTitleCase: string
    filterOptions: ViewFilterOptionFragment[] | undefined
    includeByDefault: boolean
    isFilterable: boolean
    isSortable: boolean
    isNullable: boolean
  }) {
    this.columnType = args.columnType
    this.dataType = args.dataType
    this.defaultLabelSentenceCase = args.defaultLabelSentenceCase
    this.defaultLabelTitleCase = args.defaultLabelTitleCase
    this.filterOptions = args.filterOptions ?? []
    this.includeByDefault = args.includeByDefault
    this.isFilterable = args.isFilterable
    this.isSortable = args.isSortable
    this.isNullable = args.isNullable
    makeAutoObservable(this)
  }

  static fromProtocol(protocol: ViewColumnTemplateFragment) {
    return new this({
      columnType: protocol.columnType,
      dataType: protocol.dataType,
      defaultLabelSentenceCase: protocol.defaultLabelSentenceCase,
      defaultLabelTitleCase: protocol.defaultLabelTitleCase,
      filterOptions: protocol.filterOptions ?? [],
      includeByDefault: protocol.includeByDefault,
      isFilterable: protocol.isFilterable,
      isSortable: protocol.isSortable,
      isNullable: !!protocol.isNullable,
    })
  }
}
