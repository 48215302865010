export const RelatedRecordFragment = gql`
  fragment RelatedRecordFragment on Record {
    id
    uuid
    identifier
    issuedIdentifier
    isRenewal
    expirationDate
    formattedName
    status {
      ...RecordTypeStatusFragment
    }
    isDraft
    recordTemplate {
      name
    }
    parentRecord {
      id
    }
    rootRecord {
      id
    }
  }
`
