// @ts-nocheck
import { useEffect } from 'react'

import {
  OrganizationFragmentWithUsers,
  UserDataFragment,
} from '../../types/graphql'
import { EnvVariables } from '../constants'

import { useSlug } from './use-slug'

type Params = {
  me: UserDataFragment | null | undefined
  organization: OrganizationFragmentWithUsers | null | undefined
}

export const useSetUpIntercom = ({ me, organization }: Params) => {
  const stringifiedMe = me ? JSON.stringify(me) : ''
  const slug = useSlug()
  useEffect(() => {
    window.intercomSettings = {
      api_base: EnvVariables.IntercomApiBase,
      app_id: EnvVariables.IntercomAppId,
      ...(me
        ? {
            name: `${me.firstName} ${me.lastName}`, // Full name
            user_id: me.id, // a UUID for your user
            email: me.emailAddress, // the email for your user
            created_at: me.createdAt, // Signup date as a Unix timestamp
            IsGovStaff: me.organizationId ? 'True' : 'False',
            GovStaffGroup: me.group || null,
            GovStaffCity: organization?.city ?? '',
            Title: me.title ?? '',
            IsPlanReviewer: me.isPlanReviewer ? 'True' : 'False',
            IsInspector: me.isInspector ? 'True' : 'False',
            IsApplicant: !me?.organizationId ? 'True' : 'False',
          }
        : {}),
    }
    const w = window
    const ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i = function (...args) {
        i.c(args)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/gr76ir1h'
        const x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  }, [me, organization?.city, slug, stringifiedMe])
}
