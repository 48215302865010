import {
  LogInspectionModalQuery,
  LogInspectionModalQueryVariables,
} from 'types/graphql'

import { QueryKey, useQuery } from 'src/utils/queryClient'

const LogInspectionModal = gql`
  query LogInspectionModalQuery($recordTaskInspectionId: Int!) {
    inspection: recordTaskInspection(id: $recordTaskInspectionId) {
      id
      inspectionTemplate {
        fieldGroup {
          ...FieldGroupFragment
        }
      }
      latestAttempt {
        ...ViewRecordTaskInspectionAttemptFragment
      }
    }
  }
`

export const useLogInspectionModalQuery = (recordTaskInspectionId: number) => {
  return useQuery<LogInspectionModalQuery, LogInspectionModalQueryVariables>({
    queryKey: [QueryKey.LogInspectionModal, recordTaskInspectionId],
    queryDocument: LogInspectionModal,
    variables: {
      recordTaskInspectionId,
    },
    gcTime: 0,
    staleTime: 0,
  })
}
