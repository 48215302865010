import { ReactNode, forwardRef } from 'react'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import { Flex } from 'antd'
import {
  MenuContentStyles,
  MenuItemStyleProps,
  MenuItemStyles,
} from 'govwell-ui/components/Menu/Menu'
import styled from 'styled-components'

type ContextMenuBaseProps = {
  children?: ReactNode
}
const ContextMenuBase = ({ children }: ContextMenuBaseProps) => {
  return <ContextMenuPrimitive.Root>{children}</ContextMenuPrimitive.Root>
}

type ContextMenuTriggerProps = {
  children?: ReactNode
}
const ContextMenuTrigger = ({ children }: ContextMenuTriggerProps) => {
  return (
    <ContextMenuPrimitive.Trigger asChild>
      {children}
    </ContextMenuPrimitive.Trigger>
  )
}

const StyledContent = styled(ContextMenuPrimitive.Content)`
  ${MenuContentStyles}
`
type ContextMenuContentProps = {
  children?: ReactNode
}
const ContextMenuContent = forwardRef<HTMLDivElement, ContextMenuContentProps>(
  ({ children }: ContextMenuContentProps, ref) => {
    return <StyledContent ref={ref}>{children}</StyledContent>
  }
)

const StyledItem = styled(ContextMenuPrimitive.Item)<MenuItemStyleProps>`
  ${MenuItemStyles}
`
type ContextMenuItemProps = Pick<
  ContextMenuPrimitive.ContextMenuItemProps,
  'onMouseDown' | 'onClick' | 'onSelect'
> & {
  icon?: IconDefinition
  children?: ReactNode
}
const ContextMenuItem = forwardRef<HTMLDivElement, ContextMenuItemProps>(
  (
    { onMouseDown, onClick, onSelect, icon, children }: ContextMenuItemProps,
    ref
  ) => {
    return (
      <StyledItem
        onMouseDown={onMouseDown}
        onClick={onClick}
        onSelect={onSelect}
        ref={ref}
      >
        <Flex align="center" gap="12px">
          {icon && <FontAwesomeIcon icon={icon} />}
          {children}
        </Flex>
      </StyledItem>
    )
  }
)

const ContextMenu = Object.assign(ContextMenuBase, {
  Content: ContextMenuContent,
  Item: ContextMenuItem,
  Portal: ContextMenuPrimitive.Portal,
  Trigger: ContextMenuTrigger,
})

export default ContextMenu
