import {
  HTMLProps,
  ReactNode,
  SetStateAction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { Button, Flex } from 'antd'
import { Menu } from 'govwell-ui'
import styled, { css } from 'styled-components'

import Portal from 'src/components/shared/Portal'
import { TextSize, getFontSize } from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'

const TAB_CONTAINER_KEY_ATTR = 'data-govwell-tab-container-key'
const TAB_KEY_ATTR = 'data-govwell-tab-key'

export enum TabReorderReason {
  DragAndDrop = 'DragAndDrop',
  SelectFromMoreMenu = 'SelectFromMoreMenu',
}

type TabsBaseProps = {
  selectedTabKey: string
  onSelectedKeyChange: (tabKey: string) => void
  onReorderTabs?: (tabKeys: string[], reason: TabReorderReason) => void
  children?: React.ReactNode
}
const TabsBase = ({
  children,
  selectedTabKey,
  onReorderTabs,
  onSelectedKeyChange,
}: TabsBaseProps) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const tabListContainerRef = useRef<HTMLDivElement>(null)
  const tabListRef = useRef<HTMLDivElement>(null)
  const [tabContentsByTabKey, setTabContentsByTabKey] = useState<
    Map<string, ReactNode>
  >(new Map<string, ReactNode>())
  const [tabKeys, setTabKeys] = useState<Set<string>>(new Set<string>())
  const [visibleTabKeys, setVisibleTabKeys] = useState<Set<string>>(
    new Set<string>()
  )

  const getTabContainerElement = useCallback(
    (tabKey: string): Element | null => {
      return rootRef.current
        ? rootRef.current.querySelector(
            `[${TAB_CONTAINER_KEY_ATTR}="${tabKey}"]`
          )
        : null
    },
    []
  )
  const getTabContainerElements = useCallback(
    (): Element[] =>
      rootRef.current
        ? Array.from(
            rootRef.current
              .querySelectorAll(`[${TAB_CONTAINER_KEY_ATTR}]`)
              .values()
          )
        : [],
    []
  )
  const getTabElement = useCallback((tabKey: string): Element | null => {
    return rootRef.current
      ? rootRef.current.querySelector(`[${TAB_KEY_ATTR}="${tabKey}"]`)
      : null
  }, [])
  const getTabElements = useCallback(
    (): Element[] =>
      rootRef.current
        ? Array.from(
            rootRef.current.querySelectorAll(`[${TAB_KEY_ATTR}]`).values()
          )
        : [],
    []
  )

  const selectTab = useCallback(
    (newlySelectedTabKey: string) => {
      // Get all the relevant elements, bail out if the DOM queries fail
      const currentlySelectedTabContainer =
        getTabContainerElement(selectedTabKey)
      const currentlySelectedTab = getTabElement(selectedTabKey)
      const newlySelectedTab = getTabElement(newlySelectedTabKey)
      if (
        !tabListRef.current ||
        !newlySelectedTab ||
        !currentlySelectedTab ||
        !currentlySelectedTabContainer
      ) {
        return
      }

      // Get the total width of the tab container minus the total width of all of its tab children
      const visibleTabWidth = Array.from(visibleTabKeys.values())
        .map((tabKey) => getTabContainerElement(tabKey))
        .reduce(
          (total, tabElement) => total + (tabElement?.clientWidth ?? 0),
          0
        )
      const listWidth = tabListRef.current.clientWidth
      let availableWidth = listWidth - visibleTabWidth

      const tabs = getTabElements()
      const orderedVisibleTabs = tabs.filter((el) =>
        visibleTabKeys.has(el.getAttribute(TAB_KEY_ATTR))
      )
      const orderedInvisibleTabs = tabs.filter((el) => {
        const k = el.getAttribute(TAB_KEY_ATTR)
        return k !== newlySelectedTabKey && !visibleTabKeys.has(k)
      })

      // Displace tabs as necessary until the newly selected tab can fit in the available space
      const tabKeysToDisplace: string[] = []
      const newlySelectedTabWidth = newlySelectedTab.clientWidth
      while (availableWidth < newlySelectedTabWidth) {
        if (orderedVisibleTabs.length === 0) {
          break
        }
        const tabToDisplace = orderedVisibleTabs.pop()
        const tabKeyToDisplace = tabToDisplace.getAttribute(TAB_KEY_ATTR)
        tabKeysToDisplace.unshift(tabKeyToDisplace)
        availableWidth += tabToDisplace.clientWidth
      }

      // Update the tab order and selected tab
      const orderedVisibleTabKeys = orderedVisibleTabs.map((el) =>
        el.getAttribute(TAB_KEY_ATTR)
      )
      const orderedInvisibleTabKeys = orderedInvisibleTabs.map((el) =>
        el.getAttribute(TAB_KEY_ATTR)
      )
      const visibleKeys = [...orderedVisibleTabKeys, newlySelectedTabKey]
      const invisibleKeys = [...tabKeysToDisplace, ...orderedInvisibleTabKeys]
      const newTabOrder = [...visibleKeys, ...invisibleKeys]
      setVisibleTabKeys(
        new Set([...orderedVisibleTabKeys, newlySelectedTabKey])
      )
      onReorderTabs(newTabOrder, TabReorderReason.SelectFromMoreMenu)
      onSelectedKeyChange(newlySelectedTabKey)
    },
    [
      getTabContainerElement,
      selectedTabKey,
      getTabElement,
      visibleTabKeys,
      getTabElements,
      onReorderTabs,
      onSelectedKeyChange,
    ]
  )

  return (
    <TabsContext.Provider
      value={{
        getTabContainerElement,
        getTabContainerElements,
        getTabElement,
        getTabElements,
        rootRef,
        selectedTabKey,
        selectTab,
        setTabContentsByTabKey,
        setTabKeys,
        setVisibleTabKeys,
        tabContentsByTabKey,
        tabListContainerRef,
        tabKeys,
        tabListRef,
        visibleTabKeys,
      }}
    >
      <TabsPrimitive.Root
        ref={rootRef}
        value={selectedTabKey}
        onValueChange={onSelectedKeyChange}
        activationMode="manual"
      >
        {children}
      </TabsPrimitive.Root>
    </TabsContext.Provider>
  )
}

const StyledTabListContainer = styled(Flex)`
  align-items: center;
  position: relative;
  max-width: 100%;
  gap: 6px;

  &::before {
    content: '';
    position: absolute;
    inset: 0px;
    border-bottom: solid 1px ${({ theme }) => theme.colorSplit};
  }
`
const StyledTabList = styled(TabsPrimitive.List)`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  height: 46px;
`
const StyledMoreMenuButton = styled(Button)`
  color: ${({ theme }) => theme.colorText};
`
type TabsListProps = {
  children?: React.ReactNode
}
const TabsList = ({ children }: TabsListProps) => {
  const {
    getTabContainerElements,
    rootRef,
    tabListContainerRef,
    tabListRef,
    selectedTabKey,
    selectTab,
    setVisibleTabKeys,
    setTabKeys,
    visibleTabKeys,
  } = useContext(TabsContext)

  const tabContainerElements = getTabContainerElements()

  // Create a stable dependency that resets the observer when tabs are added or removed
  const tabKeySequence = tabContainerElements
    .map((el) => el.getAttribute(TAB_CONTAINER_KEY_ATTR))
    .sort()
    .join(',')

  useEffect(() => {
    if (!rootRef.current) {
      return
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          const tabKey = e.target.getAttribute(TAB_CONTAINER_KEY_ATTR)
          setVisibleTabKeys((prevState: Set<string>) => {
            const newState = new Set<string>(prevState)
            if (e.intersectionRatio === 1) {
              newState.add(tabKey)
            } else {
              newState.delete(tabKey)
            }
            return newState
          })
        })
      },
      {
        root: rootRef.current,
        threshold: 1,
      }
    )
    const containerElements = getTabContainerElements()
    containerElements.forEach((el) => {
      observer.observe(el)
    })
    setTabKeys(
      new Set(
        containerElements.map((el) => el.getAttribute(TAB_CONTAINER_KEY_ATTR))
      )
    )
    return () => observer.disconnect()
  }, [
    getTabContainerElements,
    rootRef,
    setTabKeys,
    setVisibleTabKeys,
    tabKeySequence,
  ])

  const isSelectedTabKeyVisible = visibleTabKeys.has(selectedTabKey)
  useEffect(() => {
    if (!isSelectedTabKeyVisible) {
      selectTab(selectedTabKey)
    }
  }, [isSelectedTabKeyVisible, selectTab, selectedTabKey])

  return (
    <StyledTabListContainer ref={tabListContainerRef}>
      <StyledTabList ref={tabListRef}>{children}</StyledTabList>
    </StyledTabListContainer>
  )
}

const StyledTabContainer = styled(Flex)`
  align-items: center;
  position: relative;
  max-width: 100%;
`
const ActiveTabStyles = css`
  color: ${({ theme }) => theme.colorPrimaryBase};
  &::before {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-bottom: solid 2px ${({ theme }) => theme.colorPrimaryBase};
    margin: 0 0 0 1px;
    width: calc(100% - 2px);
    pointer-events: none;
  }
`
const StyledTab = styled(TabsPrimitive.TabsTrigger)<{
  $isVisible: boolean
}>`
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: ${getFontSize(TextSize.Base)}px;
  font-weight: 400;
  border: none;
  outline: solid 2px transparent;
  background-color: transparent;
  cursor: pointer;
  max-width: 100%;
  padding: 0px 12px;

  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};

  &[data-state='active'] {
    ${ActiveTabStyles}
  }
  &:hover {
    ${ActiveTabStyles}
  }
  &:focus:after {
    content: '';
    position: absolute;
    inset: 4px 4px 6px 4px;
    border-radius: 4px;
    outline: solid 2px ${({ theme }) => theme.colorPrimaryBorder};
  }
`

type TabProps = Pick<
  HTMLProps<HTMLButtonElement>,
  'onClick' | 'onContextMenu'
> & {
  tabKey: string
  children?: React.ReactNode
}
const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ tabKey, children, onClick, onContextMenu }, tabRef) => {
    const { getTabElements, tabKeys, visibleTabKeys, setTabContentsByTabKey } =
      useContext(TabsContext)
    const isVisible = visibleTabKeys?.has(tabKey) ?? true
    const visibleTabCount = visibleTabKeys?.size ?? 0
    const index = getTabElements()
      .map((el) => el.getAttribute(TAB_KEY_ATTR))
      .filter((k) => visibleTabKeys?.has(k))
      .indexOf(tabKey)
    const isLastVisibleTab = isVisible && index === visibleTabCount - 1
    const areAnyTabsHidden =
      tabKeys && visibleTabKeys && tabKeys.size !== visibleTabKeys.size

    useEffect(() => {
      // Populate the tab content map with my contents in case I overflow into the more menu
      setTabContentsByTabKey((prevState) => {
        const newState = new Map<string, ReactNode>(prevState)
        newState.set(tabKey, children)
        return newState
      })
    }, [children, setTabContentsByTabKey, tabKey])

    return (
      <StyledTabContainer
        ref={tabRef}
        {...{ [TAB_CONTAINER_KEY_ATTR]: tabKey }}
      >
        <StyledTab
          value={tabKey}
          $isVisible={isVisible}
          onClick={onClick}
          onContextMenu={onContextMenu}
          {...{ [TAB_KEY_ATTR]: tabKey }}
        >
          {children}
        </StyledTab>
        {isLastVisibleTab && areAnyTabsHidden && <MoreMenu />}
      </StyledTabContainer>
    )
  }
)

const MoreMenu = () => {
  const { tabKeys, visibleTabKeys, tabContentsByTabKey, selectTab } =
    useContext(TabsContext)

  const { isOpen, open, close } = useDisclosure()
  return (
    <Menu isOpen={isOpen} onOpen={open} onClose={close}>
      <Menu.Trigger>
        <StyledMoreMenuButton
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          type="link"
        >
          {`${tabKeys.size - visibleTabKeys.size} more`}
        </StyledMoreMenuButton>
      </Menu.Trigger>
      <Menu.Content>
        {Array.from(tabKeys.values())
          .filter((k) => !visibleTabKeys.has(k))
          .map((k) => (
            <Menu.Item key={k} onSelect={() => selectTab(k)}>
              {tabContentsByTabKey.get(k)}
            </Menu.Item>
          ))}
      </Menu.Content>
    </Menu>
  )
}

type ActionsProps = {
  children?: React.ReactNode
}
const Actions = ({ children }: ActionsProps) => {
  const { tabListContainerRef } = useContext(TabsContext)
  if (!tabListContainerRef.current) {
    return null
  }
  return <Portal container={tabListContainerRef.current}>{children}</Portal>
}

type TabsContextType = {
  getTabContainerElement: (tabKey: string) => Element | null
  getTabContainerElements: () => Element[]
  getTabElement: (tabKey: string) => Element | null
  getTabElements: () => Element[]
  rootRef: React.RefObject<HTMLDivElement>
  selectTab: (tabKey: string) => void
  selectedTabKey: string
  setTabContentsByTabKey: React.Dispatch<SetStateAction<Map<string, ReactNode>>>
  setTabKeys: React.Dispatch<SetStateAction<Set<string>>>
  setVisibleTabKeys: React.Dispatch<SetStateAction<Set<string>>>
  tabContentsByTabKey: Map<string, ReactNode>
  tabKeys: Set<string>
  tabListContainerRef: React.RefObject<HTMLDivElement>
  tabListRef: React.RefObject<HTMLDivElement>
  visibleTabKeys: Set<string>
}
const TabsContext = React.createContext<TabsContextType>({} as TabsContextType)

const Tabs = Object.assign(TabsBase, {
  Actions,
  Tab,
  TabsList,
})

export default Tabs
