import { useState } from 'react'

export interface HoverProps {
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export interface HoverState {
  hovered: boolean
  setHovered: (newHovered: boolean) => void
  hoverProps: HoverProps
}

export const useHover = (): HoverState => {
  const [hovered, setHovered] = useState(false)
  return {
    hovered,
    setHovered,
    hoverProps: {
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false),
    },
  }
}
