import { ReactNode } from 'react'

import { Checkbox as AntdCheckbox, Form } from 'antd'
import styled from 'styled-components'

import Wrapper, { FormFieldWrapperProps } from './Wrapper'

export interface CheckboxOption<T = string | number> {
  label: ReactNode
  value: T
  disabled?: boolean
  onChange?: () => Promise<void> | void
}

export interface CheckboxProps extends FormFieldWrapperProps {
  options: CheckboxOption[]
  mode?: 'horizontal' | 'vertical'
  fieldName: string
  showAsGreen?: boolean
}

const Container = styled.div<{ $showAsGreen: boolean }>`
  ${(props) =>
    props.$showAsGreen &&
    `  .ant-checkbox-inner {
        background-color: ${props.theme.colorSuccessBase};
        border-color: ${props.theme.colorSuccessBase};
  }`}
`

export function Checkbox(props: CheckboxProps) {
  const {
    options,
    mode = 'horizontal',
    disabled,
    showAsGreen,
    ...formFieldWrapperProps
  } = props
  const form = Form.useFormInstance()
  Form.useWatch(formFieldWrapperProps.fieldName, form)
  return (
    <Container $showAsGreen={!!showAsGreen}>
      <Wrapper {...formFieldWrapperProps} valuePropName="checked">
        {({ value }) => (
          <AntdCheckbox.Group
            style={{
              gap: '12px',
              flexDirection: mode === 'horizontal' ? 'row' : 'column',
            }}
            options={options}
            value={value}
            name={formFieldWrapperProps.fieldName}
            disabled={disabled}
          />
        )}
      </Wrapper>
    </Container>
  )
}

export default Checkbox
