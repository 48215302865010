import { navigate } from '@redwoodjs/router'

import { useHomePage } from '../../hooks/use-home-page'
import { useMyUser } from '../AppStateContextLayout/utils'

interface Props {
  children?: React.ReactNode
}

export const CitizenLayout = (props: Props) => {
  const { children } = props
  const me = useMyUser()
  const homePage = useHomePage()
  if (me && me.type !== 'Citizen') {
    navigate(homePage)
  }
  return <>{children}</>
}

export default CitizenLayout
