import { useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import {
  QueryClient as _QueryClient,
  DefaultError,
  MutationFunction,
  QueryCache,
  QueryFunction,
  MutationKey as ReactMutationKey,
  QueryClientProvider as ReactQueryClientProvider,
  QueryKey as ReactQueryKey,
  UseMutationOptions as ReactUseMutationOptions,
  UseQueryOptions as ReactUseQueryOptions,
  useMutation as useReactMutation,
  useQuery as useReactQuery,
} from '@tanstack/react-query'

import { TypedDocumentNode } from '@redwoodjs/web'

import { useNotification } from 'src/components/shared/Notification/NotificationContext'

export enum QueryKey {
  AppState = 'AppState',
  AutomationTemplates = 'AutomationTemplates',
  CodeBooks = 'CodeBooks',
  ChecklistTemplates = 'ChecklistTemplates',
  GenerateDocumentsPage = 'GenerateDocumentsPage',
  GetOrganizationChecklistTemplatesSelect = 'GetOrganizationChecklistTemplatesSelect',
  GetOrganizationsGovWellStaffManagement = 'GetOrganizationsGovWellStaffManagement',
  GetOrganizationGovWellStaffManagement = 'GetOrganizationGovWellStaffManagement',
  GisAttributes = 'GisAttributes',
  CommentTemplates = 'CommentTemplates',
  EditRecordDraftForm = 'EditRecordDraftForm',
  EmailTemplates = 'EmailTemplates',
  EmailTemplate = 'EmailTemplate',
  Enumeration = 'Enumeration',
  Enumerations = 'Enumerations',
  File = 'File',
  FilePageImageUrls = 'FileImageUrls',
  FileProcessed = 'FileProcessed',
  FilesOnRecord = 'FilesOnRecord',
  Formulas = 'Formulas',
  InspectionTemplates = 'InspectionTemplates',
  LogInspectionModal = 'LogInspectionModal',
  MostRecentPlansSet = 'MostRecentPlansSet',
  PlanReviewModal = 'PlanReviewModal',
  RecordApplicationFields = 'RecordApplicationFields',
  RecordCaseReport = 'RecordCaseReport',
  Records = 'Records',
  RecordsSearch = 'RecordsSearch',
  RecordPlansSet = 'RecordPlansSet',
  RecordPlansSets = 'RecordPlansSets',
  RecordPlansSetReviewResults = 'RecordPlansSetReviewResults',
  RecordSettingsForModule = 'RecordSettingsForModule',
  RecordTaskGroupForPlanReview = 'RecordTaskGroupForPlanReview',
  RecordTaskInspectionAttemptFile = 'RecordTaskInspectionAttemptFile',
  RecordTaskTemplateGroupRouting = 'RecordTaskTemplateGroupRouting',
  RecordTaskTemplateGroupOptionsForRecordTemplate = 'RecordTaskTemplateGroupOptionsForRecordTemplate',
  RecordTaskTemplateGroupOptionsForWorkflowTemplate = 'RecordTaskTemplateGroupOptionsForWorkflowTemplate',
  RecordTemplate = 'RecordTemplate',
  RecordTemplates = 'RecordTemplates',
  RecordTemplatesUserHasAccessTo = 'RecordTemplatesUserHasAccessTo',
  RecordTypeStatuses = 'RecordTypeStatuses',
  RecordType = 'RecordType',
  RecordTypes = 'RecordTypes',
  RecordTypesForConfig = 'RecordTypesForConfig',
  SendRecordEmailModal = 'SendRecordEmailModal',
  StampTemplate = 'StampTemplate',
  StampTemplates = 'StampTemplates',
  StampTemplatesSelect = 'StampTemplatesSelect',
  TaskTemplates = 'TaskTemplates',
  UniversalSearch = 'UniversalSearch',
  Users = 'Users',
  Valuations = 'Valuations',
  ViewColumnTemplates = 'ViewColumnTemplates',
  Views = 'Views',
  ViewsData = 'ViewsData',
  ViewsDataCounts = 'ViewsDataCounts',
  Workflow = 'Workflow',
  WorkflowTemplate = 'WorkflowTemplate',
  WorkflowTemplates = 'WorkflowTemplates',
  WorkflowTemplatesTab = 'WorkflowTemplatesTab',
}

const useQueryClient = () => {
  // const { notification } = useNotification()
  return useMemo(
    () =>
      new _QueryClient({
        queryCache: new QueryCache({
          onError: (_error, query) => {
            const queryKey = query.queryKey[0] as QueryKey
            switch (queryKey) {
              default:
                // This switch should be used to provide customized errors on a per-query-key basis
                // notification.error({
                //   message: `Failed to load plan review file: ${error.message}`,
                // })
                break
            }
          },
        }),
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    []
  )
}

export const QueryClientProvider = ({
  children,
}: {
  children?: React.ReactNode
}) => {
  const queryClient = useQueryClient()

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  )
}

type UseQueryArgs<
  TData,
  TVariables extends Record<string, unknown> | void = void,
  TError = DefaultError,
  TQueryKey extends ReactQueryKey = ReactQueryKey
> = Omit<ReactUseQueryOptions<TData, TError, TData, TQueryKey>, 'queryFn'> &
  (
    | {
        queryDocument: TypedDocumentNode<TData, TVariables>
        queryFn?: never
      }
    | { queryFn: QueryFunction<TData, TQueryKey, never>; queryDocument?: never }
  ) &
  (TVariables extends void
    ? {
        variables?: never
      }
    : {
        variables: TVariables
      })

export type UseQueryOptions<
  TData,
  TVariables extends Record<string, unknown> | void = void
> = Omit<
  UseQueryArgs<TData, TVariables>,
  'queryKey' | 'queryFn' | 'queryDocument' | 'variables'
>

/**
 * @description Query hook wrapper that allows both REST and GQL operations
 * @param options Configure one of `queryFn` for REST or (`queryDocument` and `variables`) for GQL
 */
export const useQuery = <
  TData,
  TVariables extends Record<string, unknown> | void = void,
  TError = DefaultError,
  TQueryKey extends ReactQueryKey = ReactQueryKey
>({
  queryFn,
  queryDocument,
  variables,
  ...options
}: UseQueryArgs<TData, TVariables, TError, TQueryKey>) => {
  const client = useApolloClient()
  return useReactQuery<TData, TError, TData, TQueryKey>({
    ...options,
    queryFn: queryFn
      ? queryFn
      : () =>
          client
            .query({
              query: queryDocument,
              variables,
              fetchPolicy: 'no-cache',
            })
            .then((result) => result.data),
  })
}

export type UseMutationArgs<
  TData,
  TVariables extends Record<string, unknown> | void = void,
  TError = DefaultError,
  TMutationKey extends ReactMutationKey = ReactMutationKey
> = Omit<
  ReactUseMutationOptions<TData, TError, TVariables, TMutationKey>,
  'mutationFn'
> &
  (
    | {
        mutationDocument: TypedDocumentNode<TData, TVariables>
        mutationFn?: never
      }
    | {
        mutationFn: MutationFunction<TData, TVariables>
        mutationDocument?: never
      }
  )

export type UseMutationOptions<
  TData,
  TVariables extends Record<string, unknown> | void = void
> = Omit<UseMutationArgs<TData, TVariables>, 'mutationDocument' | 'mutationFn'>

export const useMutation = <
  TData,
  TVariables extends Record<string, unknown> | undefined = undefined,
  TError = DefaultError,
  TMutationKey extends ReactMutationKey = ReactMutationKey
>({
  mutationFn,
  mutationDocument,
  ...options
}: UseMutationArgs<TData, TVariables, TError, TMutationKey>) => {
  const client = useApolloClient()
  const { notification } = useNotification()
  return useReactMutation<TData, TError, TVariables, TMutationKey>({
    onError: (e) => {
      if (e instanceof Error) {
        notification.error({
          message: 'Something went wrong',
          description: e.message,
        })
      } else {
        notification.error({
          message: 'Something went wrong',
          description:
            'An error occurred. Please try again or contact support by clicking the orange chat icon at the bottom of the screen.',
        })
      }
    },
    ...options,
    mutationFn: mutationFn
      ? mutationFn
      : (variables: TVariables) =>
          client
            .mutate({
              mutation: mutationDocument,
              variables,
              fetchPolicy: 'no-cache',
            })
            .then((result) => {
              if (!result.data) {
                throw new Error('Mutation data unavailable')
              }
              return result.data
            }),
  })
}
