import { useEffect } from 'react'

import { navigate, useParams } from '@redwoodjs/router'

import { useHomePage } from '../../hooks/use-home-page'
import { useMyRecordTypes } from '../AppStateContextLayout/utils'

interface Props {
  children?: React.ReactNode
}

export const GovRecordTypeLayout = (props: Props) => {
  const { children } = props
  const { recordTypeId } = useParams()
  const homePage = useHomePage()
  const myRecordTypes = useMyRecordTypes()

  const isRecordTypeAllowed =
    recordTypeId &&
    myRecordTypes?.some((rt) => rt.id === parseInt(recordTypeId))
  useEffect(() => {
    if (!isRecordTypeAllowed) {
      navigate(homePage)
    }
  }, [homePage, isRecordTypeAllowed])

  if (isRecordTypeAllowed) {
    return <>{children}</>
  }
  return null
}

export default GovRecordTypeLayout
