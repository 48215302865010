import { Colors } from './colors'
import { FontMap } from './fontMap'
import { HeightMap } from './heightMap'
import { SizeMap } from './sizeMap'
import { StyleMap } from './styleMap'

export const Theme = {
  ...Colors,
  ...FontMap,
  ...HeightMap,
  ...SizeMap,
  ...StyleMap,
}

export const AntDesignThemeOverride = {
  token: Theme,
  components: {
    Breadcrumb: {
      separatorColor: Theme.colorBreadcrumbNotLastItem,
      itemColor: Theme.colorBreadcrumbNotLastItem,
      linkColor: Theme.colorBreadcrumbNotLastItem,
      lastItemColor: Theme.colorBreadcrumbLastItem,
      separatorMargin: 4,
    },
    Menu: {
      itemColor: Theme.colorText,
      itemHoverColor: Theme.colorPrimaryBase,
      itemSelectedColor: Theme.colorTextHeading,
      activeBarHeight: 2,
    },
  },
}

export const AntDesignButtonOverride = {
  style: { boxShadow: '0 2px 0 rgba(255, 255, 255, 0.02)' },
}
