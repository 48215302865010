import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  faCopy,
  faGripDotsVertical,
  faPen,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex } from 'antd'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { GetOrCreateViewsMutation } from 'types/graphql'

import CreateViewModal from 'src/components/TableViews/CreateViewModal'
import DeleteViewModal from 'src/components/TableViews/DeleteViewModal'
import RenameViewModal from 'src/components/TableViews/RenameViewModal'
import Text from 'src/components/Typography/Text'
import useDisclosure from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'
import { TableViewManager } from 'src/models/TableViews/TableViewManager'

const StyledFlex = styled(Flex)`
  padding: 6px 4px;
  border-bottom: solid 1px ${({ theme }) => theme.colorSplit};
`
const IconOnlyButton = styled(Button)`
  border: none;
`

type Props = {
  generateDefaultViews: () => Promise<GetOrCreateViewsMutation>
  view: TableView
  tableViewManager: TableViewManager
}
const EditViewsDrawerItem = ({
  generateDefaultViews,
  view,
  tableViewManager,
}: Props) => {
  const renameModalState = useDisclosure()
  const duplicateModalState = useDisclosure()
  const deleteModalState = useDisclosure()

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: view.id })

  return (
    <>
      <StyledFlex
        align="center"
        justify="space-between"
        gap="12px"
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
        }}
      >
        <Flex align="center" gap="3px">
          <div
            {...attributes}
            {...listeners}
            style={{ cursor: 'grab', padding: '8px' }}
          >
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </div>
          <Text>{view.name}</Text>
        </Flex>
        <Flex align="center" gap="3px">
          <IconOnlyButton
            icon={<FontAwesomeIcon icon={faPen} />}
            onClick={renameModalState.open}
          />
          <IconOnlyButton
            icon={<FontAwesomeIcon icon={faCopy} />}
            onClick={duplicateModalState.open}
          />
          <IconOnlyButton
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={deleteModalState.open}
          />
        </Flex>
      </StyledFlex>
      <RenameViewModal modalState={renameModalState} view={view} />
      <CreateViewModal
        modalState={duplicateModalState}
        tableViewManager={tableViewManager}
        duplicateFromView={view}
      />
      <DeleteViewModal
        generateDefaultViews={generateDefaultViews}
        modalState={deleteModalState}
        tableViewManager={tableViewManager}
        view={view}
      />
    </>
  )
}

export default observer(EditViewsDrawerItem)
