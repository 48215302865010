import { useCallback, useMemo, useState } from 'react'

import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMenuKeyboardShortcuts } from 'govwell-ui/components/Menu/use-menu-keyboard-shortcuts'
import isNil from 'lodash.isnil'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled, { css } from 'styled-components'

import { FilterManager } from 'src/models/TableViews/FilterManager'
import { TableColumnTemplate } from 'src/models/TableViews/TableColumnTemplate'
import { KeyboardKey } from 'src/types'

import { Popover } from '../../govwell-ui'
import { MenuContentStyles } from '../../govwell-ui/components/Menu'
import useDisclosure from '../../hooks/use-disclosure'
import Text, { TextSize, getFontSize } from '../Typography/Text'

const ActiveStyles = css`
  background-color: ${({ theme }) => theme.controlItemBgHover};
`
const StyledMenuItem = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  padding: 9px 12px;
  border-radius: 4px;
  font-size: ${getFontSize(TextSize.Base)}px;
  ${({ $isActive }) => $isActive && ActiveStyles};
`

const StyledFilterText = styled(Text)`
  cursor: pointer;
  padding: 0px 3px;
  border-radius: 6px;
  &:hover {
    background-color: ${({ theme }) => theme.controlItemBgHover};
  }
  &:focus {
    outline: solid 2px ${({ theme }) => theme.colorPrimaryBorder};
  }
`

const StyledContent = styled(Popover.Content)`
  ${MenuContentStyles}
  &:focus {
    outline: none;
  }
`

type FilterOption = {
  index: number
  id: `filter-type-${string}`
  label: string
  onClick: () => void
}

type Props = {
  filterManager: FilterManager
  columnTemplate: TableColumnTemplate
}
const FilterTypeSelect = ({ filterManager, columnTemplate }: Props) => {
  const filter = filterManager.getFilterByColumnType(columnTemplate.columnType)
  const {
    isOpen: isMenuOpen,
    open: onOpenMenu,
    close: onCloseMenu,
    toggle: onToggleMenu,
  } = useDisclosure()

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === KeyboardKey.Enter || e.key === KeyboardKey.Space) {
        onToggleMenu()
        e.preventDefault()
        e.stopPropagation()
      }
    },
    [onToggleMenu]
  )

  const handleContainsClicked = useCallback(() => {
    runInAction(() => {
      filter.isNull = undefined
      filter.optionsSelected = []
      onCloseMenu()
    })
  }, [filter, onCloseMenu])

  const handleIsEmptyClicked = useCallback(() => {
    runInAction(() => {
      filter.isNull = true
      filter.optionsSelected = []
      onCloseMenu()
    })
  }, [filter, onCloseMenu])

  const handleIsNotEmptyClicked = useCallback(() => {
    runInAction(() => {
      filter.isNull = false
      filter.optionsSelected = []
      onCloseMenu()
    })
  }, [filter, onCloseMenu])

  const containsFilterOption: FilterOption = useMemo(
    () => ({
      index: 0,
      id: 'filter-type-contains',
      label: 'Contains',
      onClick: handleContainsClicked,
    }),
    [handleContainsClicked]
  )
  const isEmptyFilterOption: FilterOption = useMemo(
    () => ({
      index: 1,
      id: 'filter-type-is-empty',
      label: 'Is empty',
      onClick: handleIsEmptyClicked,
    }),
    [handleIsEmptyClicked]
  )
  const isNotEmptyFilterOption: FilterOption = useMemo(
    () => ({
      index: 2,
      id: 'filter-type-is-not-empty',
      label: 'Is not empty',
      onClick: handleIsNotEmptyClicked,
    }),
    [handleIsNotEmptyClicked]
  )
  const options = useMemo(
    () => [containsFilterOption, isEmptyFilterOption, isNotEmptyFilterOption],
    [containsFilterOption, isEmptyFilterOption, isNotEmptyFilterOption]
  )

  const [activeIndex, setActiveIndex] = useState(
    isNil(filter.isNull)
      ? containsFilterOption.index
      : filter.isNull
      ? isEmptyFilterOption.index
      : isNotEmptyFilterOption.index
  )
  const { menuItemRefs } = useMenuKeyboardShortcuts({
    activeIndex,
    count: options.length,
    isEnabled: isMenuOpen,
    onEscape: onCloseMenu,
    onSelect: () => {
      options[activeIndex].onClick()
    },
    setActiveIndex,
  })

  if (!columnTemplate.isNullable) {
    return null
  }

  return (
    <Popover isOpen={isMenuOpen} onClose={onCloseMenu} onOpen={onOpenMenu}>
      <Popover.Trigger asChild>
        <StyledFilterText role="button" tabIndex={0} onKeyDown={handleKeyDown}>
          {isNil(filter.isNull)
            ? 'contains'
            : filter.isNull
            ? 'is empty'
            : 'is not empty'}{' '}
          <FontAwesomeIcon icon={isMenuOpen ? faChevronUp : faChevronDown} />
        </StyledFilterText>
      </Popover.Trigger>
      <Popover.Portal>
        <StyledContent
          sideOffset={0}
          role="menu"
          tabIndex={0}
          aria-activedescendant={options[activeIndex].id}
        >
          {options.map((o, index) => (
            <StyledMenuItem
              role="menuitem"
              onClick={o.onClick}
              $isActive={activeIndex === index}
              onMouseMove={() => setActiveIndex(index)}
              tabIndex={-1}
              ref={(el) => menuItemRefs.current.set(index, el)}
              id={o.id}
              key={o.id}
            >
              {o.label}
            </StyledMenuItem>
          ))}
        </StyledContent>
      </Popover.Portal>
    </Popover>
  )
}

export default observer(FilterTypeSelect)
