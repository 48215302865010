import { faGear, faUser, faUsers } from '@fortawesome/pro-regular-svg-icons'

import { routes, useLocation, useParams } from '@redwoodjs/router'

import Navigation from 'src/components/Sidebar/Navigation/Navigation'

import {
  useMyCompany,
  useMyOrg,
  useMyUser,
} from '../../layouts/AppStateContextLayout/utils'
import { formatCityState, formatName } from '../../utils'
import Text, { TextSize } from '../Typography/Text'

export const SettingsNavItem = () => {
  const { pathname } = useLocation()
  const { taskTemplateId, recordTemplateUuid } = useParams()

  const settingsRoutes: string[] = [
    routes.settings(),
    routes.organization(),
    routes.violationSettings(),
    routes.emailTemplates(),
    routes.taskTemplates(),
    routes.configure(),
    routes.fees(),
    routes.inspectionTemplates(),
    routes.documentTemplates(),
  ]

  if (recordTemplateUuid) {
    settingsRoutes.push(
      routes.recordTemplateConfiguration({ recordTemplateUuid })
    )
  }
  if (taskTemplateId) {
    settingsRoutes.push(
      routes.taskTemplate({ taskTemplateId: parseInt(taskTemplateId) })
    )
  }

  const isSettingsActive = settingsRoutes.includes(pathname as string)

  return (
    <Navigation.Item
      icon={faGear}
      title="Settings"
      href={routes.settings()}
      isActive={isSettingsActive}
    />
  )
}

export const CityFooterDisplay = () => {
  const org = useMyOrg()
  const city = org?.city ?? ''
  const state = org?.state
  const displayName = org?.displayName

  if (!displayName && !city && !state) {
    return null
  }

  return (
    <Text
      size={TextSize.Large}
      colorToken="colorWhite"
      style={{ display: 'block', padding: '6px 8px' }}
    >
      {displayName || formatCityState(city, state)}
    </Text>
  )
}

export const UserProfileNavItem = () => {
  const me = useMyUser()
  const firstName = me?.firstName
  const lastName = me?.lastName
  const { pathname } = useLocation()

  const active = pathname === routes.userProfile()

  if (!firstName || !lastName) {
    return null
  }

  return (
    <Navigation.Item
      icon={faUser}
      title={formatName(firstName, lastName)}
      href={routes.userProfile()}
      isActive={active}
    />
  )
}

export const UserCompanyNavItem = () => {
  const myCompany = useMyCompany()
  const { pathname } = useLocation()

  const active =
    pathname === routes.newCompanyProfile() ||
    pathname === routes.editCompanyProfile() ||
    pathname === routes.companyProfile()

  if (!myCompany) {
    return null
  }

  return (
    <Navigation.Item
      icon={faUsers}
      title={myCompany.name}
      href={routes.companyProfile()}
      isActive={active}
    />
  )
}
