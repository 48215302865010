export const RecordTemplateOptionFragment = gql`
  fragment RecordTemplateOptionFragment on RecordTemplate {
    id
    status
    uuid
    createdAt
    name
    recordType {
      id
      recordName
      name
      category
    }
  }
`
