import { useCallback } from 'react'

import { ColumnDef, PaginationState, flexRender } from '@tanstack/react-table'
import { Flex, Pagination } from 'antd'
import TableHeaderCell from 'govwell-ui/components/Table/TableHeaderCell'
import { useTable } from 'govwell-ui/components/Table/use-table'
import { SortingState } from 'govwell-ui/components/Table/use-table-state'
import styled from 'styled-components'

import EmptyState from 'src/components/EmptyState'
import { TextSize, getFontSize } from 'src/components/Typography/Text'

const StyledTable = styled.table<{ $isLoading: boolean }>`
  width: 100%;
  padding: 0;
  border-spacing: 0px;
  ${({ $isLoading }) =>
    $isLoading
      ? `
    opacity: 0.5;
    pointer-events: none;
  `
      : ''}
`
const StyledTHead = styled.thead`
  background-color: ${({ theme }) => theme.colorBgLayout};
  padding: 0;
`
const StyledTBody = styled.tbody`
  tr:nth-child(even) > td {
    background-color: ${({ theme }) => theme.colorBgTableCellAlt};
  }
  tr:nth-child(odd) > td {
    background-color: ${({ theme }) => theme.colorWhite};
  }
`
const StyledTR = styled.tr`
  &:last-child > td:first-child {
    border-bottom-left-radius: 6px;
  }
  &:last-child > td:last-child {
    border-bottom-right-radius: 6px;
  }
`
const StyledTD = styled.td<{ $textAlign?: React.CSSProperties['textAlign'] }>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${getFontSize(TextSize.Base)}px;
  text-align: ${({ $textAlign }) => $textAlign ?? 'left'};
  padding: 8px;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
  color: ${({ theme }) => theme.colorText};

  border-bottom: solid 1px ${({ theme }) => theme.colorSplit};
  &:first-child {
    border-left: solid 1px ${({ theme }) => theme.colorSplit};
  }
  &:last-child {
    border-right: solid 1px ${({ theme }) => theme.colorSplit};
  }
`
const StyledEmptyStateWrapper = styled.div`
  > div {
    border: solid 1px ${({ theme }) => theme.colorSplit};
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

type Props<TData = unknown, TSortKey extends string = string> = {
  columns: ColumnDef<TData, any>[]
  data: TData[] | null | undefined
  emptyStateButtonProps?: React.ComponentProps<typeof EmptyState.Button>
  isLoading?: boolean
  onPaginationChange?: (pagination: PaginationState) => void
  onSortingChange?: (
    updateFn: (sorting: SortingState<TSortKey>) => SortingState<TSortKey>
  ) => void
  pagination?: PaginationState
  sorting?: SortingState<TSortKey>
  totalCount?: number
}
const TableContent = <TData, TSortKey extends string = string>({
  columns,
  data,
  emptyStateButtonProps,
  isLoading,
  pagination,
  onPaginationChange,
  onSortingChange,
  sorting,
  totalCount,
}: Props<TData, TSortKey>) => {
  const { tableState } = useTable<TData, TSortKey>({
    columns,
    data,
    onPaginationChange,
    onSortingChange,
    pagination,
    sorting,
  })
  const rows = tableState.getCoreRowModel().rows

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      onPaginationChange?.({
        pageIndex: page - 1,
        pageSize,
      })
    },
    [onPaginationChange]
  )

  return (
    <Flex
      vertical
      gap="12px"
      style={{
        maxWidth: '100%',
        overflowX: 'auto',
      }}
    >
      <Flex vertical>
        <StyledTable $isLoading={!!isLoading}>
          <StyledTHead>
            {tableState.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeaderCell header={header} key={header.id} />
                  ))}
                </tr>
              )
            })}
          </StyledTHead>
          <StyledTBody>
            {rows.map((row) => (
              <StyledTR key={row.id}>
                {row.getAllCells().map((cell) => (
                  <StyledTD
                    key={cell.id}
                    $textAlign={cell.column.columnDef.meta?.textAlign}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </StyledTD>
                ))}
              </StyledTR>
            ))}
          </StyledTBody>
        </StyledTable>
        {!rows.length && (
          <StyledEmptyStateWrapper>
            <EmptyState hideBorder>
              <EmptyState.Image />
              <EmptyState.Message>No results found</EmptyState.Message>
              {emptyStateButtonProps && (
                <EmptyState.Button {...emptyStateButtonProps} />
              )}
            </EmptyState>
          </StyledEmptyStateWrapper>
        )}
      </Flex>
      <Flex justify="flex-end">
        {pagination && totalCount && totalCount > pagination.pageSize && (
          <Pagination
            current={pagination.pageIndex + 1}
            onChange={handlePaginationChange}
            pageSize={pagination.pageSize}
            style={{ position: 'sticky', bottom: 0 }}
            total={totalCount}
            showSizeChanger={false}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default TableContent
