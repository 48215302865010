import type { Prisma } from '@prisma/client'

import {
  FieldFragment,
  FieldParametersSchema,
  FieldType,
} from '../../../types/graphql'
import { FormFieldNamePrefix } from '../../constants'
import { StateAbbreviationMap } from '../../constants/states'

// fieldTypesWithoutInput is duplicated on the backend
const fieldTypesWithoutInput = new Set<FieldType>([
  'Header',
  'ExplanationText',
  'LineBreak',
])

export const fieldHasNoInput = (type: FieldType) => {
  return fieldTypesWithoutInput.has(type)
}

export const destructureFieldParameters = (
  parameters: Prisma.JsonValue
): FieldParametersSchema => {
  return parameters as FieldParametersSchema
}

interface HasType {
  type: FieldType
}

export function getInputFields<T extends HasType>(fields?: T[]): T[] {
  return fields?.filter((f) => !isFileField(f.type)) ?? []
}

export function getFileFields<T extends HasType>(fields?: T[]): T[] {
  return fields?.filter((f) => isFileField(f.type)) ?? []
}

export const isFileField = (type: FieldType) => ['File', 'Files'].includes(type)

export const isForeignValueField = (type: FieldType) =>
  ['ForeignValue'].includes(type)

export const makeFieldNameForFieldFragment = (
  fieldNameSuffix?: string | number
): string => {
  return fieldNameSuffix
    ? `${FormFieldNamePrefix}${fieldNameSuffix}`
    : FormFieldNamePrefix
}

/**
 * Format the address of a contact
 * TODO: Load a formatted address from the backend and delete this function, see api/src/utils/address.ts
 *
 * @param address Address values from a contact field
 * @returns Address if any field is populated, otherwise an empty string
 */
export const formatFieldContactAddress = (
  address:
    | Pick<
        NonNullable<FieldFragment['contact']>,
        'addressLine1' | 'addressLine2' | 'city' | 'state' | 'zip'
      >
    | undefined
) =>
  [
    `${address?.addressLine1 || ''} ${address?.addressLine2 || ''}`,
    `${address?.city || ''} ${
      address?.state ? StateAbbreviationMap[address.state] : ''
    } ${address?.zip || ''}`,
  ]
    .map((item) => item.trim())
    .filter((a) => !!a)
    .join(', ')
