import { StateAbbreviationMap, StateNameMap } from 'src/constants/states'

import { State } from '../../../../types/graphql'
import Select, { BasicOptionType, SelectProps } from '../Select'
import { getLabelStr } from '../util'

const stateOptions: BasicOptionType<State>[] = Object.keys(
  StateAbbreviationMap
).map((state) => ({
  label: StateNameMap[state as State],
  value: state as State,
}))

type Props = Omit<SelectProps<BasicOptionType<State>>, 'options'>

const StateSelect = ({ placeholder, label = 'State', ...rest }: Props) => {
  return (
    <Select<BasicOptionType<State>>
      {...rest}
      placeholder={placeholder || getLabelStr(label)}
      label={label}
      options={stateOptions}
      filterOption={(input: string, state: State) => {
        return (
          StateNameMap[state].toLowerCase().startsWith(input.toLowerCase()) ||
          StateAbbreviationMap[state]
            .toLowerCase()
            .startsWith(input.toLowerCase())
        )
      }}
    />
  )
}

export default StateSelect
