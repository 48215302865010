import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import dayjs, { Dayjs } from 'dayjs'

import { useRescheduleRecordTaskInspectionAttemptMutation } from '../../fetch/recordTaskInspectionAttempts'
import { DateFormats, formatDate } from '../../utils/date'
import DatePicker, { DatePickerAllowedDates } from '../form/DatePicker'
import { Modal } from '../Modal'
import Text, { TextSize } from '../Typography/Text'

import { InspectionMetadata } from './InspectionMetadata'
import { InspectionsTabModalProps } from './types'

interface FormValues {
  date: Dayjs
}

interface Props extends InspectionsTabModalProps {
  currentInspectionDate: string
}

export const RescheduleInspectionModal = (props: Props) => {
  const {
    modalState,
    recordTaskInspectionAttemptId,
    currentInspectionDate,
    refetch,
  } = props
  const { mutateAsync: reschedule } =
    useRescheduleRecordTaskInspectionAttemptMutation()
  const onOk = async (formValues: FormValues) => {
    const { date } = formValues
    await reschedule({
      id: recordTaskInspectionAttemptId,
      scheduledFor: dayjs(date).toISOString(),
    })
    await refetch()
    modalState.close()
  }

  const title = `Reschedule Inspection`

  return (
    <Modal
      {...modalState}
      form
      icon={faCalendar}
      title={title}
      okText={title}
      onOk={onOk}
    >
      <InspectionMetadata {...props} />
      <Text size={TextSize.Large} margin="0 0 12px 0">
        This inspection is currently scheduled for{' '}
        {formatDate(currentInspectionDate, DateFormats.MonthDayFullYear)}. You
        may reschedule it.
      </Text>
      <DatePicker
        fieldName="date"
        label="New Inspection Date"
        required
        errorMessage="You must enter a date"
        allowedDates={DatePickerAllowedDates.All}
        disableWeekends
      />
    </Modal>
  )
}
