import { ReactNode } from 'react'

import { Flex } from 'antd'
import {
  FormControlProps,
  getCaptionId,
} from 'govwell-ui/components/FormControl/util'

import Text, { TextSize } from 'src/components/Typography/Text'

type Props = FormControlProps & {
  children: ReactNode
}
const FormControl = ({
  caption,
  children,
  id,
  isRequired,
  label,
  width,
}: Props) => {
  return (
    <Flex
      vertical
      gap="3px"
      style={{
        position: 'relative',
        width,
        minWidth: 0, // This CSS hack is necessary to get form controls to shrink in flex rows
      }}
    >
      {label && (
        <label htmlFor={id}>
          <Flex>
            {typeof label === 'string' ? (
              <Text colorToken="colorTextLabel">{label}</Text>
            ) : (
              label
            )}
            {isRequired && <Text colorToken="red">&nbsp;*</Text>}
          </Flex>
        </label>
      )}
      {children}
      {caption && id && (
        <div id={getCaptionId(id)}>
          {typeof caption === 'string' ? (
            <Text size={TextSize.Small} colorToken="colorTextLabel">
              {caption}
            </Text>
          ) : (
            caption
          )}
        </div>
      )}
    </Flex>
  )
}

export default React.memo(FormControl)
