import Select, { BasicOptionType, SelectProps } from '../Select'

const options: BasicOptionType<string>[] = [
  {
    value: 'true',
    label: 'True',
  },
  {
    value: 'false',
    label: 'False',
  },
]

type Props = Omit<SelectProps<BasicOptionType<string>>, 'options'>
const TrueFalseSelect = (props: Props) => {
  return (
    <Select
      allowClear
      placeholder="Select true or false"
      {...props}
      options={options}
    />
  )
}

export default React.memo(TrueFalseSelect)
