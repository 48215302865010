import { SelectOption } from 'govwell-ui/components/Select/types'
import styled from 'styled-components'

import Text, { TextSize } from 'src/components/Typography/Text'

const StyledContainer = styled.div`
  padding: 3px 12px;
  background-color: ${({ theme }) => theme.colorFillContent};
  border-radius: 4px;
`

type Props<TValue> = {
  index: number
  option: SelectOption<TValue>
  options: SelectOption<TValue>[]
}
const SelectMenuItemGroupLabel = <TValue,>({
  index,
  option,
  options,
}: Props<TValue>) => {
  if (!option.group) {
    return null
  }
  if (index > 0 && options[index - 1].group === option.group) {
    return null
  }

  return (
    <StyledContainer>
      <Text size={TextSize.Small} colorToken="colorTextSecondary">
        {option.group}
      </Text>
    </StyledContainer>
  )
}

export default React.memo(
  SelectMenuItemGroupLabel
) as typeof SelectMenuItemGroupLabel
