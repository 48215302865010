import { ReactNode } from 'react'

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { ButtonProps as AntdButtonProps } from 'antd'
import { TableProps as AntdTableProps } from 'antd/es/table'

import { FilterType } from '../../../types/graphql'
import { TableRecordGenericType, UseTableProps } from '../../hooks/use-table'
import { CheckboxOption } from '../form/Checkbox'

export interface TableHeaderProps {
  name: ReactNode
  buttonProps?: ButtonProps | ButtonProps[]
  accordion?: boolean
}

export enum TableReorderType {
  ReorderableNoIcon,
  ReorderableIcon,
  NotReorderable,
}
export interface ButtonProps {
  text: string
  icon?: IconDefinition
  type?: AntdButtonProps['type']
  onClick: () => void | Promise<unknown>
  isLoading?: boolean
  disabled?: boolean
  tooltip?: string
}
export interface TableProps<
  RecordType extends TableRecordGenericType,
  ColumnIds = any
> extends UseTableProps<RecordType> {
  paginate?: boolean
  header?: TableHeaderProps
  footer?: ReactNode
  data?: AntdTableProps<RecordType>['dataSource']
  columns: AntdTableProps<RecordType>['columns']
  reorderType?: TableReorderType
  showNumber?: boolean
  emptyText?: ReactNode
  renderModal?: (props: TableProps<RecordType>) => ReactNode
  filterInputs?: FilterInput[]
  sortInputs?: SortInput<ColumnIds>[]
  expandable?: AntdTableProps<RecordType>['expandable']
  showRightVerticalLine?: boolean
  id?: string
  hasHeaderRows?: boolean
  renderHeaderCell?: (record: RecordType) => ReactNode
  rowSelectionAllowSelectAll?: boolean
  renderRowSelection?: (records: RecordType[]) => ReactNode
  isRowReorderable?: (record: RecordType) => boolean
  hideSearchBar?: boolean
  searchBarWidth?: string
  onClickRow?: (record: RecordType) => void
}

export interface SortInput<ColumnIds = any, FiltersType = any> {
  fieldName: keyof FiltersType
  columnId: ColumnIds
}

export interface FilterInput<FiltersType = any> {
  label: string
  type: FilterType
  fieldName: keyof FiltersType
  checkboxOptions?: CheckboxOption[]
  caption?: string
}

export const ReorderClassName = 'table-reorder-icon'

export enum FilterInputRenderType {
  Collapse,
  FormFields,
}
