import {
  faArrowsRepeat,
  faFileCheck,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { styled } from 'styled-components'

import { FileUploadStatuses } from '../../../hooks/use-file-upload-state'
import { useTheme } from '../../../hooks/use-theme'
import { formatBytes } from '../../../utils'
import Text, { TextSize } from '../../Typography/Text'

interface Props {
  name: string
  removeFile: () => void
  status: FileUploadStatuses
  size: number
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const Done = (props: Props) => {
  const { removeFile, name, status, size } = props

  const { getTokenVal } = useTheme()

  const removeButton = (
    <Button
      type="text"
      onClick={removeFile}
      icon={<FontAwesomeIcon icon={faTrash} />}
    >
      Remove
    </Button>
  )
  const replaceButton = (
    <Button type="text" icon={<FontAwesomeIcon icon={faArrowsRepeat} />}>
      Replace
    </Button>
  )
  const button =
    status === FileUploadStatuses.Done ? removeButton : replaceButton

  return (
    <Container>
      <FontAwesomeIcon
        icon={faFileCheck}
        style={{
          height: 32,
          width: 32,
          color: getTokenVal('colorSuccessBase'),
        }}
      />
      <Text size={TextSize.Base}>{name}</Text>
      <Text size={TextSize.Small}>{formatBytes(size)}</Text>
      {button}
    </Container>
  )
}
