import {
  faArrowTurnDownLeft,
  faArrowUpArrowDown,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Flex } from 'antd'
import styled from 'styled-components'

import { OperatingSystem, getCurrentOS, getIsDesktopOS } from 'src/utils/window'

import Text from '../Typography/Text'

const StyledFlex = styled(Flex)`
  margin-top: 12px;
  margin-bottom: 3px;
`

type Props = {
  count: number
}
const UniversalSearchModalFooter = ({ count }: Props) => {
  return (
    <StyledFlex align="center" gap="6px">
      <Text colorToken="colorTextTertiary" style={{ marginRight: '12px' }}>
        {count} result{count === 1 ? '' : 's'}
      </Text>
      {getIsDesktopOS() && (
        <>
          <Text
            colorToken="colorTextTertiary"
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          >
            <FontAwesomeIcon icon={faArrowUpArrowDown} />
            Select
          </Text>
          <Divider type="vertical" />
          <Text
            colorToken="colorTextTertiary"
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          >
            <FontAwesomeIcon icon={faArrowTurnDownLeft} />
            Open
          </Text>
          <Divider type="vertical" />
          <Text
            colorToken="colorTextTertiary"
            style={{ display: 'flex', alignItems: 'center', gap: '6px' }}
          >
            {getCurrentOS() === OperatingSystem.Windows ? 'Ctrl' : '⌘'}
            <FontAwesomeIcon icon={faArrowTurnDownLeft} />
            Open in new tab
          </Text>
        </>
      )}
    </StyledFlex>
  )
}

export default React.memo(UniversalSearchModalFooter)
