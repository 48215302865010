import { useCallback } from 'react'

import { faPen } from '@fortawesome/pro-regular-svg-icons'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { UpdateViewNameMutation } from 'types/graphql'

import Input from 'src/components/form/Input'
import Modal from 'src/components/Modal'
import { useUpdateViewNameMutation } from 'src/fetch/views'
import { UseDisclosureReturn } from 'src/hooks/use-disclosure'
import { TableView } from 'src/models/TableViews/TableView'

type FormValues = {
  name?: string
}

type Props = {
  view: TableView
  modalState: UseDisclosureReturn
}
const RenameViewModal = ({ view, modalState }: Props) => {
  const handleRename = useCallback(
    (result: UpdateViewNameMutation) => {
      runInAction(() => {
        view.name = result.updateViewName.name
      })
    },
    [view]
  )

  const { mutateAsync: renameView } = useUpdateViewNameMutation({
    onSuccess: handleRename,
  })

  const handleSubmit = useCallback(
    async ({ name }: FormValues) => {
      if (!name) {
        return
      }
      await runInAction(async () => {
        await renameView({
          id: view.id,
          name,
        })
      })
    },
    [renameView, view.id]
  )

  return (
    <Modal
      title="Rename View"
      icon={faPen}
      okText="Rename view"
      onOk={handleSubmit}
      form
      formInitialValues={{
        name: view.name,
      }}
      {...modalState}
    >
      <Input
        fieldName="name"
        required
        errorMessage="Views must have a name"
        label="Name"
      />
    </Modal>
  )
}

export default observer(RenameViewModal)
