import { Select as AntdSelect } from 'antd'

import {
  FieldFragment,
  FieldParametersSchema,
  FieldType,
  ForeignValueType,
} from '../../../../types/graphql'
import { LabelSublabelOption } from '../../shared/StyledComponents'
import { FormFieldInputTypeDisplayMap } from '../types'

export interface AddFieldInputOptionType {
  value: FieldType
  label: string
  sublabel: string
}

export const createFieldInputOption = (
  type: FieldType,
  sublabel: string
): AddFieldInputOptionType => ({
  value: type,
  label: FormFieldInputTypeDisplayMap[type],
  sublabel,
})

export const createForeignValueFieldInputOption = () =>
  createFieldInputOption(
    'ForeignValue',
    'A secure input for capturing sensitive personal information like SSNs or EINs.'
  )

export const FormInputs: AddFieldInputOptionType[] = [
  createFieldInputOption(
    'ShortText',
    'A short piece of text like the name of a development'
  ),
  createFieldInputOption(
    'LongText',
    'A longer piece of text like a job description or property history description'
  ),
  createFieldInputOption(
    'Number',
    'A number input like the number of lots in a development or number of employees on a job site'
  ),
  createFieldInputOption(
    'Date',
    'A date input like the desired start or end dates of a project or any associated deadlines'
  ),
  createFieldInputOption(
    'Radio',
    'An input for choosing one option from a predefined set of options'
  ),
  createFieldInputOption(
    'Dropdown',
    'An input for choosing one or more options from a predefined set of options'
  ),
  createFieldInputOption('Currency', 'An input for entering dollar amounts'),
  createFieldInputOption(
    'Checkbox',
    'An input for capturing yes/no values. Also useful for entering required affidavits.'
  ),
  createFieldInputOption(
    'Address',
    'An input for capturing addresses with GIS integration.'
  ),
  createFieldInputOption(
    'Contact',
    'An input for capturing personal information - name, email, and phone number.'
  ),
  createFieldInputOption('Signature', 'A signature'),
  createFieldInputOption('Header', 'A big header'),
  createFieldInputOption('ExplanationText', 'Small text explaining a section'),
  createFieldInputOption('LineBreak', 'A horizontal line break'),
  createFieldInputOption(
    'RecordCollection',
    'An input for connecting the Record to one or more projects.'
  ),
  createFieldInputOption(
    'CodeBookItem',
    'A code book item defined in settings'
  ),
]

export const renderAddFieldInputOption = (
  option: AddFieldInputOptionType,
  index: number
) => {
  return (
    <AntdSelect.Option
      key={`option-${index}`}
      value={option.value}
      label={option.label}
    >
      <LabelSublabelOption label={option.label} sublabel={option.sublabel} />
    </AntdSelect.Option>
  )
}

export enum ContactFieldName {
  FirstName = 'firstName',
  LastName = 'lastName',
  CompanyName = 'companyName',
  PhoneNumber = 'phoneNumber',
  FaxNumber = 'faxNumber',
  Email = 'email',
  Title = 'title',
  Address = 'address',
}

export enum ContactAddressFieldNames {
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  State = 'state',
  Zip = 'zip',
}

type ContactFieldTypes = {
  [key in ContactFieldName]: boolean
}
type ContactFieldRequiredTypes = {
  [key in `${ContactFieldName}Required`]: boolean
}

export interface AddFieldInputFormValues
  extends ContactFieldTypes,
    ContactFieldRequiredTypes {
  type: FieldType
  label: string
  tooltip: string
  required: boolean
  options: string[]
  checkboxText: string
  isMainAddress: boolean
  dropdownAllowMultiple: boolean
  showLineBreak: boolean
  recordTemplateIds: number[]
  recordTypeSubmitAsText: string
  recordPreventSubmission: boolean
  foreignValueType: ForeignValueType | undefined
  // contact fields
  isMainContact: boolean
  allowMultiple: boolean
}

const makeParametersForContact = (
  values: AddFieldInputFormValues
): FieldParametersSchema | Record<string, never> => {
  if (values.type !== 'Contact') {
    return {}
  }
  const {
    isMainContact,
    firstName,
    firstNameRequired,
    lastName,
    lastNameRequired,
    companyName,
    companyNameRequired,
    phoneNumber,
    phoneNumberRequired,
    faxNumber,
    faxNumberRequired,
    email,
    emailRequired,
    address,
    addressRequired,
    title,
    titleRequired,
  } = values
  return {
    contact: {
      isMainContact,
      firstName: {
        isPresent: firstName,
        isRequired: firstNameRequired,
      },
      lastName: {
        isPresent: lastName,
        isRequired: lastNameRequired,
      },
      businessName: {
        isPresent: companyName,
        isRequired: companyNameRequired,
      },
      phoneNumber: {
        isPresent: phoneNumber,
        isRequired: phoneNumberRequired,
      },
      faxNumber: {
        isPresent: faxNumber,
        isRequired: faxNumberRequired,
      },
      emailAddress: {
        isPresent: email,
        isRequired: emailRequired,
      },
      address: {
        isPresent: address,
        isRequired: addressRequired,
      },
      title: {
        isPresent: title,
        isRequired: titleRequired,
      },
    },
  }
}

export const makeParameters = (
  values: AddFieldInputFormValues
): FieldParametersSchema => {
  const {
    isMainAddress,
    checkboxText,
    options,
    dropdownAllowMultiple,
    type,
    showLineBreak,
    recordTypeSubmitAsText,
    recordPreventSubmission,
  } = values
  return {
    address: isMainAddress
      ? {
          isMainAddress,
        }
      : undefined,
    ...makeParametersForContact(values),
    checkboxText,
    options: options?.length ? options : undefined,
    ...(dropdownAllowMultiple || type !== 'Dropdown'
      ? {}
      : {
          dropdown: {
            limit: 1,
          },
        }),
    ...(type !== 'LineBreak' || showLineBreak
      ? {}
      : { lineBreak: { hidden: true } }),
    ...(type === 'Record'
      ? {
          record: {
            submitText: recordTypeSubmitAsText || '',
            preventSubmission: recordPreventSubmission,
          },
        }
      : {}),
  }
}

export const getInitialFieldInputFormValues = (
  field?: FieldFragment
): AddFieldInputFormValues => {
  if (!field) {
    return {
      type: undefined,
      label: '',
      tooltip: '',
      required: true,
      options: [],
      checkboxText: '',
      isMainAddress: false,
      isMainContact: false,
      dropdownAllowMultiple: true,
      showLineBreak: true,
      recordTemplateIds: [],
      recordTypeSubmitAsText: '',
      recordPreventSubmission: false,
      foreignValueType: undefined,
      firstName: false,
      firstNameRequired: false,
      lastName: false,
      lastNameRequired: false,
      companyName: false,
      companyNameRequired: false,
      phoneNumber: false,
      phoneNumberRequired: false,
      faxNumber: false,
      faxNumberRequired: false,
      email: false,
      emailRequired: false,
      address: false,
      addressRequired: false,
      title: false,
      titleRequired: false,
      allowMultiple: true,
    }
  }
  const { label, type, required, tooltip, recordTemplates, foreignValueType } =
    field
  const recordTemplateIds = recordTemplates?.map(({ id }) => id) || []
  const parameters = field.parameters as FieldParametersSchema

  return {
    type,
    label: label ?? '',
    tooltip: tooltip ?? '',
    required: required ?? true,
    options: (parameters?.options || []).filter((o) => !!o),
    checkboxText: parameters?.checkboxText ?? '',
    isMainAddress: parameters?.address?.isMainAddress || false,
    isMainContact: parameters?.contact?.isMainContact || false,
    dropdownAllowMultiple: !parameters?.dropdown?.limit,
    showLineBreak: !parameters?.lineBreak?.hidden,
    recordTemplateIds,
    recordTypeSubmitAsText: parameters?.record?.submitText || '',
    recordPreventSubmission: parameters?.record?.preventSubmission || false,
    foreignValueType: foreignValueType || undefined,
    firstName: parameters?.contact?.firstName?.isPresent || false,
    firstNameRequired: parameters?.contact?.firstName?.isRequired || false,
    lastName: parameters?.contact?.lastName?.isPresent || false,
    lastNameRequired: parameters?.contact?.lastName?.isRequired || false,
    companyName: parameters?.contact?.businessName?.isPresent || false,
    companyNameRequired: parameters?.contact?.businessName?.isRequired || false,
    phoneNumber: parameters?.contact?.phoneNumber?.isPresent || false,
    phoneNumberRequired: parameters?.contact?.phoneNumber?.isRequired || false,
    faxNumber: parameters?.contact?.faxNumber?.isPresent || false,
    faxNumberRequired: parameters?.contact?.faxNumber?.isRequired || false,
    email: parameters?.contact?.emailAddress?.isPresent || false,
    emailRequired: parameters?.contact?.emailAddress?.isRequired || false,
    address: parameters?.contact?.address?.isPresent || false,
    addressRequired: parameters?.contact?.address?.isRequired || false,
    title: parameters?.contact?.title?.isPresent || false,
    titleRequired: parameters?.contact?.title?.isRequired || false,
    allowMultiple: field.allowMultiple,
  }
}
