import { navigate } from '@redwoodjs/router'

import { useHomePage } from '../../hooks/use-home-page'
import { useMyUser } from '../AppStateContextLayout/utils'

interface Props {
  children?: React.ReactNode
}

export const GovAdminLayout = (props: Props) => {
  const { children } = props
  const me = useMyUser()
  const homePage = useHomePage()

  if (!me) {
    return <>{children}</>
  }
  if (me.isGovWellStaff) {
    return <>{children}</>
  }
  if (me.type === 'Government' && me.group === 'Admin') {
    return <>{children}</>
  }
  navigate(homePage)
  return null
}

export default GovAdminLayout
