import { faTrash } from '@fortawesome/pro-regular-svg-icons'

import { useDeleteRecordTaskInspectionMutation } from '../../fetch/recordTaskInspectionAttempts'
import Modal from '../Modal'
import Text, { TextSize } from '../Typography/Text'

import { InspectionsTabModalProps } from './types'

export const DeleteInspectionModal = (props: InspectionsTabModalProps) => {
  const {
    modalState,
    recordTaskInspectionId,
    inspectionTemplateName,
    refetch,
  } = props

  const { mutateAsync: deleteFn } = useDeleteRecordTaskInspectionMutation()

  const onOk = async () => {
    await deleteFn({
      id: recordTaskInspectionId,
    })
    await refetch()
    modalState.close()
  }

  const title = `Delete ${inspectionTemplateName} Inspection`

  return (
    <Modal
      {...modalState}
      icon={faTrash}
      onOk={onOk}
      title={title}
      okText={title}
    >
      <Text size={TextSize.Base} strong margin="0 0 24px">
        Are you sure you would like delete the {inspectionTemplateName}{' '}
        inspection?
      </Text>
    </Modal>
  )
}
