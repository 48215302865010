import { SizeMapToken } from 'antd/es/theme/interface'

export const SizeMap: SizeMapToken = {
  sizeXXL: 48,
  sizeXL: 32,
  sizeLG: 24,
  sizeMD: 20,
  sizeMS: 16,
  size: 16,
  sizeSM: 12,
  sizeXS: 8,
  sizeXXS: 4,
}
