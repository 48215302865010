import { Button } from 'antd'
import Title from 'antd/es/typography/Title'
import { styled } from 'styled-components'

import { back, navigate } from '@redwoodjs/router'

import TopBarTemplate, {
  TopbarTitle,
} from '../../components/Topbar/TopbarTemplate'
import { TopbarPageLayoutContainer } from '../../layouts/TopbarLayout/TopbarLayout'
import { Logo, LogoColor } from '../svgs/Logo'

import { MailToLink } from './StyledComponents'

const Main = styled.div`
  height: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  href?: string
}

export const ErrorPage = (props: Props) => {
  const { href } = props

  return (
    <TopbarPageLayoutContainer>
      <TopBarTemplate
        color="rgba(10, 46, 87, 1)"
        title={<TopbarTitle>GovWell</TopbarTitle>}
        image={<Logo color={LogoColor.White} />}
        onClickImage={() => {
          if (href) {
            navigate(href)
            window?.location?.reload?.()
          } else {
            back()
          }
        }}
      />
      <Main>
        <Title level={3} style={{ width: '30%', textAlign: 'center' }}>
          Oops! A bug occurred. Click the button below to refresh the page, or
          try going back to a previous page. If that doesn&apos;t solve the
          issue, please contact GovWell tech support at{' '}
          <MailToLink emailAddress="help@govwelltech.com">
            help@govwelltech.com
          </MailToLink>
          .
        </Title>
        <Button
          size="large"
          type="primary"
          onClick={async () => {
            if (href) {
              navigate(href)
              window?.location?.reload?.()
            } else {
              window?.location?.reload?.()
            }
          }}
        >
          Refresh the page
        </Button>
      </Main>
    </TopbarPageLayoutContainer>
  )
}

export default ErrorPage
