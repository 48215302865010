import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

interface Props {
  icon: IconDefinition
  hovered: boolean
}
export const RecordHistoryItemLeftIcon = (props: Props) => {
  const { icon, hovered } = props
  return (
    <FontAwesomeIcon
      icon={icon}
      style={{
        marginRight: 'auto',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '20px',
        height: '20px',
        color: hovered ? 'rgba(250, 84, 28, 1)' : 'rgb(100, 100, 100, 1)',
      }}
    />
  )
}

export const AvatarImage = styled.img<{ $size: number }>`
  border-radius: 50%;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`
