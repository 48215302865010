import React from 'react'

import Lottie from 'react-lottie'
import styled from 'styled-components'

// Define props for the component
interface LottieAnimationProps {
  animationData: any
  width?: number | string
  height?: number | string
}

// Styled component for the animation container
const AnimationContainer = styled.div<{
  width: number | string
  height: number | string
}>`
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};
  display: flex;
  justify-content: center;
  align-items: center;
`

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  animationData,
  width = '100%',
  height = '100%',
}) => {
  // Default options for Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <AnimationContainer width={width} height={height}>
      <Lottie options={defaultOptions} height={height} width={width} />
    </AnimationContainer>
  )
}

export default LottieAnimation
