import { useCallback, useEffect, useState } from 'react'

import { faFilter } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import { Popover } from 'govwell-ui'
import { useMenuKeyboardShortcuts } from 'govwell-ui/components/Menu/use-menu-keyboard-shortcuts'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import {
  MenuContentStyles,
  MenuItemStyleProps,
  MenuItemStyles,
} from 'src/govwell-ui/components/Menu'
import { useCurrentBreakpoint } from 'src/hooks/use-current-breakpoint'
import useDisclosure from 'src/hooks/use-disclosure'
import { FilterManager } from 'src/models/TableViews/FilterManager'
import { TableColumnTemplate } from 'src/models/TableViews/TableColumnTemplate'

import FilterEditor from './FilterEditor'

const StyledContent = styled(Popover.Content)`
  ${MenuContentStyles}
  max-height: var(--radix-popover-content-available-height);
  width: fit-content;
  padding: 6px;
`
const StyledItem = styled.div<MenuItemStyleProps>`
  ${MenuItemStyles}
`
const StyledSubmenuContent = styled(Popover.Content)`
  ${MenuContentStyles};
  max-height: var(--radix-popover-content-available-height);
  width: fit-content;
  max-width: 100vw;
  padding: 6px;
`

type Props = {
  filterManager: FilterManager
}
const AddFilterButton = ({ filterManager }: Props) => {
  const { isOpen, open: onOpen, close: onClose } = useDisclosure()
  const { isSmallScreen } = useCurrentBreakpoint()
  const [selectedColumnTemplate, setSelectedColumnTemplate] =
    useState<TableColumnTemplate>()

  const handleClickColumnTemplate = useCallback(
    (ct: TableColumnTemplate) => {
      runInAction(() => {
        if (
          selectedColumnTemplate &&
          ct.columnType === selectedColumnTemplate.columnType
        ) {
          // Close filter if the open type was clicked
          setSelectedColumnTemplate(undefined)
        } else {
          setSelectedColumnTemplate(ct)
        }
      })
    },
    [selectedColumnTemplate]
  )

  const resetSelectedColumnTemplate = useCallback(() => {
    setSelectedColumnTemplate(undefined)
  }, [])

  const handleClose = useCallback(() => {
    resetSelectedColumnTemplate()
    onClose()
  }, [onClose, resetSelectedColumnTemplate])

  const renderSubmenu = useCallback(() => {
    if (!selectedColumnTemplate) {
      return null
    }
    return (
      <FilterEditor
        filterManager={filterManager}
        columnTemplate={selectedColumnTemplate}
      />
    )
  }, [filterManager, selectedColumnTemplate])

  const [activeIndex, setActiveIndex] = useState(0)
  const { menuItemRefs } = useMenuKeyboardShortcuts({
    activeIndex,
    count: filterManager.filterableColumnTemplates.length,
    isEnabled: isOpen && !selectedColumnTemplate,
    onEscape: resetSelectedColumnTemplate,
    onSelect: () => {
      runInAction(() => {
        setSelectedColumnTemplate(
          filterManager.filterableColumnTemplates[activeIndex]
        )
      })
    },
    setActiveIndex,
  })

  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(0)
    }
  }, [isOpen, setActiveIndex])

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={handleClose}>
      <Popover.Trigger asChild>
        {isSmallScreen() ? (
          <div>
            <Tooltip title="Add filters">
              <Button
                icon={<FontAwesomeIcon icon={faFilter} />}
                type="default"
              />
            </Tooltip>
          </div>
        ) : (
          <Button icon={<FontAwesomeIcon icon={faFilter} />} type="default">
            Add filters
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <StyledContent align="start" sideOffset={6} tabIndex={-1} role="menu">
          {filterManager.filterableColumnTemplates.map((ct, index) => (
            <Popover
              isOpen={selectedColumnTemplate?.columnType === ct.columnType}
              key={ct.columnType}
              onClose={resetSelectedColumnTemplate}
            >
              <Popover.Trigger asChild>
                <StyledItem
                  role="menuitem"
                  ref={(el) => menuItemRefs.current.set(index, el)}
                  onMouseMove={() => {
                    if (!selectedColumnTemplate) {
                      setActiveIndex(index)
                    }
                  }}
                  onClick={() => {
                    setActiveIndex(index)
                    handleClickColumnTemplate(ct)
                  }}
                  {...{
                    ['data-selected']: index === activeIndex, // controls option remaining highlighted when submenu is expanded
                  }}
                >
                  {ct.defaultLabelSentenceCase}
                </StyledItem>
              </Popover.Trigger>
              <StyledSubmenuContent
                align="start"
                side={isSmallScreen() ? 'bottom' : 'right'}
                sideOffset={6}
                role="menu"
              >
                {renderSubmenu()}
              </StyledSubmenuContent>
            </Popover>
          ))}
        </StyledContent>
      </Popover.Portal>
    </Popover>
  )
}

export default observer(AddFilterButton)
