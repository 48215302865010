import { useEffect } from 'react'

import { useUser } from '@clerk/clerk-react'
import { datadogRum } from '@datadog/browser-rum'

import { DatadogSettings, EnvVariables } from 'src/constants'
import { AppStateContextData } from 'src/layouts/AppStateContextLayout/types'

enum ContextKey {
  MyOrganization = 'myOrganization',
}

export const useMonitoring = (contextData: AppStateContextData) => {
  useEffect(() => {
    // Initialize DataDog
    if (
      EnvVariables.DatadogRumEnabled === 'false' ||
      !EnvVariables.DatadogClientToken
    ) {
      return
    }
    datadogRum.init({
      applicationId: DatadogSettings.datadogApplicationId,
      clientToken: EnvVariables.DatadogClientToken,
      site: DatadogSettings.datadogSite,
      service: EnvVariables.DatadogService,
      env: EnvVariables.DatadogEnv,
      version: EnvVariables.VercelDeploymentSha,
      allowedTracingUrls: [
        // IMPORTANT! we do not use https://*.govwelltech.com because we have
        // clerk proxied at clerk.govwelltech.com which doesnt accept the
        // datadog headers! changing this without caution can cause an outage!
        // check cloudflare for any other services that could be affected by
        // changing this!
        /https:\/\/(app|dev|demo)\.govwell(tech)?\.com/,
      ],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      storeContextsAcrossPages: true,
    })
  }, [])

  const { user } = useUser()
  useEffect(() => {
    // Set user data
    if (user) {
      const id = user?.id
      const name = user?.fullName
      const email = user?.primaryEmailAddress?.emailAddress
      if (!name) {
        return
      }

      datadogRum.setUser({
        id,
        name,
        email,
      })
    }
  }, [user])

  const myOrg = contextData?.organization
  useEffect(() => {
    // Set my org data
    if (myOrg) {
      const myOrgContext = {
        id: myOrg.id,
        uuid: myOrg.uuid,
        displayName: myOrg.displayName,
        city: myOrg.city,
        slug: myOrg.slug,
      }

      datadogRum.setGlobalContextProperty(
        ContextKey.MyOrganization,
        myOrgContext
      )
    }
  }, [myOrg])
}

export const resetMonitoring = () => {
  datadogRum.clearUser()
  datadogRum.setGlobalContext({})
}
