import { MouseEventHandler } from 'react'

import { faExclamation, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import styled from 'styled-components'

import { BreakpointComponents } from '../../constants/breakpoints'
import { useCurrentBreakpoint } from '../../hooks/use-current-breakpoint'
import { UploadMultipleFilesFileUploadState } from '../../hooks/use-files-upload-state'
import { useTheme } from '../../hooks/use-theme'
import { getErrorName } from '../../utils/file'
import { StylePropsMobile } from '../shared/StyledComponents'
import Text, { TextSize } from '../Typography/Text'

import { FileCard } from './StyledComponents'

const Container = styled(FileCard)`
  text-align: left;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Top = styled.div<StylePropsMobile>`
  flex: 1;
  margin-right: auto;
  display: flex;
  gap: ${(props) => (props.$small ? '8px' : '12px')};
  align-items: center;
`
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
`

interface Props extends UploadMultipleFilesFileUploadState {
  onRemove: () => void
}

export const ErrorFileCard = (props: Props) => {
  const { getTokenVal } = useTheme()
  const onClick: MouseEventHandler = (
    e: React.MouseEvent<Element, MouseEvent>
  ) => e.stopPropagation()
  const { errorType, name, onRemove } = props
  const { isSmall } = useCurrentBreakpoint()
  const small = isSmall(BreakpointComponents.FileUpload)
  return (
    <Container onClick={onClick} $small={small}>
      <Top $small={small}>
        <FontAwesomeIcon
          icon={faExclamation}
          color={getTokenVal('colorErrorBase')}
          style={{ fontSize: small ? 16 : 20 }}
        />
        <Text size={TextSize.Base} style={{ flex: 1 }}>
          {name}
        </Text>
      </Top>
      <Bottom>
        <Text size={TextSize.Small} style={{ whiteSpace: 'nowrap' }}>
          {getErrorName(errorType || '')}
        </Text>
        <Button
          type="text"
          size="small"
          onClick={onRemove}
          icon={<FontAwesomeIcon icon={faTrash} />}
        >
          {!small && 'Remove'}
        </Button>
      </Bottom>
    </Container>
  )
}
