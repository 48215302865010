import { Form } from 'antd'
import { ColumnsType } from 'antd/es/table'

import Switch from 'src/components/form/Switch'

import { useTable } from '../../../hooks/use-table'
import { TableV2 } from '../../TableV2'
import Text, { TextSize } from '../../Typography/Text'

import { ContactFieldName } from './FieldInputUtil'
import { AddlInputProps, makeFieldName } from './util'

interface Row {
  id: string
  fieldName: string
  requiredFieldName: string
}

export const ContactFieldNameDisplayMap: Record<ContactFieldName, string> = {
  firstName: 'First Name',
  lastName: 'Last Name',
  companyName: 'Business Name',
  phoneNumber: 'Phone Number',
  faxNumber: 'Fax Number',
  email: 'Email Address',
  address: 'Address',
  title: 'Title',
}

const contactFieldNames: string[] = [
  'firstName',
  'lastName',
  'companyName',
  'phoneNumber',
  'faxNumber',
  'email',
  'address',
  'title',
]

enum ContactInputTypes {
  ShowField,
  IsFieldRequired,
}

export const ContactInputs = (props: AddlInputProps) => {
  const { fieldNameSuffix } = props
  const rows: Row[] = contactFieldNames.map((fieldName) => ({
    id: fieldName,
    fieldName: makeFieldName(fieldName, fieldNameSuffix),
    requiredFieldName: makeFieldName(`${fieldName}Required`, fieldNameSuffix),
  }))
  const tableProps = useTable<Row>({ data: rows })
  const form = Form.useFormInstance()
  Form.useWatch([], form)

  const columns: ColumnsType<Row> = [
    {
      title: 'Field',
      render: (row: Row) =>
        ContactFieldNameDisplayMap[row?.fieldName as ContactFieldName],
      width: '33%',
    },
    {
      title: (
        <Header rows={rows} text="Show?" type={ContactInputTypes.ShowField} />
      ),
      render: (row: Row) => (
        <Switch
          noMargin
          fieldName={row.fieldName}
          onChange={(checked: boolean) => {
            if (!checked) {
              form.setFieldValue(row.requiredFieldName, false)
            }
          }}
        />
      ),
      width: '33%',
    },
    {
      title: (
        <Header
          rows={rows}
          text="Required?"
          type={ContactInputTypes.IsFieldRequired}
        />
      ),
      render: (row: Row) => {
        return (
          <Switch
            noMargin
            fieldName={row.requiredFieldName}
            onChange={(checked: boolean) => {
              if (checked) {
                form.setFieldValue(row.fieldName, true)
              }
            }}
          />
        )
      },
      width: '33%',
    },
  ]

  return (
    <>
      <Switch
        label="Main Contact"
        caption="We will show this contact information on the Contacts tab instead of the applicant's contact info"
        fieldName={makeFieldName('isMainContact', props.fieldNameSuffix)}
      />
      <TableV2<Row> {...tableProps} columns={columns} />
    </>
  )
}

interface HeaderProps {
  text: string
  type: ContactInputTypes
  rows: Row[]
}
const Header = (props: HeaderProps) => {
  const { text, rows, type } = props

  const form = Form.useFormInstance()
  const fieldNames = rows.map((r) =>
    type === ContactInputTypes.ShowField ? r.fieldName : r.requiredFieldName
  )
  Form.useWatch([], form)
  const { setFieldValue, getFieldValue } = form

  const showSelectAll = !fieldNames.every((fn) => !!getFieldValue(fn))
  const selectText = showSelectAll ? 'Select all' : 'Select none'
  const onClick = () => {
    fieldNames.forEach((fn) => setFieldValue(fn, showSelectAll))
  }

  return (
    <Text size={TextSize.Base} inline>
      <Text size={TextSize.Base} inline>
        {text}
      </Text>
      <Text
        size={TextSize.Base}
        inline
        colorToken="cyan-7"
        pointer
        onClick={onClick}
        margin="0 0 0 4px"
      >
        {selectText}
      </Text>
    </Text>
  )
}
