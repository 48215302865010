/// <reference types="user-agent-data-types" />

export enum OperatingSystem {
  Android = 'Android',
  iOS = 'iOS',
  Linux = 'Linux',
  Mac = 'Mac',
  Unknown = 'Unknown',
  Windows = 'Windows',
}
export const getCurrentOS = () => {
  // if a browser has no support for navigator.userAgentData.platform use platform as fallback
  const userAgent = (
    navigator.userAgentData?.platform ?? navigator.platform
  ).toLowerCase()

  if (userAgent.includes('win')) {
    return OperatingSystem.Windows
  } else if (userAgent.includes('android')) {
    return OperatingSystem.Android
  } else if (userAgent.includes('mac')) {
    return OperatingSystem.Mac
  } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    return OperatingSystem.iOS
  } else if (userAgent.includes('linux')) {
    return OperatingSystem.Linux
  }
  return OperatingSystem.Unknown
}

export const getIsDesktopOS = () => {
  const currentOS = getCurrentOS()
  return [
    OperatingSystem.Windows,
    OperatingSystem.Mac,
    OperatingSystem.Linux,
  ].includes(currentOS)
}
