import { faWarning } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from 'antd'
import styled from 'styled-components'

import { FatalErrorBoundary } from '@redwoodjs/web'

import Text from 'src/components/Typography/Text'

const WarningContainer = styled(Flex)`
  border: solid 1px ${({ theme }) => theme['orange-3']};
  background-color: ${({ theme }) => theme['orange-1']};
  border-radius: 4px;
  padding: 12px;
  width: fit-content;
`

type Props = {
  children: React.ReactNode
}
const FormFieldErrorBoundary = ({ children }: Props) => {
  return (
    <FatalErrorBoundary
      page={() => (
        <WarningContainer align="center" gap="6px">
          <FontAwesomeIcon icon={faWarning} />
          <Text>Something went wrong. GovWell is looking into it.</Text>
        </WarningContainer>
      )}
    >
      {children}
    </FatalErrorBoundary>
  )
}

export default React.memo(FormFieldErrorBoundary)
