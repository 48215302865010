import { useContext } from 'react'

import { NotificationInstance } from 'antd/es/notification/interface'

type NotificationContextType = {
  notification: NotificationInstance
}

export const NotificationContext = React.createContext<NotificationContextType>(
  {} as NotificationContextType
)

export const useNotification = () => {
  return useContext(NotificationContext)
}
