import { makeAutoObservable } from 'mobx'
import {
  DateDirection,
  DelayType,
  ViewFilterDateFragment,
  ViewFilterDateType,
} from 'types/graphql'

export class TableViewDateFilter {
  public exactEnd?: string | null
  public exactStart?: string | null
  public relativeToTodayCount?: number | null
  public relativeToTodayDelayType?: DelayType | null
  public relativeToTodayDirection?: DateDirection | null
  public type: ViewFilterDateType

  constructor(args: {
    exactEnd?: string | null
    exactStart?: string | null
    relativeToTodayCount?: number | null
    relativeToTodayDelayType?: DelayType | null
    relativeToTodayDirection?: DateDirection | null
    type: ViewFilterDateType
  }) {
    this.exactEnd = args.exactEnd
    this.exactStart = args.exactStart
    this.relativeToTodayCount = args.relativeToTodayCount
    this.relativeToTodayDelayType = args.relativeToTodayDelayType
    this.relativeToTodayDirection = args.relativeToTodayDirection
    this.type = args.type
    makeAutoObservable(this)
  }

  public static isDatePopulated(
    protocol: ViewFilterDateFragment | null | undefined
  ) {
    return (
      !!protocol &&
      (protocol.type === 'IsNotNull' ||
        protocol.type === 'IsNull' ||
        !!protocol.exactEnd ||
        !!protocol.exactStart ||
        (!!protocol.relativeToTodayCount &&
          !!protocol.relativeToTodayDelayType &&
          !!protocol.relativeToTodayDirection))
    )
  }

  public equals(
    otherFilterProtocol: ViewFilterDateFragment | null | undefined
  ): boolean {
    const isThisDateEmpty = !TableViewDateFilter.isDatePopulated(this.protocol)
    const isOtherDateEmpty =
      !TableViewDateFilter.isDatePopulated(otherFilterProtocol)
    return (
      (isThisDateEmpty && isOtherDateEmpty) ||
      (this.protocol?.type === otherFilterProtocol?.type &&
        this.protocol?.exactEnd === otherFilterProtocol?.exactEnd &&
        this.protocol?.exactStart === otherFilterProtocol?.exactStart &&
        this.protocol?.relativeToTodayCount ===
          otherFilterProtocol?.relativeToTodayCount &&
        this.protocol?.relativeToTodayDelayType ===
          otherFilterProtocol?.relativeToTodayDelayType &&
        this.protocol?.relativeToTodayDirection ===
          otherFilterProtocol?.relativeToTodayDirection)
    )
  }

  public get isPopulated(): boolean {
    return (
      this.type === 'IsNotNull' ||
      this.type === 'IsNull' ||
      !!this.exactEnd ||
      !!this.exactStart ||
      (!!this.relativeToTodayCount &&
        !!this.relativeToTodayDelayType &&
        !!this.relativeToTodayDirection)
    )
  }

  public get protocol(): ViewFilterDateFragment | undefined {
    return this.isPopulated
      ? {
          exactEnd: this.exactEnd,
          exactStart: this.exactStart,
          relativeToTodayCount: this.relativeToTodayCount,
          relativeToTodayDelayType: this.relativeToTodayDelayType,
          relativeToTodayDirection: this.relativeToTodayDirection,
          type: this.type,
        }
      : undefined
  }
}
