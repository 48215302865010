import { ReactNode } from 'react'

import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Tag } from 'antd'
import styled from 'styled-components'
import {
  FieldFragment,
  ViewRecordTaskInspectionAttemptFragment,
} from 'types/graphql'

import { useDownloadInspectionReport } from '../../../fetch/recordTaskInspectionAttempts'
import { filterNil, formatUserName, isImage } from '../../../utils'
import { DateFormats, formatDate } from '../../../utils/date'
import { FieldDisplay } from '../../Field/FieldDisplay'
import { TableLinkNewPage } from '../../shared/StyledComponents'
import Text, { TextSize } from '../../Typography/Text'

interface Props {
  attempt: ViewRecordTaskInspectionAttemptFragment
}

const Image = styled.img`
  width: 100%;
  height: auto;
`

export const RecordTaskInspectionAttemptResults = (props: Props) => {
  const {
    attempt: { id, fieldGroup, hasReport },
  } = props
  const fields = fieldGroup?.fields || []

  const displayVal = (field: FieldFragment) => {
    if (field.type === 'File' || field.type === 'Files') {
      const files = filterNil(
        [field.file ? [field.file] : [], field.files || []].flatMap((f) => f) ??
          []
      )
      if (!files.length) {
        return null
      }
      const nonImageFiles = files.filter((f) => !isImage(f.type))
      const imageFiles = files.filter((f) => isImage(f.type))
      return (
        <>
          {nonImageFiles.map((file) => {
            if (!file.url?.signedUrl) {
              return null
            }
            return (
              <div key={file.id}>
                <TableLinkNewPage href={file.url?.signedUrl}>
                  {file.name}
                </TableLinkNewPage>
              </div>
            )
          })}
          {imageFiles.flatMap((file) => {
            if (!file?.url?.signedUrl) {
              return []
            }
            return (
              <div key={file.id}>
                <Image src={file.url?.signedUrl} />
              </div>
            )
          })}
        </>
      )
    }
    return <FieldDisplay input={field} />
  }

  const { fn: downloadReport, isLoading } = useDownloadInspectionReport(id)

  return (
    <Flex vertical gap="12px">
      <Text size={TextSize.Large}>Inspection Details</Text>
      <Val field="Result" val={<InspectionAttemptStatusDisplay {...props} />} />
      <TimesDisplay {...props} />
      {hasReport && (
        <>
          <Text size={TextSize.Large}>Inspection Report</Text>
          <Button
            loading={isLoading}
            onClick={downloadReport}
            icon={<FontAwesomeIcon icon={faDownload} />}
            style={{ width: 'fit-content' }}
          >
            Download Inspection Report
          </Button>
        </>
      )}
      {!!fields.length && <Text size={TextSize.Large}>Inspection Notes</Text>}
      {fields.flatMap((field, index) => {
        const val = displayVal(field)
        if (!val) {
          return null
        }
        return <Val field={field.label} val={val} key={`field-${index}`} />
      })}
    </Flex>
  )
}

export const InspectionAttemptStatusDisplay = (props: Props) => {
  const isPassing = props.attempt.result?.isPassing
  const name = props.attempt.result?.name

  return <Tag color={isPassing ? 'green' : 'red'}>{name}</Tag>
}

interface ValProps {
  field: string | null | undefined
  val: ReactNode
}
const Val = (props: ValProps) => {
  const { field, val } = props
  return (
    <Flex vertical gap="4px">
      <Text size={TextSize.Base} strong underline>
        {field}
      </Text>
      <Text size={TextSize.Base} strong margin="0 0 24px 0">
        {val}
      </Text>
    </Flex>
  )
}

const TimesDisplay = (props: Props) => {
  const {
    attempt: {
      loggedByUser,
      loggedAt,
      inspectionStartedAt,
      inspectionEndedAt,
      scheduledAt,
      scheduledByUser,
      requestedAt,
      requestedByUser,
    },
  } = props

  return (
    <>
      {loggedAt && (
        <Val
          field="Inspection Logged"
          val={`${formatDate(
            loggedAt,
            DateFormats.FullDateWithDayOfWeek
          )} by ${formatUserName(loggedByUser)}`}
        />
      )}
      {inspectionStartedAt && inspectionEndedAt && (
        <Flex gap="24px">
          <div>
            <Val
              field="Inspection Started At"
              val={formatDate(
                inspectionStartedAt,
                DateFormats.FullDateWithDayOfWeek
              )}
            />
          </div>
          <div>
            <Val
              field="Inspection Ended At"
              val={formatDate(
                inspectionEndedAt,
                DateFormats.FullDateWithDayOfWeek
              )}
            />
          </div>
        </Flex>
      )}
      {scheduledAt && (
        <Val
          field="Inspection Scheduled"
          val={`${formatDate(
            scheduledAt,
            DateFormats.FullDateWithDayOfWeek
          )} by ${formatUserName(scheduledByUser)}`}
        />
      )}
      {requestedAt && (
        <Val
          field="Inspection Requested"
          val={`${formatDate(
            requestedAt,
            DateFormats.FullDateWithDayOfWeek
          )} by ${formatUserName(requestedByUser)}`}
        />
      )}
    </>
  )
}
