import { datadogRum } from '@datadog/browser-rum'
import { RcFile } from 'antd/es/upload'
import axios, { AxiosProgressEvent, CancelToken } from 'axios'
import { pdfjs } from 'react-pdf'
import { v4 } from 'uuid'

import { CreateFileMutationVariables, FileFragment } from '../../types/graphql'
import { EnvVariables, MaxFileSize } from '../constants'
import { PathPrefixes } from '../constants/file'
import { FileUploadStatuses } from '../hooks/use-file-upload-state'

import { DateFormats, formatDate } from './date'

import { formatBytes } from '.'

interface UploadFileParams {
  file?: RcFile
  fileUrl?: string
  bucket: string
  setFileName: (name: string) => void
  setFileUploadStatus: (status: FileUploadStatuses) => void
  setFileUploadProgress: (progress: number) => void
  setFile: (file: RcFile) => void
  setFileSize: (fileSize: number) => void
  setPath: (path: string) => void
  setFileGovWellFileId: (govWellFileId: number) => void
  setGovWellFile?: (govWellFile: FileFragment) => void
  cancelToken: CancelToken
  createFile: (input: CreateFileMutationVariables) => Promise<FileFragment>
  pathPrefix?: PathPrefixes
  onError?: (errorType: string) => void
}

export const formatFileProgress = (progress: number) =>
  Math.min(99, Math.round(progress * 100))

const isImage = (type: string) => type.startsWith('image/')

export const getFileName = (file: RcFile): string => {
  const typeWord = isImage(file?.type) ? 'Photo' : 'File'
  const now = formatDate(new Date(), DateFormats.NumericalDateTimeConcise)
  return file?.name ?? `${typeWord} uploaded at ${now}`
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export enum FileUploadErrors {
  TooBig = 'TooBig',
  UnsupportedFileType = 'UnsupportedFileType',
}

export const uploadFile = async (
  params: UploadFileParams
): Promise<FileFragment | undefined> => {
  const {
    file,
    fileUrl,
    bucket,
    setFileName,
    setFileUploadStatus,
    setFileUploadProgress,
    setFile,
    setFileSize,
    setPath,
    setFileGovWellFileId,
    setGovWellFile,
    cancelToken,
    createFile,
    pathPrefix,
    onError,
  } = params

  // for files where it's a data url (ie signature) the name doens't matter
  const fileName = file ? getFileName(file) : ''

  setFileName(fileName)
  if (file) {
    setFileSize(file.size)
  }
  setFileUploadStatus(FileUploadStatuses.Uploading)

  const getBlobAndMetadata = async () => {
    if (file) {
      return {
        blob: new Blob([file], { type: file.type }),
      }
    }
    if (!fileUrl) {
      throw new Error('fileUrl missing')
    }
    const dataUrlResponse = await axios.get(fileUrl, {
      responseType: 'blob',
    })
    return {
      blob: dataUrlResponse.data,
      size: dataUrlResponse.data.size,
      type: dataUrlResponse.data.type,
    }
  }

  const { blob, size, type } = await getBlobAndMetadata()
  const formData = new FormData()
  formData.append('cacheControl', '3600')
  formData.append('', blob)

  const generatedUuid = v4()
  const path = pathPrefix ? `${pathPrefix}/${generatedUuid}` : generatedUuid

  try {
    const arrayBuffer = await blob.arrayBuffer()
    if (file?.type === 'application/pdf') {
      await pdfjs.getDocument(new Uint8Array(arrayBuffer)).promise
    }

    const hostedPath = `${EnvVariables.SupabaseProjectUrl}/storage/v1/object/${bucket}/${path}`

    await axios.post(hostedPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${EnvVariables.SupabaseApiKey}`,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const calculatedProgress = progressEvent.total
          ? progressEvent.loaded / progressEvent.total
          : 0
        setFileUploadProgress(progressEvent.progress || calculatedProgress)
      },
      cancelToken,
    })
  } catch (e) {
    datadogRum.addError(e)
    if (e instanceof Error) {
      onError?.(e?.name)
    }
    setFileUploadStatus(FileUploadStatuses.Error)
    return
  }

  try {
    const response = await createFile({
      input: {
        size: size || file?.size, // from data url or file
        type: type || file?.type, // from data url or file
        name: fileName,
        bucket,
        path,
      },
    })

    const govWellFileId = response.id

    if (file) {
      setFile(file)
    }
    setPath(path)
    setFileUploadStatus(FileUploadStatuses.Done)
    if (govWellFileId) {
      setFileGovWellFileId(govWellFileId)
    }
    setGovWellFile?.(response)

    return response
  } catch (e) {
    if (e instanceof Error) {
      onError?.(e?.name ?? e?.message)
    }
    setFileUploadStatus(FileUploadStatuses.Error)
    return undefined
  }
}

export const getErrorName = (errorType: string): string => {
  switch (errorType) {
    case 'PasswordException': {
      return 'You may not upload a password-protected file.'
    }
    case FileUploadErrors.TooBig: {
      return `Files have a max size of ${formatBytes(MaxFileSize)}.`
    }
    case FileUploadErrors.UnsupportedFileType: {
      return 'File type not supported.'
    }
    default: {
      return `Error: ${errorType}`
    }
  }
}

export const deduplicateFileFragments = (
  files: FileFragment[]
): FileFragment[] => {
  const fileIds = new Set<number>()
  return files.reduce((prev: FileFragment[], curr: FileFragment) => {
    if (fileIds.has(curr.id)) {
      return prev
    }
    fileIds.add(curr.id)
    return [...prev, curr]
  }, [])
}

export const getIsFileExtensionAccepted = (args: {
  accept?: string
  fileName: string
}) => {
  const { accept, fileName } = args
  const acceptedFileExtensions = accept?.split(', ')
  return (
    !acceptedFileExtensions?.length ||
    acceptedFileExtensions.some((ext) => fileName.toLowerCase().endsWith(ext))
  )
}
