import {
  FieldParametersSchema,
  FieldParamsFilesAcceptedTypes,
} from '../../../../types/graphql'
import {
  AcceptedImages,
  AcceptedPdf,
  AcceptedShp,
  OrgSlugs,
} from '../../../constants'

export interface AddlInputProps {
  fieldNameSuffix: string
}

export const makeFieldName = (name: string, fieldNameSuffix: string) =>
  fieldNameSuffix ? `${name}${fieldNameSuffix}` : name

export const getAcceptedFileTypes = (
  parameters: any
): FieldParamsFilesAcceptedTypes[] => {
  const params = parameters as FieldParametersSchema
  const acceptedFileTypes = params?.files?.accept
  if (!acceptedFileTypes) {
    return ['Pdf']
  }
  const allowPdf = acceptedFileTypes?.includes('Pdf')
  const allowImage = acceptedFileTypes?.includes('Image')
  return [
    ...(allowPdf ? ['Pdf' as FieldParamsFilesAcceptedTypes] : []),
    ...(allowImage ? ['Image' as FieldParamsFilesAcceptedTypes] : []),
  ]
}

const AcceptedFileTypeMap: Record<FieldParamsFilesAcceptedTypes, string> = {
  Image: AcceptedImages,
  Pdf: AcceptedPdf,
}

export const AcceptedFileTypeDisplayMap: Record<
  FieldParamsFilesAcceptedTypes,
  string
> = {
  Image: 'Images',
  Pdf: 'PDFs',
}

export const getAcceptedFileTypesForFileInput = (
  parameters: any,
  orgSlug: string
): string => {
  const res = getAcceptedFileTypes(parameters)
  // TODO hampton asked for the ability to upload .shp, we don't have a robust way of allowing file types on a per-org or per-file input basis, so
  // for now it's based on org slug
  const base = res.map((type) => AcceptedFileTypeMap[type]).join(', ')
  if (orgSlug === OrgSlugs.Hampton || orgSlug === OrgSlugs.GovWell) {
    return `${base}, ${AcceptedShp}`
  }
  return base
}
