import { ReactNode, useEffect, useState } from 'react'

import { faSpinner, IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select as AntdSelect, Form, SelectProps } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import useDebounce from '../../hooks/use-debounce'
import { useTheme } from '../../hooks/use-theme'
import Text, { TextSize } from '../Typography/Text'

import {
  makeHiddenFieldName as makeHiddenFieldNameFn,
  makeSelectedIdFieldName,
} from './util'
import Wrapper, { FormFieldWrapperProps } from './Wrapper'

export type BaseMappedOptionType = {
  value: number | string
  label: ReactNode
  id: string
  disabled?: boolean
}
interface Props<
  OptionType,
  MappedOptionType extends BaseMappedOptionType = BaseMappedOptionType
> extends FormFieldWrapperProps {
  placeholder?: string
  search: (query: string) => Promise<OptionType[]>
  mapDataToOption: (data: OptionType) => MappedOptionType
  initialQuery?: string
  optionLabelProp: keyof MappedOptionType
  disabled?: boolean
  suffixIcon?: IconDefinition
  createOptionText?: string
  onSelectCreateOption?: () => void
  initialOptions?: OptionType[]
  size?: SelectProps['size']
  onChange?: (id: MappedOptionType['id']) => Promise<void>
  index?: number
  allowClear?: boolean
}

export interface AutocompleteFieldValues<OptionType> {
  label: ReactNode
  options: OptionType[]
  selectedId: string
  selectedOption: OptionType | undefined
}

export function AutoComplete<
  OptionType,
  MappedOptionType extends BaseMappedOptionType = BaseMappedOptionType
>(props: Props<OptionType, MappedOptionType>) {
  const {
    placeholder,
    mapDataToOption,
    search,
    initialQuery,
    disabled,
    suffixIcon,
    createOptionText,
    onSelectCreateOption,
    size,
    onChange,
    index,
    allowClear = false,
    ...formFieldWrapperProps
  } = props

  const { fieldName } = formFieldWrapperProps

  const makeHiddenFieldName = (str: string) => {
    return makeHiddenFieldNameFn(fieldName, index, str)
  }

  const selectedIdFieldName = makeSelectedIdFieldName(fieldName, index)
  const selectedOptionFieldName = makeHiddenFieldName('selectedOption')
  const labelFieldName = makeHiddenFieldName('label')
  const optionsFieldName = makeHiddenFieldName('options')

  const form = Form.useFormInstance()
  Form.useWatch(fieldName, form)
  Form.useWatch(selectedIdFieldName)
  Form.useWatch(selectedOptionFieldName)
  Form.useWatch(labelFieldName)
  Form.useWatch(optionsFieldName)

  const { getFieldValue, setFieldValue } = form
  const value: unknown = getFieldValue(selectedIdFieldName)
  const initialOptions = props.initialOptions || getFieldValue(optionsFieldName)

  const [query, setQuery] = useState(initialQuery || undefined)
  const [data, setData] = useState(initialOptions || [])
  const [loading, setLoading] = useState(!!initialQuery)

  const debouncedQuery = useDebounce(query)

  const stringifiedOptions = (() => {
    try {
      return JSON.stringify(getFieldValue(optionsFieldName))
    } catch {
      return ''
    }
  })()

  useEffect(() => {
    setData(getFieldValue(optionsFieldName) || [])
  }, [stringifiedOptions])

  useEffect(() => {
    if (debouncedQuery) {
      const fetchNewOptions = async () => {
        setLoading(true)
        const response = await search(debouncedQuery)
        setFieldValue(optionsFieldName, response)
        setData(response)
        setLoading(false)
      }
      fetchNewOptions()
    }
  }, [debouncedQuery])

  const handleChange = (
    _: unknown,
    options: DefaultOptionType | DefaultOptionType[]
  ) => {
    const option = Array.isArray(options) ? options[0] : options
    form.setFieldValue(selectedIdFieldName, option.id)
    form.setFieldValue(selectedOptionFieldName, option)
    form.setFieldValue(labelFieldName, option.label)
    onChange?.(option.id)
  }

  const { getTokenVal } = useTheme()

  const getSuffixIcon = () => {
    if (loading) {
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          style={{
            color: getTokenVal('colorPrimaryBase'),
            animation: 'spin 2s linear infinite',
            marginLeft: '4px',
          }}
        />
      )
    }
    if (suffixIcon) {
      return <FontAwesomeIcon icon={suffixIcon} />
    }
    return null
  }

  return (
    <>
      <Wrapper
        {...formFieldWrapperProps}
        fieldName={selectedIdFieldName}
        caption={
          formFieldWrapperProps.caption ||
          (createOptionText ? (
            <Text
              pointer
              colorToken="cyan-7"
              size={TextSize.Small}
              onClick={() => onSelectCreateOption?.()}
            >
              {createOptionText}
            </Text>
          ) : null)
        }
      >
        <AntdSelect<unknown>
          loading={loading}
          onChange={handleChange}
          showSearch
          searchValue={query}
          notFoundContent={null}
          value={value}
          disabled={disabled}
          optionLabelProp="label"
          defaultActiveFirstOption={true}
          suffixIcon={getSuffixIcon()}
          filterOption={false}
          onSearch={(newValue: string) => {
            setQuery(newValue)
          }}
          placeholder={
            placeholder ||
            (typeof formFieldWrapperProps.label === 'string'
              ? formFieldWrapperProps.label
              : '')
          }
          size={size}
          options={data.map(mapDataToOption)}
          allowClear={allowClear}
        />
      </Wrapper>
      <Form.Item name={optionsFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={selectedOptionFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
      <Form.Item name={labelFieldName} hidden>
        <input type="hidden" />
      </Form.Item>
    </>
  )
}

export default AutoComplete
