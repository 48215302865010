import { forwardRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from 'antd'
import styled, { css } from 'styled-components'

import {
  UniversalSearchOptionTypeToIconDefinitionMap,
  UniversalSearchOption,
} from 'src/components/UniversalSearchModal/util'

import { useTheme } from '../../hooks/use-theme'
import Text, { TextSize, getFontSize } from '../Typography/Text'

const ActiveStyles = css`
  border: 1px solid ${({ theme }) => theme.colorPrimaryBase};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.controlItemBgHover};
`
const StyledMenuItem = styled.a<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 24px;
  cursor: pointer;
  padding: 9px 12px;
  border-radius: 4px;
  font-size: ${getFontSize(TextSize.Base)}px;
  border: solid 1px transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colorSplit};
  border-radius: 0px;
  ${({ $isActive }) => $isActive && ActiveStyles};
`

type Props = {
  isActive: boolean
  onMouseMove: (e: React.MouseEvent) => void
  option: UniversalSearchOption
}
const UniversalSearchResultTemplate = forwardRef<HTMLAnchorElement, Props>(
  ({ isActive, onMouseMove, option }: Props, ref) => {
    const { getTokenVal } = useTheme()
    const colorIcon = getTokenVal('colorIcon')

    return (
      <StyledMenuItem
        id={option.uuid}
        $isActive={isActive}
        role="menuitem"
        tabIndex={0}
        href={option.url}
        ref={ref}
        onMouseMove={onMouseMove}
      >
        <FontAwesomeIcon
          icon={UniversalSearchOptionTypeToIconDefinitionMap[option.type]}
          color={colorIcon}
          style={{ width: '20px', height: '20px' }}
        />
        <Flex vertical flex={1}>
          <Text size={TextSize.Small} colorToken="colorTextSecondary">
            {option.title}
          </Text>
          <Text size={TextSize.Base}>{option.subtitle}</Text>
        </Flex>
      </StyledMenuItem>
    )
  }
)

export default React.memo(UniversalSearchResultTemplate)
