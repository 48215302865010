import { ReactNode, useCallback } from 'react'

import {
  faBookOpen,
  faClipboardList,
  faClock,
  faDownload,
  faEye,
  faEyeSlash,
  faPenToSquare,
  faTrash,
  faUser,
  faX,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip } from 'antd'
import styled from 'styled-components'

import { useUpdateRecordTaskInspectionMutation } from 'src/fetch/recordTaskInspections'

import {
  AddressEntryType,
  OrganizationInspectionCoordinationType,
  RecordTaskInspectionAttemptFragment,
} from '../../../types/graphql'
import { useDownloadInspectionReport } from '../../fetch/recordTaskInspectionAttempts'
import useDisclosure, { UseDisclosureReturn } from '../../hooks/use-disclosure'
import {
  useIsGov,
  useIsRestrictedReviewer,
} from '../../layouts/AppStateContextLayout/utils'
import { RefetchFnType } from '../../types'
import { RightAlignedCellContainer } from '../shared/StyledComponents'
import { DropdownMenuItem, EllipsisButton } from '../TableV2/EllipsisButton'

import { CancelInspectionModal } from './CancelInspectionModal'
import { DeleteInspectionModal } from './DeleteInspectionModal'
import LogInspectionModal from './LogInspectionModal/LogInspectionModal'
import { ReassignInspectionModal } from './ReassignInspectionModal'
import { RequestInspectionModal } from './RequestInspectionModal'
import { RescheduleInspectionModal } from './RescheduleInspectionModal'
import { ScheduleInspectionModal } from './ScheduleInspectionModal'
import { InspectionsTabModalProps } from './types'
import ViewRecordTaskInspectionAttemptResultsModalCell from './ViewRecordTaskInspectionAttemptResultsModalCell'
import ViewRecordTaskInspectionResultsModalCell from './ViewRecordTaskInspectionResultsModalCell'

const StyledRightAlignedCellContainer = styled(RightAlignedCellContainer)`
  align-items: center;
`

export enum InspectionTableViewResultsType {
  Inspection,
  Attempt,
}

type Props = {
  attempt: RecordTaskInspectionAttemptFragment
  recordTaskInspectionId: number
  isVisibleToApplicant: boolean
  refetch: RefetchFnType
  inspectionCoordinationType: OrganizationInspectionCoordinationType
  showOnlyDelete?: boolean
  showViewResults?: boolean
  rightContent?: ReactNode
  viewResultsType: InspectionTableViewResultsType
  isLatestAttempt: boolean // RecordsInspectionsPage shows all attempts, RecordCell shows latest attempts
  inspectionCutoffTime: Date | string | undefined | null
  isLocked: boolean
  isAvailable: boolean // can be interacted with by user
  inspectionGroupName?: string
  inspectionSchedulingInstructions?: string
  addressEntryType: AddressEntryType
}

export const InspectionsTableButtons = (props: Props) => {
  const {
    attempt,
    recordTaskInspectionId,
    isVisibleToApplicant,
    refetch,
    inspectionCoordinationType,
    showOnlyDelete,
    showViewResults,
    rightContent,
    viewResultsType,
    isLatestAttempt,
    inspectionCutoffTime,
    isLocked,
    isAvailable,
    inspectionGroupName,
    inspectionSchedulingInstructions,
    addressEntryType,
  } = props
  const { inspectorUser } = attempt

  const rescheduleModalState = useDisclosure()
  const cancelModalState = useDisclosure()
  const reassignModalState = useDisclosure()
  const requestModalState = useDisclosure()
  const scheduleModalState = useDisclosure()
  const deleteModalState = useDisclosure()
  const viewResultsModalState = useDisclosure()
  const logModalState = useDisclosure()

  const isGov = useIsGov()
  const isRestrictedReviewer = useIsRestrictedReviewer()

  const { mutateAsync: updateRecordTaskInspection } =
    useUpdateRecordTaskInspectionMutation()
  const handleUpdateRecordTaskInspection = useCallback(
    async (newIsVisibleToApplicant: boolean) => {
      await updateRecordTaskInspection({
        id: recordTaskInspectionId,
        input: {
          visibleToApplicant: newIsVisibleToApplicant,
        },
      })
      await refetch()
    },
    [recordTaskInspectionId, refetch, updateRecordTaskInspection]
  )

  const status = attempt?.status
  const isDoneAndPassed = status === 'Done' && attempt?.result?.isPassing
  const isDoneAndFailed = status === 'Done' && !attempt?.result?.isPassing

  const shouldShowCancel = () => {
    return (
      !isRestrictedReviewer &&
      isLatestAttempt &&
      (status === 'Scheduled' || status === 'Requested')
    )
  }

  const shouldShowViewResults = () => {
    return showViewResults
  }

  const shouldShowSchedule = () => {
    return (
      !isRestrictedReviewer &&
      isLatestAttempt &&
      status !== 'Scheduled' &&
      !isDoneAndPassed &&
      ((!isGov && inspectionCoordinationType === 'DirectBook' && !isLocked) ||
        isGov)
    )
  }

  const shouldShowDelete = () => {
    return isGov && !isRestrictedReviewer
  }

  const shouldShowLog = () => {
    return isGov && isLatestAttempt && !isDoneAndPassed
  }

  const shouldShowReschedule = () => {
    return (
      isGov &&
      !isRestrictedReviewer &&
      isLatestAttempt &&
      status === 'Scheduled'
    )
  }

  const shouldShowReassign = () => {
    return (
      !isRestrictedReviewer &&
      isGov &&
      isLatestAttempt &&
      (!status || ['Requested', 'Scheduled'].includes(status))
    )
  }

  const shouldShowRequest = () => {
    return (
      !isGov &&
      isLatestAttempt &&
      inspectionCoordinationType === 'SchedulingRequest' &&
      (!status || status === 'Cancelled' || isDoneAndFailed) &&
      !isLocked
    )
  }

  const shouldShowDownloadReport = () => {
    return (
      viewResultsType === InspectionTableViewResultsType.Attempt &&
      !!attempt.hasReport
    )
  }

  const shouldShowVisibilityItems = () => {
    return isGov && !isRestrictedReviewer
  }

  const makeKey = (str: string) => `${str}-${attempt?.id}`

  const disableRequestButton = shouldShowRequest() && !isAvailable && !isGov
  const requestItem: DropdownMenuItem = {
    label: 'Request',
    key: makeKey('request'),
    icon: faClipboardList,
    onClick: requestModalState.open,
    disabled: disableRequestButton,
    tooltip: disableRequestButton
      ? `This inspection cannot be requested yet. To do so, you must pass all inspections before beginning this inspections group: "${inspectionGroupName}".`
      : '',
  }

  // done
  const disableScheduleButton = shouldShowSchedule() && !isAvailable && !isGov
  const scheduleItem: DropdownMenuItem = {
    label: 'Schedule',
    key: makeKey('schedule'),
    icon: faClock,
    onClick: scheduleModalState.open,
    disabled: disableScheduleButton,
    tooltip: disableScheduleButton
      ? `This inspection cannot be scheduled yet. To do so, you must pass all inspections before beginning this inspections group: "${inspectionGroupName}".`
      : '',
  }

  const viewResultsItem: DropdownMenuItem = {
    label: 'View Results',
    key: makeKey('view_results'),
    icon: faBookOpen,
    onClick: viewResultsModalState.open,
  }

  const logItem: DropdownMenuItem = {
    label: 'Log',
    key: makeKey('log'),
    icon: faPenToSquare,
    onClick: logModalState.open,
  }

  // done
  const rescheduleItem: DropdownMenuItem = {
    label: 'Reschedule',
    key: makeKey('reschedule'),
    icon: faClock,
    onClick: rescheduleModalState.open,
  }

  // done
  const reassignItem: DropdownMenuItem = {
    label: inspectorUser ? 'Reassign' : 'Assign',
    key: makeKey('reassign'),
    icon: faUser,
    onClick: reassignModalState.open,
  }

  const showToApplicantItem: DropdownMenuItem = {
    label: 'Show to applicant',
    key: makeKey('showToApplicant'),
    icon: faEye,
    onClick: () => handleUpdateRecordTaskInspection(true),
  }
  const hideFromApplicantItem: DropdownMenuItem = {
    label: 'Hide from applicant',
    key: makeKey('showToApplicant'),
    icon: faEyeSlash,
    onClick: () => handleUpdateRecordTaskInspection(false),
  }
  const visibilityItems = isVisibleToApplicant
    ? [hideFromApplicantItem]
    : [showToApplicantItem]

  // done
  const cancelItem: DropdownMenuItem = {
    label: 'Cancel',
    key: makeKey('cancel'),
    icon: faX,
    onClick: cancelModalState.open,
  }

  // done
  const deleteItem: DropdownMenuItem = {
    label: 'Delete',
    key: makeKey('delete'),
    icon: faTrash,
    onClick: deleteModalState.open,
  }

  const { fn: downloadReport } = useDownloadInspectionReport(attempt.id)

  const downloadReportItem: DropdownMenuItem = {
    label: 'Download Report',
    key: makeKey('inspectionReport'),
    icon: faDownload,
    onClick: downloadReport,
  }

  // See logic for which actions to show here:
  // https://www.notion.so/govwell/Show-correct-attempt-status-and-actions-6328a01383f847ea8c007b566beddedc
  const getItems = (): DropdownMenuItem[] => {
    if (showOnlyDelete) {
      return isGov ? [deleteItem] : []
    }

    const items: DropdownMenuItem[] = [
      ...(shouldShowRequest() ? [requestItem] : []),
      ...(shouldShowSchedule() ? [scheduleItem] : []),
      ...(shouldShowViewResults() ? [viewResultsItem] : []),
      ...(shouldShowLog() ? [logItem] : []),
      ...(shouldShowReschedule() ? [rescheduleItem] : []),
      ...(shouldShowReassign() ? [reassignItem] : []),
      ...(shouldShowVisibilityItems() ? visibilityItems : []),
      ...(shouldShowCancel() ? [cancelItem] : []),
      ...(shouldShowDelete() ? [deleteItem] : []),
      ...(shouldShowDownloadReport() ? [downloadReportItem] : []),
    ]

    return items
  }

  const items = getItems()

  const makeModalProps = (
    modalState: UseDisclosureReturn
  ): InspectionsTabModalProps => {
    return {
      refetch,
      inspectionTemplateName:
        attempt?.recordTaskInspection?.inspectionTemplate?.name ?? '',
      recordTaskInspectionId: attempt?.recordTaskInspectionId,
      recordTaskInspectionAttemptId: attempt?.id,
      modalState,
      recordTaskInspectionAttemptRequestedFor: attempt?.requestedFor,
      inspectionCutoffTime,
      status: attempt?.status,
      schedulingNotes: attempt?.schedulingNotes ?? '',
      inspectionSchedulingInstructions,
      addressEntryType,
    }
  }

  const latestInspector = attempt?.inspectorUser
  const latestInspectorUserId = latestInspector?.id

  const renderItem = useCallback((item: DropdownMenuItem, index: number) => {
    const button = (
      <Button
        key={item.key}
        size="small"
        onClick={item.onClick}
        icon={<FontAwesomeIcon icon={item.icon} />}
        disabled={item.disabled}
      >
        {item.label}
      </Button>
    )
    if (item.tooltip) {
      return (
        <Tooltip title={item.tooltip} key={`tooltip-${index}`}>
          {button}
        </Tooltip>
      )
    }
    return button
  }, [])

  return (
    <>
      <StyledRightAlignedCellContainer>
        {isGov && items.length === 1 && renderItem(items[0], 0)}
        {isGov && items.length > 1 && <EllipsisButton items={items} />}
        {!isGov && items.map(renderItem)}
        {rightContent}
      </StyledRightAlignedCellContainer>
      <DeleteInspectionModal {...makeModalProps(deleteModalState)} />
      <RequestInspectionModal {...makeModalProps(requestModalState)} />
      <ScheduleInspectionModal
        {...makeModalProps(scheduleModalState)}
        defaultInspectorUserId={latestInspectorUserId}
      />
      <CancelInspectionModal {...makeModalProps(cancelModalState)} />
      <ReassignInspectionModal
        {...makeModalProps(reassignModalState)}
        currentInspector={latestInspector}
      />
      <RescheduleInspectionModal
        {...makeModalProps(rescheduleModalState)}
        currentInspectionDate={attempt?.scheduledFor}
      />
      {logModalState.isOpen && attempt && (
        <LogInspectionModal {...makeModalProps(logModalState)} />
      )}
      {viewResultsModalState.isOpen &&
        attempt &&
        viewResultsType === InspectionTableViewResultsType.Attempt && (
          <ViewRecordTaskInspectionAttemptResultsModalCell
            {...makeModalProps(viewResultsModalState)}
          />
        )}
      {viewResultsModalState.isOpen &&
        attempt &&
        viewResultsType === InspectionTableViewResultsType.Inspection && (
          <ViewRecordTaskInspectionResultsModalCell
            {...makeModalProps(viewResultsModalState)}
          />
        )}
    </>
  )
}
