import { ReactNode } from 'react'

export interface MenuItem {
  label: ReactNode
  labelTag?: string
  count?: number | string
  key?: string // for more control over keys
  isInitialItem?: boolean
  renderChildren: () => ReactNode
  renderTop?: () => ReactNode
}

export enum MenuMode {
  Horizontal,
  Vertical,
}

export interface MenuState {
  keys: string[]
  menuKey: string
  setMenuKey: (newKey: string) => void
}

export interface MenuProps {
  items: MenuItem[]
  noStyle?: boolean
  mode?: MenuMode
  // for controlled with useMenu hook
  menuKey?: MenuState['menuKey']
  setMenuKey?: MenuState['setMenuKey']
}
