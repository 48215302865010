import { forwardRef } from 'react'

import FormControl from 'govwell-ui/components/FormControl/FormControl'
import {
  FormControlProps,
  getCaptionId,
} from 'govwell-ui/components/FormControl/util'
import InputBase, {
  InputBaseProps,
} from 'govwell-ui/components/Input/InputBase'

type Props = InputBaseProps & FormControlProps
const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      autoFocus,
      caption,
      id,
      isClearable,
      isDisabled,
      isLoading,
      isReadOnly,
      isRequired,
      label,
      onBlur,
      onFocus,
      onKeyDown,
      onValueChange,
      placeholder,
      prefixIcon,
      size,
      suffixIcon,
      value,
      width,
    },
    ref
  ) => {
    return (
      <FormControl
        caption={caption}
        id={id}
        label={label}
        isRequired={isRequired}
        width={width}
      >
        <InputBase
          autoFocus={autoFocus}
          {...(caption && id ? { ariaDescribedBy: getCaptionId(id) } : {})}
          id={id}
          isClearable={isClearable}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onValueChange={onValueChange}
          placeholder={placeholder}
          prefixIcon={prefixIcon}
          ref={ref}
          size={size}
          suffixIcon={suffixIcon}
          value={value}
          width="100%"
        />
      </FormControl>
    )
  }
)

export default Input
