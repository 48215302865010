import { Tag, TagProps } from 'antd'

import { RecordTypeStatusType } from '../../../../types/graphql'

interface Props {
  isDraft?: boolean
  status?: {
    name: string
    type: RecordTypeStatusType
  } | null
}

const getColor = (type: RecordTypeStatusType): TagProps['color'] => {
  switch (type) {
    case 'ToDo': {
      return 'blue'
    }
    case 'InProgress': {
      return 'gold'
    }
    case 'Complete': {
      return 'green'
    }
    case 'CompleteNegative': {
      return 'red'
    }
  }
}

export const RecordTypeStatusDisplay = (props: Props) => {
  const { isDraft, status } = props
  if (isDraft) {
    return <Tag color="default">Draft</Tag>
  }
  const name = status?.name
  const type = status?.type
  const color = type ? getColor(type) : undefined
  return <Tag color={color}>{name}</Tag>
}
