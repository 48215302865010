import { ReactNode } from 'react'

export type FormControlProps = {
  caption?: ReactNode
  id?: string
  isRequired?: boolean
  label?: ReactNode
  width?: React.CSSProperties['width']
}

export const getCaptionId = (id: string) => `${id}-caption`
