import { Select as AntdSelect, Form, SelectProps } from 'antd'

import { InputTypes } from './Input'
import Wrapper, { FormFieldWrapperProps } from './Wrapper'

interface Props extends FormFieldWrapperProps {
  placeholder?: string
  onChange?: (value: string[]) => void | Promise<void>
  disabled?: SelectProps['disabled']
  inputType?: InputTypes
}

export function SelectCreate(props: Props) {
  const {
    placeholder,
    onChange,
    disabled,
    inputType,
    ...formFieldWrapperProps
  } = props
  const { fieldName } = formFieldWrapperProps
  Form.useWatch(fieldName)
  const { getFieldValue, setFieldValue } = Form.useFormInstance()
  const value = getFieldValue(fieldName)

  const handleChange: SelectProps['onChange'] = async (values: string[]) => {
    if (inputType === InputTypes.Email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const validValues = values.filter((v) => emailRegex.test(v))
      setFieldValue(fieldName, validValues)
    } else {
      setFieldValue(fieldName, values)
    }
  }

  return (
    <Wrapper {...formFieldWrapperProps}>
      <AntdSelect
        mode="tags"
        value={value}
        onChange={onChange || handleChange}
        placeholder={placeholder || formFieldWrapperProps.label}
        disabled={disabled}
        notFoundContent={null}
      />
    </Wrapper>
  )
}

export default SelectCreate
