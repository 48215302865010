import { Header, flexRender } from '@tanstack/react-table'
import { Flex, Tooltip } from 'antd'
import TableSortIndicator from 'govwell-ui/components/Table/TableSortIndicator'
import styled from 'styled-components'

import { TextSize, getFontSize } from 'src/components/Typography/Text'

const StyledTH = styled.th<{
  $isSortable?: boolean
  $textAlign?: React.CSSProperties['textAlign']
}>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${getFontSize(TextSize.Base)}px;
  position: relative;
  text-align: ${({ $textAlign }) => $textAlign ?? 'left'};
  font-weight: 600;
  color: ${({ theme }) => theme.colorText};
  padding: 16px 8px;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
  cursor: ${({ $isSortable: $isSortingEnabled }) =>
    $isSortingEnabled ? 'pointer' : 'default'};

  border-top: solid 1px ${({ theme }) => theme.colorSplit};
  border-bottom: solid 1px ${({ theme }) => theme.colorSplit};
  &:first-child {
    border-top-left-radius: 6px;
    border-left: solid 1px ${({ theme }) => theme.colorSplit};
  }
  &:last-child {
    border-top-right-radius: 6px;
    border-right: solid 1px ${({ theme }) => theme.colorSplit};
  }
`

type Props<TData> = {
  header: Header<TData, unknown>
}
const TableHeaderCell = <TData,>({ header }: Props<TData>) => {
  const isSortable = header.column.getCanSort()

  return (
    <Tooltip
      title={
        isSortable
          ? `Click to ${
              header.column.getIsSorted() === 'asc'
                ? 'sort descending'
                : header.column.getIsSorted() === 'desc'
                ? 'remove sorting'
                : 'sort ascending'
            }`
          : ''
      }
    >
      <StyledTH
        colSpan={header.colSpan}
        $isSortable={isSortable}
        $textAlign={header.column.columnDef.meta?.textAlign}
        onClick={header.column.getToggleSortingHandler()}
      >
        <Flex gap="6px">
          <Flex
            gap="6px"
            align="center"
            justify={
              header.column.columnDef.meta?.textAlign === 'right'
                ? 'flex-end'
                : 'flex-start'
            }
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </Flex>
          {isSortable && <TableSortIndicator column={header.column} />}
        </Flex>
      </StyledTH>
    </Tooltip>
  )
}

export default TableHeaderCell
