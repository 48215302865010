import { useCallback } from 'react'

import { FormInstance } from 'antd'
import { FieldError } from 'rc-field-form/lib/interface'

import { useNotification } from 'src/components/shared/Notification/NotificationContext'

export const useAnnounceFirstInvalidField = (form: FormInstance) => {
  const { notification } = useNotification()

  return useCallback(() => {
    const firstInvalidFieldId = form
      .getFieldsError()
      .find((field: FieldError) => !!field.errors.length)
      ?.name?.join('_')
    const firstInvalidField = document.getElementById(`${firstInvalidFieldId}`)

    if (!firstInvalidField) {
      return
    }

    firstInvalidField.style.scrollMargin = '30px'
    firstInvalidField.scrollIntoView({
      behavior: 'smooth',
    })
    notification.error({
      message: 'One or more fields is invalid.',
    })
  }, [form, notification])
}
