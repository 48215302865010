import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableProps as AntdTableProps, Table } from 'antd'
import { ColumnType, ColumnsType } from 'antd/es/table'
import 'dragula/dist/dragula.css'
import { styled } from 'styled-components'

import {
  TableHeaderField,
  TableRecordGenericType,
  TableStatus,
} from '../../hooks/use-table'
import Text, { TextSize } from '../Typography/Text'

import { TableV2Container } from './StyledComponents'
import { TableHeader } from './TableHeader'
import { TableRow } from './TableRow'
import { ReorderClassName, TableProps, TableReorderType } from './types'

const EmptyContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: center;
`

export function TableV2<
  RecordType extends TableRecordGenericType,
  ColumnIds = any
>(props: TableProps<RecordType, ColumnIds>) {
  const {
    header,
    footer,
    reorderType,
    showNumber,
    columns: inputColumns,
    data,
    isLoading,
    currentData,
    paginate,
    tableStatus,
    expandable,
    showRightVerticalLine,
    reorderData,
    id,
    hasHeaderRows,
    renderHeaderCell,
    rowSelection,
    rowKey,
    isRowReorderable,
    setHoveredRow,
    sortInputs,
    setOrder,
    pagination,
    onClickRow,
    setHoveredRowIndex,
    hoveredRowIndex,
    headerAccordionState,
  } = props

  const dataWithoutHeaders = currentData.filter((item) => !('header' in item))

  const showReorderColumn =
    reorderType !== TableReorderType.NotReorderable &&
    (hasHeaderRows ||
      (reorderType === TableReorderType.ReorderableIcon &&
        (!isRowReorderable || currentData.some((r) => isRowReorderable(r)))))

  const reorderColumn: ColumnType<RecordType> = {
    title: '',
    className: ReorderClassName,
    width: '200px',
    render: (record: RecordType) => {
      if (isRowReorderable && !isRowReorderable?.(record)) {
        return ''
      }
      return <FontAwesomeIcon icon={faBars} />
    },
  }
  const numberColumn: ColumnType<RecordType> = {
    title: '#',
    width: '1px',
    render: (record: RecordType) => {
      if (TableHeaderField in record) {
        return ''
      }
      const { key: _, ...recordWithoutKey } = record
      const index = dataWithoutHeaders.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(recordWithoutKey)
      )

      return `${index + 1}`
    },
  }
  const columns: ColumnsType = [
    ...(showReorderColumn ? [reorderColumn] : []),
    ...(showNumber ? [numberColumn] : []),
    ...inputColumns.map((column, index) => {
      if (index === 0 && hasHeaderRows) {
        return {
          ...column,
          render: (record: RecordType, _, rowIndex: number) => {
            if (TableHeaderField in record) {
              return renderHeaderCell?.(record) || record.header
            }
            return column.render(record, record, rowIndex)
          },
        }
      }
      if (sortInputs?.find((si) => si.columnId === column.key)) {
        return {
          ...column,
          sorter: true,
          showSorterTooltip: false,
        }
      }
      return column
    }),
  ]
  const showTable =
    !header ||
    !header.accordion ||
    (header.accordion && headerAccordionState?.isOpen)

  const emptyText =
    tableStatus === TableStatus.Default
      ? props.emptyText
      : 'No results for the given query'

  const dataWithKeys = currentData.map((d) => ({
    ...d,
    key: d.id || JSON.stringify(d),
  }))

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  )

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataWithKeys.findIndex((i) => i.key === active.id)
      const overIndex = dataWithKeys.findIndex((i) => i.key === over?.id)
      await reorderData(activeIndex, overIndex)
    }
  }

  const showReorder =
    reorderType === TableReorderType.ReorderableIcon ||
    reorderType === TableReorderType.ReorderableNoIcon
  const getComponentsProp = () => {
    if (showReorder) {
      return {
        components: {
          body: {
            row: TableRow,
          },
        },
      }
    }
    return {}
  }

  const onChange: AntdTableProps<RecordType>['onChange'] = (_, __, sorter) => {
    const obj = Array.isArray(sorter) ? sorter?.[0] : sorter
    const columnId = obj?.columnKey
    const sortInput = sortInputs?.find((si) => si.columnId === columnId)
    if (sortInput) {
      if (obj.order === undefined) {
        setOrder(undefined)
      } else {
        setOrder({
          mode: obj.order === 'ascend' ? 'Asc' : 'Desc',
          fieldName: sortInput?.fieldName,
        })
      }
    }
  }

  const table = (
    <Table
      showHeader
      {...(footer ? { footer: () => footer } : {})}
      columns={columns}
      expandable={expandable}
      id={id}
      key={`table-${id}`}
      {...getComponentsProp()}
      rowSelection={rowSelection}
      rowKey={rowKey}
      locale={{
        emptyText: emptyText && (
          <EmptyContainer>
            <Text size={TextSize.Base} strong>
              {emptyText}
            </Text>
          </EmptyContainer>
        ),
      }}
      onChange={onChange}
      dataSource={paginate || !data ? dataWithKeys : data}
      {...(paginate ? { pagination } : { pagination: false })}
      onRow={(record: RecordType, index) => ({
        onMouseEnter: () => {
          setHoveredRow(record)
          setHoveredRowIndex(index)
        },
        onMouseLeave: () => {
          setHoveredRow(undefined)
          setHoveredRowIndex(undefined)
        },
        onClick: () => onClickRow?.(record),
      })}
    />
  )

  const renderTable = () => {
    if (!showTable) {
      return null
    }
    if (showReorder) {
      return (
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            // rowKey array
            items={dataWithKeys.map((d) => d.key)}
            strategy={verticalListSortingStrategy}
          >
            {table}
          </SortableContext>
        </DndContext>
      )
    }
    return table
  }

  return (
    <TableV2Container
      $showRightVerticalLine={showRightVerticalLine}
      $hasHeader={!!header}
      $reorderType={reorderType}
      $hoveredRowIndex={onClickRow ? hoveredRowIndex + 1 : undefined}
      $isLoading={isLoading}
    >
      {header && <TableHeader key={`header-${id}`} {...props} />}
      {renderTable()}
    </TableV2Container>
  )
}
