import { LegacyColorPalettes } from 'antd/es/theme/interface'

export const ColorPalettes: LegacyColorPalettes = {
  'blue-1': 'rgba(230, 244, 255, 1)',
  'blue-2': 'rgba(186, 224, 255, 1)',
  'blue-3': 'rgba(145, 202, 255, 1)',
  'blue-4': 'rgba(105, 177, 255, 1)',
  'blue-5': 'rgba(64, 150, 255, 1)',
  'blue-6': 'rgba(22, 119, 255, 1)',
  'blue-7': 'rgba(9, 88, 217, 1)',
  'blue-8': 'rgba(0, 62, 179, 1)',
  'blue-9': 'rgba(0, 44, 140, 1)',
  'blue-10': 'rgba(0, 29, 102, 1)',
  'purple-1': 'rgba(249, 240, 255, 1)',
  'purple-2': 'rgba(239, 219, 255, 1)',
  'purple-3': 'rgba(211, 173, 247, 1)',
  'purple-4': 'rgba(179, 127, 235, 1)',
  'purple-5': 'rgba(146, 84, 222, 1)',
  'purple-6': 'rgba(114, 46, 209, 1)',
  'purple-7': 'rgba(83, 29, 171, 1)',
  'purple-8': 'rgba(57, 16, 133, 1)',
  'purple-9': 'rgba(34, 7, 94, 1)',
  'purple-10': 'rgba(18, 3, 56, 1)',
  'cyan-1': 'rgba(230, 255, 251, 1)',
  'cyan-2': 'rgba(181, 245, 236, 1)',
  'cyan-3': 'rgba(135, 232, 222, 1)',
  'cyan-4': 'rgba(92, 219, 211, 1)',
  'cyan-5': 'rgba(54, 207, 201, 1)',
  'cyan-6': 'rgba(19, 194, 194, 1)',
  'cyan-7': 'rgba(8, 151, 156, 1)',
  'cyan-8': 'rgba(0, 109, 117, 1)',
  'cyan-9': 'rgba(0, 71, 79, 1)',
  'cyan-10': 'rgba(0, 35, 41, 1)',
  'green-1': 'rgba(246, 255, 237, 1)',
  'green-2': 'rgba(217, 247, 190, 1)',
  'green-3': 'rgba(183, 235, 143, 1)',
  'green-4': 'rgba(149, 222, 100, 1)',
  'green-5': 'rgba(115, 209, 61, 1)',
  'green-6': 'rgba(82, 196, 26, 1)',
  'green-7': 'rgba(56, 158, 13, 1)',
  'green-8': 'rgba(35, 120, 4, 1)',
  'green-9': 'rgba(19, 82, 0, 1)',
  'green-10': 'rgba(9, 43, 0, 1)',
  'magenta-1': 'rgba(255, 240, 246, 1)',
  'magenta-2': 'rgba(255, 214, 231, 1)',
  'magenta-3': 'rgba(255, 173, 210, 1)',
  'magenta-4': 'rgba(255, 133, 192, 1)',
  'magenta-5': 'rgba(247, 89, 171, 1)',
  'magenta-6': 'rgba(235, 47, 150, 1)',
  'magenta-7': 'rgba(196, 29, 127, 1)',
  'magenta-8': 'rgba(158, 16, 104, 1)',
  'magenta-9': 'rgba(120, 6, 80, 1)',
  'magenta-10': 'rgba(82, 3, 57, 1)',
  'pink-1': 'white',
  'pink-2': 'white',
  'pink-3': 'white',
  'pink-4': 'white',
  'pink-5': 'white',
  'pink-6': 'white',
  'pink-7': 'white',
  'pink-8': 'white',
  'pink-9': 'white',
  'pink-10': 'white',
  'red-1': 'rgba(255, 241, 240, 1)',
  'red-2': 'rgba(255, 204, 199, 1)',
  'red-3': 'rgba(255, 163, 158, 1)',
  'red-4': 'rgba(255, 120, 117, 1)',
  'red-5': 'rgba(255, 77, 79, 1)',
  'red-6': 'rgba(245, 34, 45, 1)',
  'red-7': 'rgba(207, 19, 34, 1)',
  'red-8': 'rgba(168, 7, 26, 1)',
  'red-9': 'rgba(130, 0, 20, 1)',
  'red-10': 'rgba(92, 0, 17, 1)',
  'orange-1': 'rgba(255, 247, 230, 1)',
  'orange-2': 'rgba(255, 231, 186, 1)',
  'orange-3': 'rgba(255, 213, 145, 1)',
  'orange-4': 'rgba(255, 192, 105, 1)',
  'orange-5': 'rgba(255, 169, 64, 1)',
  'orange-6': 'rgba(250, 140, 22, 1)',
  'orange-7': 'rgba(212, 107, 8, 1)',
  'orange-8': 'rgba(173, 78, 0, 1)',
  'orange-9': 'rgba(135, 56, 0, 1)',
  'orange-10': 'rgba(97, 37, 0, 1)',
  'yellow-1': 'rgba(254, 255, 230, 1)',
  'yellow-2': 'rgba(255, 255, 184, 1)',
  'yellow-3': 'rgba(255, 251, 143, 1)',
  'yellow-4': 'rgba(255, 245, 102, 1)',
  'yellow-5': 'rgba(255, 236, 61, 1)',
  'yellow-6': 'rgba(250, 219, 20, 1)',
  'yellow-7': 'rgba(212, 177, 6, 1)',
  'yellow-8': 'rgba(173, 139, 0, 1)',
  'yellow-9': 'rgba(135, 104, 0, 1)',
  'yellow-10': 'rgba(97, 71, 0, 1)',
  'volcano-1': 'rgba(255, 242, 232, 1)',
  'volcano-2': 'rgba(255, 216, 191, 1)',
  'volcano-3': 'rgba(255, 187, 150, 1)',
  'volcano-4': 'rgba(255, 156, 110, 1)',
  'volcano-5': 'rgba(255, 122, 69, 1)',
  'volcano-6': 'rgba(250, 84, 28, 1)',
  'volcano-7': 'rgba(212, 56, 13, 1)',
  'volcano-8': 'rgba(173, 33, 2, 1)',
  'volcano-9': 'rgba(135, 20, 0, 1)',
  'volcano-10': 'rgba(97, 11, 0, 1)',
  'geekblue-1': 'rgba(240, 245, 255, 1)',
  'geekblue-2': 'rgba(214, 228, 255, 1)',
  'geekblue-3': 'rgba(173, 198, 255, 1)',
  'geekblue-4': 'rgba(133, 165, 255, 1)',
  'geekblue-5': 'rgba(89, 126, 247, 1)',
  'geekblue-6': 'rgba(47, 84, 235, 1)',
  'geekblue-7': 'rgba(29, 57, 196, 1)',
  'geekblue-8': 'rgba(16, 35, 158, 1)',
  'geekblue-9': 'rgba(6, 17, 120, 1)',
  'geekblue-10': 'rgba(3, 8, 82, 1)',
  'lime-1': 'rgba(252, 255, 230, 1)',
  'lime-2': 'rgba(244, 255, 184, 1)',
  'lime-3': 'rgba(234, 255, 143, 1)',
  'lime-4': 'rgba(211, 242, 97, 1)',
  'lime-5': 'rgba(186, 230, 55, 1)',
  'lime-6': 'rgba(160, 217, 17, 1)',
  'lime-7': 'rgba(124, 179, 5, 1)',
  'lime-8': 'rgba(91, 140, 0, 1)',
  'lime-9': 'rgba(63, 102, 0, 1)',
  'lime-10': 'rgba(37, 64, 0, 1)',
  'gold-1': 'rgba(255, 251, 230, 1)',
  'gold-2': 'rgba(255, 241, 184, 1)',
  'gold-3': 'rgba(255, 229, 143, 1)',
  'gold-4': 'rgba(255, 214, 102, 1)',
  'gold-5': 'rgba(255, 197, 61, 1)',
  'gold-6': 'rgba(250, 173, 20, 1)',
  'gold-7': 'rgba(212, 136, 6, 1)',
  'gold-8': 'rgba(173, 104, 0, 1)',
  'gold-9': 'rgba(135, 77, 0, 1)',
  'gold-10': 'rgba(97, 52, 0, 1)',
}
