import { Flex } from 'antd'
import TableContent from 'govwell-ui/components/Table/TableContent'
import TableControls from 'govwell-ui/components/Table/TableControls'

const TableBase = ({ children }: { children?: React.ReactNode }) => (
  <Flex vertical gap="6px">
    {children}
  </Flex>
)

export default Object.assign(TableBase, {
  Content: TableContent,
  Controls: TableControls,
})
